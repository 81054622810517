import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import moment from "moment";

import PulseLoader from "react-spinners/PulseLoader";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import Form from "../../../../components/forms/Form";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import EvidenceDetailsMarginStyled from "./styled/EvidenceDetailsMarginStyled";
import EvidenceDetailsColumnWithBorderStyled from "./styled/EvidenceDetailsColumnWithBorderStyled";

import {
  getCaseById,
  getEvidenceById,
  previewEvidenceFile,
} from "../../../Auth/auth";

import fileDocIcon from "../../../../assets/img3/svg/file-doc-icon.svg";
import fileOdtIcon from "../../../../assets/img3/svg/file-odt-icon.svg";
import { format, parseISO } from "date-fns";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../../../../components/FontsNewComponent/Fonts";

import filePreviewIcon from "../../../../assets/img3/svg/file-preview-icon.svg";
import {
  TagElementBlue,
} from "../../../../components/TagElementStyled/TagElementStyled";
import CasesMenu from "../../../../components/baseAppComponents/CasesMenu";
import NoRecords from "../../../../components/NoRecords";
import flagRelevanceCheck from "../../../../components/FlagRelevanceFilter/flagRelevanceCheck";
import NA from "../../../../components/NA";
import {
  setTagField,
  ShowCoa,
  ShowIssues,
  ShowThemes,
} from "../../../../utils/function";
import KeySections from "../../../../components/KeySections";

const extensionsWithoutPreview = [
  {
    extension: "doc",
    previewImage: fileDocIcon,
  },
  {
    extension: "docx",
    previewImage: fileDocIcon,
  },
  {
    extension: "odt",
    previewImage: fileOdtIcon,
  },
];

const NotAvailable = <NA />;
const dateFormat = "dd MMM yyyy";

const dateFormatToCompare = "YYYYMMDD";

const dateToInt = (date) => parseInt(moment(date).format(dateFormatToCompare));
const EvidenceDetails = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [evidenceObject, setEvidenceObject] = useState({});
  const [fileType, setFileType] = useState({});
  const [filePreview, setFilePreview] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [caseObject, setCaseObject] = useState("");
  const [datePickerStartDate, setDatePickerStartDate] = useState(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState(null);
  const [selectedSortFunction, setSelectedSortFunction] = useState(1);
  const [flagRelevance, setFlagRelevance] = useState([]);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [themesFilterOptions, setThemesFilterOptions] = useState([
    {
      value: 0,
      label: "All Themes",
    },
  ]);
  const [themesFilterSelectedOption, setThemesFilterSelectOption] = useState(
    themesFilterOptions[0]
  );
  const [subThemesFilterOptions, setSubThemesFilterOptions] = useState([
    {
      value: 0,
      label: "All Subtheme",
    },
  ]);
  const [
    subThemesFilterSelectedOption,
    setSubThemesFilterSelectOption,
  ] = useState(subThemesFilterOptions[0]);
  const { id, evidenceId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // Get evidence data from api
  useEffect(() => {
    const getData = async () => {
      const caseResp = await getCaseById(id, dispatch);
      if (is_admin && !caseResp) {
        history.push("/admin/all-cases");
      }
      setCaseObject(caseResp);
      const evidenceResp = await getEvidenceById(evidenceId, id, dispatch);
      if (evidenceResp) {
        setEvidenceObject(evidenceResp);
        if (evidenceResp.file) {
          const fileNameArray = evidenceResp.file.split(".");
          const fileExtension = fileNameArray[fileNameArray.length - 1];
          const checkExtension = extensionsWithoutPreview.findIndex(
            (object) => object.extension === fileExtension
          );
          if (checkExtension === -1) {
            const filePreviewResp = await previewEvidenceFile(
              evidenceId,
              id,
              dispatch
            );
            if (filePreviewResp) {
              setFilePreview(filePreviewResp);
            }
          } else {
            setFileType(extensionsWithoutPreview[checkExtension]);
          }
        }
      }

      setIsLoading(false);
    };
    getData();
    if (history.location.state && history.location.state.subevidence) {
      setActiveTab(1);
    }
  }, []);
  useEffect(() => {
    if (evidenceObject.sub_evidence && evidenceObject.sub_evidence.length) {
      const uniqueThemes = [];
      const uniqueSubThemes = [];
      const withTags = [];
      const evidenceObjectWithTaggedEvidences = { ...evidenceObject };
      setTagField(caseObject, evidenceObjectWithTaggedEvidences.sub_evidence);
      evidenceObject.sub_evidence.forEach((item, index, array) => {
        if (item.themes_parent && item.themes_parent.length) {
          item.themes_parent.forEach((element) => {
            if (uniqueThemes.indexOf(element.name) === -1) {
              uniqueThemes.push(element.name);
              setThemesFilterOptions((prevState) => [
                ...prevState,
                {
                  value: element.id,
                  label: element.name,
                },
              ]);
            }
          });
        }
        if (item.themes && item.themes.length) {
          item.themes.forEach((element) => {
            if (uniqueSubThemes.indexOf(element.name) === -1) {
              uniqueSubThemes.push(element.name);
              setSubThemesFilterOptions((prevState) => [
                ...prevState,
                {
                  value: element.id,
                  label: element.name,
                },
              ]);
            }
          });
        }
      });
    }
  }, [evidenceObject]);
  const onClearDatesHandler = () => {
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
  };

  const onChangeDatesHandler = (dates) => {
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
  };

  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const clearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  const onThemesFilterChangeHandler = (selectedOption) => {
    setThemesFilterSelectOption(selectedOption);
  };

  const onSubThemesFilterChangeHandler = (selectedOption) => {
    setSubThemesFilterSelectOption(selectedOption);
  };
  const noRecordsFragment = (
    <NoRecords>
      <Bold18Font textColor={"#0f122f"}>
        Your Key Section will appear here
      </Bold18Font>
    </NoRecords>
  );
  const onFilterDatesAlphabetChangeHandler = (value) => {
    setSelectedSortFunction(value.value);
  };
  const editEvidenceHandler = () => {
    if (is_admin) {
      history.push(
        `/admin/all-cases/${id}/trial-hub/evidence/edit/${evidenceId}`
      );
    } else {
      history.push(`/app/cases/${id}/trial-hub/evidence/edit/${evidenceId}`);
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: evidenceObject.case.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-hub/evidence`
        : `/admin/all-cases/${id}/trial-hub/evidence`,
      title: `Trial Hub - Evidence`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-hub/evidence/${evidenceId}`
        : `/admin/all-cases/${id}/trial-hub/evidence/${evidenceId}`,
      title: evidenceObject.label ?? "New Evidence",
      activeMenuItem: CASES,
    },
  ];
  const getType = (type) => {
    setActiveTab(type);
  };
  const evidenceTableSettings = {
    collapsed: false,
    actions: [],
    clickAction: (object) => {
      object.case = caseObject;
      history.push({
        state: { subevidence: object.id },
        pathname: `/app/cases/${id}/documents/` + evidenceObject.file_id,
      });
    },
    fields: [
      {
        name: "id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
      },
      {
        name: "label",
        label: "KEY SECTION",
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      // {
      //   name: "type",
      //   label: "TYPE",
      //   renderer: (object) => {
      //     return <Medium14Font>{object.type}</Medium14Font>;
      //   },
      // },
      // {
      //   name: "from",
      //   label: "FROM",
      //   renderer: (object) => {
      //     return <Medium14Font>{object.from}</Medium14Font>;
      //   },
      // },
      // {
      //   name: "to",
      //   label: "TO",
      //   renderer: (object) => {
      //     return <Medium14Font>{object.from}</Medium14Font>;
      //   },
      // },
      {
        name: "date",
        label: "DATE",
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.date ? format(parseISO(object.date), "dd MMM yyyy") : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ],
  };
  const searchField = (fieldValue) => {
    if (typeof fieldValue === "string") {
      return (
        fieldValue.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1
      );
    } else if (typeof fieldValue === "object") {
      return (
        fieldValue.filter((string) => {
          if (string && string.name !== null) {
            return (
              string.name
                .toLowerCase()
                .indexOf(searchBarValue.toLowerCase()) !== -1
            );
          }
          return false;
        }).length > 0
      );
    }
  };
  const searchThemeField = (fieldValue) => {
    return (
      fieldValue.filter((item) => {
        return item.id === themesFilterSelectedOption.value;
      }).length > 0
    );
  };

  // Filter by sub-theme id
  const searchSubThemeField = (fieldValue) => {
    return fieldValue.name === subThemesFilterSelectedOption.label;
  };
  const filterEvidences = (value) => {
    let check = true;

    if (searchBarValue && check) {
      check = value.label && searchField(value.label);
      // TODO: Add search by TAGS!
    }
    if (check && flagRelevance.length) {
      check = flagRelevanceCheck(value, flagRelevance);
    }
    // Filter by date
    if (check) {
      const checkedDate = dateToInt(value.date);
      let dateBefore;
      let dateAfter;
      if (datePickerStartDate && datePickerEndDate) {
        dateBefore = dateToInt(datePickerStartDate);
        dateAfter = dateToInt(datePickerEndDate);
        check = dateBefore <= checkedDate && checkedDate <= dateAfter;
      } else if (datePickerStartDate) {
        dateBefore = dateToInt(datePickerStartDate);
        check = dateBefore <= checkedDate;
      } else if (datePickerEndDate) {
        dateAfter = dateToInt(datePickerEndDate);
        check = checkedDate <= dateAfter;
      }
    }
    // Filter by theme name
    if (themesFilterSelectedOption.value !== 0 && check) {
      check =
        value.themes_parent &&
        value.themes_parent.length &&
        searchThemeField(value.themes_parent);
    }

    const list = [];

    if (subThemesFilterSelectedOption.value !== 0 && check) {
      if (value.themes.length) {
        value.themes.forEach((sub_theme) => {
          const subThemeCheck = searchSubThemeField(sub_theme);
          if (list.indexOf(subThemeCheck) === -1) {
            list.push(subThemeCheck);
          }
        });
        check = list.indexOf(true) !== -1;
      }
    }

    return check;
  };
  const tabsPanelItems = [
    {
      id: 1,
      text: "Basic Info",
      activeType: 0,
    },
    {
      id: 2,
      text: `Key Sections (${evidenceObject ? evidenceObject.sub_evidence.length : null})`,
      activeType: 1,
    },
  ];
  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-6">
          <Bold30Font>{evidenceObject.label ?? "New Evidence"}</Bold30Font>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center mb-3">
          <ButtonNew
            clickHandler={editEvidenceHandler}
            loading={isLoading}
            disabled={isLoading}
            // style={{ marginRight: "20px" }}
            primary
            // delete_
          >
            Edit
          </ButtonNew>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <CasesMenu
            casesMenu={tabsPanelItems}
            noBadges
            noButton
            active={activeTab}
            activeFont={Bold18Font}
            font={Medium18Font}
            getType={getType}
          />
        </div>
      </div>
      {activeTab === 0 ? (
        <div className="row">
          <div className="col">
            <Form>
              <div className="container-fluid" style={{ padding: "35px 60px" }}>
                <div
                  className="row pb-5 mb-5"
                  style={{ borderBottom: `1px solid ${theme.colors.gray}` }}
                >
                  <div className="col-12 col-sm d-flex flex-column">
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Witness name</Medium14Font>
                      {/* TODO: Need change to list? */}
                      {evidenceObject.witnesses.filter(
                        (el) => el.type !== "Secondary"
                      ).length
                        ? evidenceObject.witnesses
                            .filter((el) => el.type !== "Secondary")
                            .map((witness) => (
                              <Bold18Font key={witness.id}>
                                {witness.name}
                              </Bold18Font>
                            ))
                        : NotAvailable}
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Files</Medium14Font>
                      <Bold14Font>
                        {evidenceObject.file ?? NotAvailable}
                      </Bold14Font>
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Core Book</Medium14Font>
                      <Bold14Font>
                        {evidenceObject.core_book === 1 ? "Yes" : "No"}
                      </Bold14Font>
                    </EvidenceDetailsMarginStyled>
                  </div>
                  <EvidenceDetailsColumnWithBorderStyled
                    className="col-12 col-sm d-flex flex-column"
                    // style={{ borderRight: `1px solid ${theme.colors.gray}` }}
                  >
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Date</Medium14Font>
                      <Bold18Font>
                        {evidenceObject.date
                          ? moment(evidenceObject.date).format("DD MMM YYYY")
                          : NotAvailable}
                      </Bold18Font>
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Reference</Medium14Font>
                      <Bold14Font>
                        {evidenceObject.reference ?? NotAvailable}
                      </Bold14Font>
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Priority</Medium14Font>
                      <Bold14Font>
                        {evidenceObject.priority ?? NotAvailable}
                      </Bold14Font>
                    </EvidenceDetailsMarginStyled>
                  </EvidenceDetailsColumnWithBorderStyled>

                  <div className="col-12 col-sm d-flex flex-column pl-sm-5">
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Evidence Type</Medium14Font>
                      {evidenceObject.type ? (
                        <TagElementBlue theme={theme}>
                          <Bold14Font style={{ color: "inherit" }}>
                            {evidenceObject.type}
                          </Bold14Font>
                        </TagElementBlue>
                      ) : (
                        NotAvailable
                      )}
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Elements</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {(evidenceObject.causes_of_action &&
                          evidenceObject.causes_of_action.length) ||
                        (evidenceObject.causes_of_action_parent &&
                          evidenceObject.causes_of_action_parent.length) ||
                        (evidenceObject.causes_of_action_defensive &&
                          evidenceObject.causes_of_action_defensive.length) ? (
                          <Fragment>
                            <ShowCoa
                              object={evidenceObject}
                              case_object={caseObject}
                            />
                          </Fragment>
                        ) : (
                          NotAvailable
                        )}
                      </div>
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Issues</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {(evidenceObject.issues &&
                          evidenceObject.issues.length) ||
                        (evidenceObject.issues_parent &&
                          evidenceObject.issues_parent.length) ? (
                          <Fragment>
                            <ShowIssues
                              object={evidenceObject}
                              case_object={caseObject}
                            />
                          </Fragment>
                        ) : (
                          NotAvailable
                        )}
                      </div>
                    </EvidenceDetailsMarginStyled>
                  </div>
                  <div className="col-12 col-sm d-flex flex-column mt-4 mt-sm-0">
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Status</Medium14Font>
                      <Bold18Font>
                        {evidenceObject.status ? (
                          <TagElementBlue theme={theme}>
                            <Bold14Font style={{ color: "inherit" }}>
                              {evidenceObject.status}
                            </Bold14Font>
                          </TagElementBlue>
                        ) : (
                          NotAvailable
                        )}
                      </Bold18Font>
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Themes</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {(evidenceObject.themes &&
                          evidenceObject.themes.length) ||
                        (evidenceObject.themes_parent &&
                          evidenceObject.themes_parent.length) ? (
                          <Fragment>
                            <ShowThemes object={evidenceObject} case_object={caseObject}/>
                          </Fragment>
                        ) : (
                          NotAvailable
                        )}
                      </div>
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Secondary Witness</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {evidenceObject.witnesses &&
                        // TODO: create witness lists object with primary and secondary division
                        evidenceObject.witnesses.filter(
                          (el) => el.type === "Secondary"
                        ).length
                          ? evidenceObject.witnesses
                              .filter((el) => el.type === "Secondary")
                              .map((el) => (
                                <TagElementBlue key={el.id} theme={theme}>
                                  <Bold14Font style={{ color: "inherit" }}>
                                    {el.name}
                                  </Bold14Font>
                                </TagElementBlue>
                              ))
                          : NotAvailable}
                      </div>
                    </EvidenceDetailsMarginStyled>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm d-flex flex-column">
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Objectives</Medium14Font>
                      {evidenceObject.objectives &&
                      evidenceObject.objectives.length ? (
                        <ul>
                          {evidenceObject.objectives.map((objective, index) => (
                            <LiStyled
                              key={index}
                              style={{ paddingLeft: "15px" }}
                            >
                              <Bold18Font>{objective.name}</Bold18Font>
                            </LiStyled>
                          ))}
                        </ul>
                      ) : (
                        NotAvailable
                      )}
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Summary</Medium14Font>
                      {evidenceObject.summary ? (
                        <Bold18Font>{evidenceObject.summary}</Bold18Font>
                      ) : (
                        NotAvailable
                      )}
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Preempt</Medium14Font>
                      {evidenceObject.preempt ? (
                        <Bold18Font>{evidenceObject.preempt}</Bold18Font>
                      ) : (
                        NotAvailable
                      )}
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Notes / Comments</Medium14Font>
                      {evidenceObject.notes ? (
                        <Bold18Font>{evidenceObject.notes}</Bold18Font>
                      ) : (
                        NotAvailable
                      )}
                    </EvidenceDetailsMarginStyled>
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Related Evidences</Medium14Font>
                      {evidenceObject.related_evidence.length ? (
                        <ul>
                          {evidenceObject.related_evidence.map((v) => {
                            return (
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  !is_admin
                                    ? history.push(
                                        `/app/cases/${id}/trial-hub/evidence/${v.fake_id}`
                                      )
                                    : history.push(
                                        `/admin/all-cases/${id}/trial-hub/evidence/${v.fake_id}`
                                      );
                                }}
                              >
                                {v.label}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        NotAvailable
                      )}
                    </EvidenceDetailsMarginStyled>
                  </div>
                  {evidenceObject.file ? (
                    <div className="col-12 col-sm d-flex justify-content-end mt-4 mt-sm-0">
                      <ButtonNew
                        tertiary
                        clickHandler={() => {
                          history.push(
                            !is_admin
                              ? `/app/cases/${id}/documents/${evidenceObject.file_id}`
                              : `/admin/all-cases/${id}/documents/${evidenceObject.file_id}`
                          );
                        }}
                        style={{
                          borderRadius: "4px",
                          border: `1px solid ${theme.colors.gray}`,
                        }}
                      >
                        <img
                          src={
                            filePreview ? filePreview : fileType.previewImage
                          }
                          alt=""
                          className="img-fluid"
                        />
                        <img
                          src={filePreviewIcon}
                          alt=""
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </ButtonNew>
                    </div>
                  ) : null}
                </div>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <KeySections
          evidenceObject={evidenceObject}
          caseObject={caseObject}
          history={history}
        />
      )}
    </Fragment>
  );
};

export default withTheme(EvidenceDetails);
