import React, { Fragment, useEffect, useState } from "react";
import CaseCardStyled from "./styled/CaseCardStyled";
import CaseCardTypeStyled from "./styled/CaseCardTypeStyled";
import CaseCardFirmNameStyled from "./styled/CaseCardFirmNameStyled";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import pancilIcon from "../../../assets/img/svg/pancil_icon.svg";
import personIcon from "../../../assets/img/svg/person.svg";
import { HIDE_RIGHT_SIDE_BAR, SHOW_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import RSBCaseCardPreview from "../../rightSideBarViews/RSBCaseCardPreview";
import Avatar from "react-avatar";
import UserNameDivStyled from "./styled/UserNameDivStyled";
import AccessDivStyled from "./styled/AccessDivStyled";
import { requireAccessToCase } from "../../../containers/Auth/auth";
import { route } from "../../../routes";
import { theme } from "../../../styled-components/Theme/Theme";
import { PulseLoader } from "react-spinners";
import CaseCardLabelOverflowStyled from "./styled/CaseCardLabelOverflowStyled";
import {
  TagElementBlue,
  TagElementOrange,
  TagElementPurple,
  TagElementTurquoise,
} from "../../TagElementStyled/TagElementStyled";

const NAComponent = () => {
  return (
    <Medium14Font as="div" style={{ marginBottom: "20px" }}>
      N/A
    </Medium14Font>
  );
};

const ApplicationCard = ({ applicationObject, onClick, ...props }) => {
  const dateUpdated = moment(applicationObject.updated_at).format("DD/MM/YY");
  return (
    <CaseCardStyled
      admin={false}
      onClick={onClick}
      className="m-1 d-flex flex-column justify-content-between"
      noArchivedTopBorder
      {...props}
    >
      <div>
        <Bold18Font as="div" style={{ marginBottom: "30px" }}>
          {applicationObject.label}
        </Bold18Font>
        <Medium14Font as="div" style={{ marginBottom: "8px" }}>
          Moving Party
        </Medium14Font>
        {applicationObject.moving_parties.length ? (
          <TagElementTurquoise style={{ marginBottom: "20px" }}>
            <Bold14Font style={{ color: "inherit" }}>
              {applicationObject.moving_parties[0].name}
            </Bold14Font>
          </TagElementTurquoise>
        ) : (
          <NAComponent />
        )}
        <Medium14Font as="div" style={{ marginBottom: "8px" }}>
          Respondents
        </Medium14Font>
        <ul style={{ marginBottom: "20px" }} className="d-flex flex-wrap">
          {applicationObject.respondent_parties.length ? (
            applicationObject.respondent_parties.map((party) => (
              <TagElementOrange key={party.id} as="li">
                <Bold14Font style={{ color: "inherit" }}>
                  {party.name}
                </Bold14Font>
              </TagElementOrange>
            ))
          ) : (
            <NAComponent />
          )}
        </ul>
        <Medium14Font as="div" style={{ marginBottom: "8px" }}>
          Affidavits
        </Medium14Font>
        <Bold14Font as="div" style={{ marginBottom: "8px" }}>
          {applicationObject.affidavits}
        </Bold14Font>
      </div>
      <div className="d-flex justify-content-end">
        <Medium14Font title="last updated">
          <img className="mr-2" src={pancilIcon} alt={"date"} />
          {dateUpdated}
        </Medium14Font>
      </div>
    </CaseCardStyled>
  );
};

export default ApplicationCard;
