import React, { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HIDE_RIGHT_SIDE_BAR,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
} from "../../../redux/types";
import Checkbox from "../../Checkbox/Checkbox";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import {
  createNewEvent,
  updateEventById,
  updateEvidenceCoreBook,
} from "../../../containers/Auth/auth";
import NoRecords from "../../NoRecords";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import { parseISO } from "date-fns";
import moment from "moment";
import InputNew from "../../InputNew/InputNew";
import CustomReactDatepicker from "../../inputs/CustomReactDatepicker";

const AddEditEventForm = ({ eventObject, setEventsList, routePaths }) => {
  // page states
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const { caseId } = routePaths;
  const form = useRef(null);

  // data states
  const [eventTitle, setEventTitle] = useState(
    eventObject ? eventObject.title : ""
  );
  const [eventDate, setEventDate] = useState(
    eventObject && eventObject.date ? parseISO(eventObject.date) : null
  );

  // send data

  const sendDataHandler = async (submitObj) => {
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    const resp = eventObject
      ? await updateEventById(eventObject.id, caseId, dispatch, submitObj)
      : await createNewEvent(caseId, dispatch, submitObj);
    if (resp) {
      if (eventObject) {
        setEventsList((prevState) => {
          const index = prevState.findIndex((el) => el.id === eventObject.id);
          if (index !== -1) {
            const prevStateCopy = [...prevState];
            prevStateCopy.splice(index, 1, resp);
            return prevStateCopy;
          }
        });
      } else {
        setEventsList((prevState) => [...prevState, resp]);
      }
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  // button handlers

  const onClickSaveButtonHandler = async (e) => {
    e.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
      return;
    }
    if (!isSubmitting) {
      const sendObj = {
        title: eventTitle,
        date: eventDate ? moment(eventDate).format("YY-MM-DD") : null,
      };
      await sendDataHandler(sendObj);
    }
  };

  // input handlers

  const onChangeDateHandler = (date) => {
    setEventDate(date);
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            maxLength={125}
            required
            label={"Title"}
            onChangeHandler={(e) => {
              if (e.target.value.length <= 125) setEventTitle(e.target.value);
            }}
            value={eventTitle}
            placeholder={"Enter Title"}
            name={"title"}
            form={`form-add-edit-event`}
          />
        </div>
        <div className="form-group">
          <CustomReactDatepicker
            selected={eventDate}
            onChange={(date) => onChangeDateHandler(date)}
            dateFormat={"dd/MM/yyyy"}
            dateFormatCalendar={"dd MMMM yyyy"}
            label="Allocate Date"
            name="date"
            placeholderText="Select Date"
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group" style={{ marginTop: "20px" }}>
        <div className="container-fluid">
          <form
            onSubmit={onClickSaveButtonHandler}
            id="form-add-edit-event"
            ref={form}
          >
            <ButtonNew
              loading={isSubmitting}
              disabled={isSubmitting}
              // clickHandler={onClickSaveButtonHandler}
              wide
              primary
              type="submit"
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddEditEventForm;
