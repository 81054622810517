import React, { useEffect, useRef, useState, Fragment } from "react";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../FontsNewComponent/Fonts";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import pancilIcon from "../../assets/img/svg/pancil_icon.svg";
import keyIcon from "../../assets/img/svg/key_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { fadeIn } from "react-animations";
import { keyframes } from "styled-components";
import ListActionButton from "../buttons/ListActionButton/ListActionButton";
import fileDocIcon from "../../assets/img3/svg/file-doc-icon.svg";
import fileOdtIcon from "../../assets/img3/svg/file-odt-icon.svg";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../redux/types";
import { deleteDocument } from "../../containers/Auth/auth";
import {
  TagElementCyan,
  TagElementPurple,
} from "../TagElementStyled/TagElementStyled";
import {
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  EVIDENCE_DOCUMENTS,
} from "../../containers/BaseApp/Documents/Documents";
import { documentTypesMap } from "../../utils/constants";
import AssociateElementsThemesIssuesForm from "../forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import AssignToBundleForm from "../forms/DocumentForms/AssignToBundleForm";

const bounceAnimation = keyframes`${fadeIn}`;

const DocumentCardStyled = styled.div`
  border-radius: 4px;
  cursor: pointer;
  animation: 1s ${bounceAnimation};
  width: 100%;
  min-height: 256px;
  border: 1px solid #dcdce6;
  background-color: #ffffff;
`;

const DocumentCard = ({
  archived,
  deleteCaseFromView,
  archiveCaseInView,
  reloadDocuments,
  sendRequestCaseInView,
  onKeySectionClickHandler,
  ...props
}) => {
  const { documentObject } = props;
  const [imageLoaded, setImageLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const extensionsWithoutPreview = [
    {
      extension: "doc",
      previewImage: fileDocIcon,
    },
    {
      extension: "docx",
      previewImage: fileDocIcon,
    },
    {
      extension: "odt",
      previewImage: fileOdtIcon,
    },
  ];
  const fileNameArray = documentObject.file.split(".");
  const fileExtension = fileNameArray[fileNameArray.length - 1];
  const checkExtension = extensionsWithoutPreview.findIndex(
    (object) => object.extension === fileExtension
  );

  const dateCreated = documentObject.date
    ? moment(documentObject.date).format("DD/MM/YY")
    : moment(documentObject.updated_at).format("DD/MM/YY");
  const handleDelete = async (event) => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteDocument(id, documentObject.id, dispatch);
        await reloadDocuments();
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };
  const onClickAssignToBundleButton = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: documentObject,
      content: (
        <AssignToBundleForm
          key={documentObject.id}
          caseId={id}
          afterSaveHandler={reloadDocuments}
        />
      ),
      title: "Assign to bundle",
    });
  };
  const goTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push(
      !is_admin
        ? `/app/cases/${id}/documents/${documentObject.id}`
        : `/admin/all-cases/${id}/documents/${documentObject.id}`
    );
  };
  const reference = useRef();
  const onScroll = () => {
    if (reference.current) {
      const top = reference.current.getBoundingClientRect().top;
      if (top >= 0 && top <= window.innerHeight) {
        setImageLoading(true);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll, true);
    return () => window.removeEventListener("scroll", onScroll, true);
  });
  useEffect(() => {
    onScroll();
  }, []);
  return (
    <DocumentCardStyled className="m-1" ref={reference}>
      <div className="d-flex flex-column justify-content-between h-100 position-relative">
        <div
          onClick={goTo}
          className="mb-2 d-flex justify-content-end pt-3 pr-2"
          style={{
            height: "200px",
            backgroundSize: "cover",
            backgroundImage: `url(${
              imageLoaded
                ? documentObject.data
                  ? documentObject.data.replace('/content/', '/view/')
                  : extensionsWithoutPreview[checkExtension] && extensionsWithoutPreview[checkExtension].previewImage
                  ? extensionsWithoutPreview[checkExtension].previewImage.replace('content', 'view')
                  : null
                : null
            })`,
          }}
        />
        <div
          className="d-flex position-absolute"
          style={{ right: 0, top: "8px" }}
        >
          <ListActionButton
            clickHandler={onClickAssignToBundleButton}
            type={"label"}
          />

          <ListActionButton clickHandler={goTo} type={"edit"} />
          <ListActionButton clickHandler={handleDelete} type={"delete"} />
        </div>
        <div onClick={goTo} className="p-3">
          <div className="row">
            <div className="col d-flex flex-wrap">
              <TagElementPurple>
                <Bold14Font style={{ color: "inherit" }}>
                  {documentTypesMap[documentObject.document_type]}
                </Bold14Font>
              </TagElementPurple>
              {documentObject.bundles
                ? documentObject.bundles.map((v, index) => (
                    <TagElementCyan key={index}>
                      <Bold14Font style={{ color: "inherit" }}>
                        {v.label}
                      </Bold14Font>
                    </TagElementCyan>
                  ))
                : ""}
            </div>
          </div>
          <Bold18Font breakWord>
            {String(documentObject.name).length > 20
              ? String(documentObject.name).substring(0, 20) + "..."
              : documentObject.name}
          </Bold18Font>
          <div className="row mt-2">
            <div className="col d-flex flex-wrap justify-content-between justify-content-lg-center justify-content-xl-between align-items-center">
              <Medium14Font
                pointer
                onClick={(e) => {
                  onKeySectionClickHandler ? onKeySectionClickHandler(e) : null;
                }}
              >
                {documentTypesMap[documentObject.document_type] ===
                "Evidence" ? (
                  <Fragment>
                    <img
                      width={14}
                      height={14}
                      className="mr-2"
                      src={keyIcon}
                      alt={"key sections"}
                    />
                    {documentObject.sub_evidence
                      ? documentObject.sub_evidence.length
                      : 0}
                  </Fragment>
                ) : null}
              </Medium14Font>
              <Medium14Font>
                <img className="mr-2" src={pancilIcon} alt={"date"} />
                {dateCreated}
              </Medium14Font>
            </div>
          </div>
        </div>
      </div>
    </DocumentCardStyled>
  );
};

export default DocumentCard;
