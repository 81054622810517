import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { PulseLoader } from "react-spinners";
import {
  generateDocAdviceOnProofs,
  generateDocAuthorities,
  getAuthoritiesList,
  getCaseById,
  getEventsList,
  getEvidenceList,
  updateCase,
} from "../../Auth/auth";
import { useHistory, useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium30Font,
} from "../../../components/FontsNewComponent/Fonts";
import { theme } from "../../../styled-components/Theme/Theme";
import { PdfAdviceOnProofs } from "../../../components/Pdf/PdfAdviceOnProofs";
import styled from "styled-components/macro";
import TextAreaWide from "../../../components/inputs/TextArea/TextAreaWide";
import moment from "moment";
import rem from "../../../utils/rem";
import InputStyled from "../../../components/InputNew/styled/InputStyled";
import {TRIAL_AUTHORITIES_FLOW} from "./Authorities/Authorities";

const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 30px;
  margin: 15px 0;
  background-color: ${({ theme }) => theme.colors.white};
`;
const LiDiskStyled = styled.li`
  font-family: "PT Root UI", sans-serif;
  list-style: disc;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 500;
  font-size: ${rem("14px")};
`;
const LiStyled = styled.li`
  font-family: "PT Root UI", sans-serif;
  list-style: none;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 500;
  font-size: ${rem("14px")};
`;
const LiDisk = ({ children }) => {
  return <LiDiskStyled>{children}</LiDiskStyled>;
};
const dateFormatToCompare = "YYYYMMDD";
const dateFormat = "dd MMM yyyy";

const dateToInt = (date) => {
  if (date !== null) {
    return parseInt(moment(date).format(dateFormatToCompare));
  }
  return 0;
};
const Header = ({ children }) => {
  return (
    <p className="mt-3">
      <Bold14Font textColor={theme.colors.dark} style={{ fontSize: "15px" }}>
        {children}
      </Bold14Font>
    </p>
  );
};
const SubHeader = ({ children }) => {
  return (
    <p className="mt-3">
      <Bold14Font textColor={theme.colors.dark}>{children}</Bold14Font>
    </p>
  );
};
const PText = ({ children }) => {
  return (
    <p>
      <Medium14Font textColor={theme.colors.dark}>{children}</Medium14Font>
    </p>
  );
};
const abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export default function AdviceOnProofs() {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dataToPdf, setDataToPdf] = useState({});
  const [weeks, setWeeks] = useState(2);
  const [copies, setCopies] = useState(2);
  const [copiesSecond, setCopiesSecond] = useState(3);
  const [caseObject, setCaseObject] = useState(null);
  const [authorities, setAuthorities] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [coreEvidence, setCoreEvidence] = useState(null);
  const [documentsList, setDocumentsList] = useState([]);
  const [objectsOrderedByDateList, setObjectsOrderedByDateList] = useState([]);
  const [editMode, setEditMode] = useState(true);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const getDataFromAPI = async () => {
    const caseObjectResp = await getCaseById(id, dispatch, true);
    const authoritiesResp = await getAuthoritiesList(id, dispatch);
    const evidenceRespList = await getEvidenceList(id, dispatch);
    const eventsRespList = await getEventsList(id, dispatch);
    if (caseObjectResp && authoritiesResp) {
      setCaseObject(caseObjectResp);
      setCoreEvidence(
        evidenceRespList.sort(sort).filter((v) => v.core_book === 1)
      );
      saveData(evidenceRespList, eventsRespList);
      let unique = {};
      if (authoritiesResp.length) {
        authoritiesResp.forEach((v) => {
          if (typeof unique[v.type] === "undefined") {
            unique[v.type] = [];
          }
          unique[v.type].push(
            `${v.title}${v.jurisdiction ? " - " + v.jurisdiction : ""}${
              v.citation ? " - " + v.citation : ""
            }`
          );
        });
      }
      setAuthorities(unique);
      let docsWithBundle = {};
      caseObjectResp.documents.forEach((doc) => {
        if (doc.bundles && doc.bundles.length) {
          doc.bundles.forEach((bundle) => {
            if (!docsWithBundle[bundle.label]) {
              docsWithBundle[bundle.label] = [];
            }
            docsWithBundle[bundle.label].push(doc);
          });
        }
      });
      setDocumentsList(docsWithBundle);
      setIsLoading(false);
    }
  };
  const sort = (itemA, itemB) => {
    if (itemA.date && itemB.date) {
      return dateToInt(itemA.date) - dateToInt(itemB.date);
    } else if (itemA.date === null) {
      return 1;
    } else if (itemB.date === null) {
      return -1;
    }
  };
  const saveData = (evidenceRespList, eventsRespList) => {
    const sortedList = [...evidenceRespList, ...eventsRespList].sort(sort);
    setObjectsOrderedByDateList(
      sortedList.map((el, index) => ({
        id: index + 1,
        type: el.label ? "evidence" : "event",
        data: el,
      }))
    );
  };
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    getDataFromAPI();
  }, []);
  const PdfExport = () => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject) {
            setExporting(true);
            const blob = await pdf(
              <PdfAdviceOnProofs
                weeks={weeks}
                copies={copies}
                copiesSecond={copiesSecond}
                user={user}
                documentsList={documentsList}
                coreEvidence={coreEvidence}
                data={dataToPdf}
                authorities={authorities}
                caseObject={caseObject}
              />
            ).toBlob();
            saveAs(blob, `${caseObject.label} Advice on proofs.pdf`);
            setExporting(false);
          }
        }}
        loading={exporting}
        disabled={exporting}
        className="float-right"
        primary
      >
        Export to PDF
      </ButtonNew>
    );
  };
  const DocExport = ({as_pdf}) => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject) {
            setExporting(true);
            const data_to_doc = {
              data: dataToPdf,
              authorities: authorities,
              coreEvidence: coreEvidence,
              documentsList: documentsList,
              user: user,
              copiesSecond: copiesSecond,
              weeks: weeks,
              copies: copies,
              case_object: caseObject,
            };
            if (as_pdf) {
              data_to_doc.as_pdf = true;
            }
            const response = await generateDocAdviceOnProofs(dispatch, data_to_doc);
            const url = window.URL.createObjectURL(new Blob([response]));
            setExporting(false);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${caseObject.label} Advice on proofs.${as_pdf ? 'pdf' : 'docx'}`);
            document.body.appendChild(link);
            link.click();
          }
        }}
        loading={exporting}
        disabled={exporting}
        className={`float-right ${!as_pdf ? 'mr-2' : ''}`}
        primary
      >
        Export to {as_pdf ? 'PDF' : 'Docx'}
      </ButtonNew>
    );
  };
  const clickEditSaveButton = async () => {
    if (editMode) {
      setIsSaving(true);
      await updateCase(id, dispatch, { advice: caseObject.advice });
      setIsSaving(false);
    }
    setEditMode(true);
  };
  const addS = (val) => {
    if (!val) {
      return "s";
    } else if (val > 10 && val < 21) {
      return "s";
    } else if (val % 10 === 1) {
      return "";
    }
    return "s";
  };
  useEffect(() => {
    setDataToPdf(generatePdfData(objectsOrderedByDateList));
  }, [objectsOrderedByDateList]);
  const generatePdfData = (data) => {
    return {
      headers: [
        {
          name: "id",
          label: "ID",
          width: "5%",
        },
        {
          name: "date",
          label: "Date",
          width: "30%",
        },
        {
          name: "name",
          label: "Name",
          width: "65%",
        },
      ],
      rows: data.map((v) => {
        return {
          id: v.id,
          date: v.data.date ? moment(v.data.date).format("DD MMM YYYY") : "N/A",
          name:
            v.type === "evidence" ? v.data.label : "(event) " + v.data.title,
        };
      }),
    };
  };
  const ExportToPdf = useMemo(() => {
    return <DocExport as_pdf={true} />;
  }, [
    caseObject,
    coreEvidence,
    authorities,
    dataToPdf,
    documentsList,
    copiesSecond,
    copies,
    weeks,
    exporting
  ]);
  const ExportToDoc = useMemo(() => {
    return <DocExport />;
  }, [
    caseObject,
    coreEvidence,
    authorities,
    dataToPdf,
    documentsList,
    copiesSecond,
    copies,
    weeks,
    exporting
  ]);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }
  const pageConfig = [
    {
      path: !is_admin ? "/app/cases" : "/admin/all-cases",
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs`
        : `/admin/all-cases/${id}/trial-proofs`,
      title: `Trial Proofs`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs/trial-checklist/advice-on-proofs`
        : `/admin/all-cases/${id}/trial-proofs/trial-checklist/advice-on-proofs`,
      title: "Trial Checklist - Advice on Proofs",
      activeMenuItem: CASES,
    },
  ];
  const setFieldValue = (e) => {
    e.persist();
    setCaseObject((prev) => {
      let clone = { ...prev.advice };
      if (!clone) {
        clone = {};
      }
      clone[e.target.name] = e.target.value;
      return { ...prev, ...{ advice: clone } };
    });
  };
  const clients = caseObject.parties.filter((v) => {
    return v.client;
  });
  let documents_index = 0;
  let total_index = 0;
  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-8">
          <Bold30Font style={{ marginRight: "16px" }}>
            {caseObject.label}
          </Bold30Font>
          <Medium30Font>Advice On Proofs</Medium30Font>
        </div>
        <div className="col-12 col-sm-4 d-flex justify-content-end align-items-center mb-3 mr-0">
          <ButtonNew
            loading={isSaving}
            disabled={isSaving}
            clickHandler={clickEditSaveButton}
            className="mr-3"
            primary
          >
            {editMode ? "Save" : "Edit"}
          </ButtonNew>
          {caseObject ? ExportToDoc : null}
          {caseObject ? ExportToPdf : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Medium14Font textColor={"rgba(15, 18, 47, 0.6)"}>
            I have considered the papers furnished and advise as follows:
          </Medium14Font>
        </div>
      </div>
      <Box>
        <Header>Summary</Header>
        <PText>
          These are{" "}
          <Bold14Font>
            {caseObject.case_type ? caseObject.case_type.name : "[#Case Type]"}
          </Bold14Font>{" "}
          proceedings in which the client is{" "}
          <Bold14Font>
            {!clients.length
              ? "[#Plaintiff]"
              : clients.map((v) => v.name).join(", ")}
          </Bold14Font>
          .
        </PText>
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="summary"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={
              caseObject.advice && caseObject.advice.summary
                ? caseObject.advice.summary
                : ""
            }
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.summary
              ? caseObject.advice.summary
              : ""}
          </PText>
        )}
        <PText>The claim involves a claim for</PText>
        {caseObject.causes_of_action.filter((v) => !v.counterclaim).length ? (
          <ul className="pl-4 mb-4">
            {caseObject.causes_of_action
              .filter((v) => !v.counterclaim)
              .map((v) => {
                return <LiDisk>{v.type.name}</LiDisk>;
              })}
          </ul>
        ) : null}
        {caseObject.causes_of_action.filter((v) => v.counterclaim).length ? (
          <Fragment>
            <PText>Counterclaim</PText>
            {caseObject.causes_of_action.filter((v) => v.counterclaim)
              .length ? (
              <ul className="pl-4 mb-4">
                {caseObject.causes_of_action
                  .filter((v) => v.counterclaim)
                  .map((v) => {
                    return <LiDisk>{v.type.name}</LiDisk>;
                  })}
              </ul>
            ) : null}
          </Fragment>
        ) : null}
        <Header>Background</Header>
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="background"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={caseObject.advice && caseObject.advice.background}
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.background
              ? caseObject.advice.background
              : ""}
          </PText>
        )}
        <Header>Counsel’s View of the Case</Header>
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="case_theory"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={
              caseObject.advice && caseObject.advice.case_theory
                ? caseObject.advice.case_theory
                : caseObject.case_theory
            }
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.case_theory
              ? caseObject.advice.case_theory
              : caseObject.case_theory}
          </PText>
        )}
        <SubHeader>Elements of the Cause/s of Action</SubHeader>
        {caseObject.causes_of_action.filter((v) => !v.counterclaim).length
          ? caseObject.causes_of_action
              .filter((v) => !v.counterclaim)
              .map((v) => {
                return (
                  <Fragment>
                    <PText>
                      In order to establish the claim for{" "}
                      <Bold14Font>{v.type.name}</Bold14Font>, the following must
                      be established:
                    </PText>
                    <ul className="pl-4 mb-4">
                      {v.elements.map((e) => {
                        return <LiDisk>{e.name};</LiDisk>;
                      })}
                    </ul>
                  </Fragment>
                );
              })
          : null}
        {caseObject.causes_of_action.filter((v) => v.counterclaim).length
          ? caseObject.causes_of_action
              .filter((v) => v.counterclaim)
              .map((v) => {
                return (
                  <Fragment>
                    <PText>
                      The Counterclaim for{" "}
                      <Bold14Font>{v.type.name}</Bold14Font> depends on the
                      following established:
                    </PText>
                    <ul className="pl-4 mb-4">
                      {v.elements.map((e) => {
                        return <LiDisk>{e.name};</LiDisk>;
                      })}
                    </ul>
                  </Fragment>
                );
              })
          : null}
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="causes_of_action"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={
              caseObject.advice && caseObject.advice.causes_of_action
                ? caseObject.advice.causes_of_action
                : ""
            }
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.causes_of_action
              ? caseObject.advice.causes_of_action
              : ""}
          </PText>
        )}
        <Header>Themes in the Case</Header>
        <PText>
          The case can also be considered from the perspective of the following
          themes:
        </PText>
        <ul className="pl-4 mb-4">
          {caseObject.themes.map((v) => {
            return <LiDisk>{v.name}</LiDisk>;
          })}
        </ul>
        <Header>Analysis</Header>
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="analysis"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={
              caseObject.advice && caseObject.advice.analysis
                ? caseObject.advice.analysis
                : ""
            }
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.analysis
              ? caseObject.advice.analysis
              : ""}
          </PText>
        )}
        <Header>Witnesses</Header>
        <PText>
          The following witnesses should attend if available on the hearing
          date:
        </PText>
        <ul className="pl-4 mb-4">
          {caseObject.witnesses.map((v) => {
            return (
              <LiDisk>
                {v.name}
                {v.class || (v.party && v.party.role) ? <br /> : null}
                {v.class ? `${v.class},` : ""}
                {v.party && v.party.role ? v.party.role : ""}
              </LiDisk>
            );
          })}
          <LiDisk>
            Any other witness that can be identified who can provide salient
            evidence in relation to the Issues identified above or who may be
            necessary to prove any of the items of Evidence in the Core Booklet.
          </LiDisk>
        </ul>
        <PText>
          Please advise Counsel at the earliest opportunity if witnesses are
          unwilling or unavailable to attend at the hearing.
        </PText>

        <Header>Documents</Header>
        <PText>
          The following booklets, indexed and paginated, should be available for
          Counsel{" "}
          <InputStyled
            className="text-center"
            onChange={(e) => {
              setWeeks(e.target.value);
            }}
            value={weeks}
            style={{ width: "70px" }}
          />{" "}
          week{addS(weeks)} in advance of the trial, with a further{" "}
          <InputStyled
            onChange={(e) => {
              setCopies(e.target.value);
            }}
            value={copies}
            className="text-center"
            style={{ width: "70px" }}
          />
          + copie{addS(copies)} available for the Court and other parties.
        </PText>
        <SubHeader>A. Case Documents Booklet</SubHeader>
        {Object.keys(documentsList).map((doc_key, index) => {
          let k = index;
          if (coreEvidence && coreEvidence.length) {
            k += 1;
          }
          documents_index = k;
          return (
            <div key={k} className="ml-2 mb-3">
              <SubHeader>{doc_key}</SubHeader>
              {documentsList[doc_key].length
                ? documentsList[doc_key].map((doc, i) => {
                    return (
                      <LiStyled>
                        {i + 1}) {doc.name}
                      </LiStyled>
                    );
                  })
                : null}
            </div>
          );
        })}
        <SubHeader>B. Core Booklet</SubHeader>
        {coreEvidence && coreEvidence.length ? (
          <ul className="pl-4 mb-4">
            {coreEvidence.map((v, i) => {
              return (
                <LiStyled>
                  {i + 1}) {v.label}
                </LiStyled>
              );
            })}
          </ul>
        ) : null}
        <SubHeader>
          {abc[documents_index + 1]}. Inter Partes Correspondence
        </SubHeader>
        <ul className="pl-4 mb-4">
          <LiDisk>
            Separate booklets of without prejudice and open correspondence
            should be prepared.
          </LiDisk>
        </ul>
        <Header>Booklet of Authorities</Header>
        <PText>
          <InputStyled
            onChange={(e) => {
              setCopiesSecond(e.target.value);
            }}
            value={copiesSecond}
            className="text-center"
            style={{ width: "70px" }}
          />{" "}
          copie{addS(copiesSecond)} of the Booklets of Authorities should be
          available 7 days in advance of trial arranged in the following format:
        </PText>
        {Object.keys(authorities).length
          ? Object.keys(authorities).map((v, i) => {
              return (
                <Fragment>
                  <PText>
                    {abc[i]}. {v && v !== "null" ? v : "Without type"}
                  </PText>
                  <ul className="pl-4 mb-4">
                    {authorities[v].map((aut) => {
                      total_index++;
                      return (
                        <LiStyled>
                          {total_index}. {aut}
                        </LiStyled>
                      );
                    })}
                  </ul>
                </Fragment>
              );
            })
          : null}

        <Header>Pre-Trial Steps</Header>
        <PText>
          The following steps should be taken as soon as possible in advance of
          trial:
        </PText>
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="pre_trial"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={
              caseObject.advice && caseObject.advice.pre_trial
                ? caseObject.advice.pre_trial
                : ""
            }
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.pre_trial
              ? caseObject.advice.pre_trial
              : ""}
          </PText>
        )}

        <Header>Consultation</Header>
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="consultation"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={
              caseObject.advice && caseObject.advice.consultation
                ? caseObject.advice.consultation
                : ""
            }
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.consultation
              ? caseObject.advice.consultation
              : ""}
          </PText>
        )}
        <Header>Brief</Header>
        {editMode ? (
          <TextAreaWide
            without_max_len={true}
            name="brief"
            placeholder="Enter your text here"
            onChange={setFieldValue}
            value={
              caseObject.advice && caseObject.advice.brief
                ? caseObject.advice.brief
                : "Brief one Junior Counsel with Documents referred to in this Advice on Proofs, and a copy of this Advice on Proofs and prior advices of Counsel."
            }
          />
        ) : (
          <PText>
            {caseObject.advice && caseObject.advice.brief
              ? caseObject.advice.brief
              : "Brief one Junior Counsel with Documents referred to in this Advice on Proofs, and a copy of this Advice on Proofs and prior advices of Counsel."}
          </PText>
        )}
      </Box>
    </Fragment>
  );
}
