import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  createApplicationPaperFileById,
  createNewApplication,
  createNewApplicationPaper,
  createNewAuthority,
  deleteApplicationById,
  deleteCauseOfAction,
  getApplicationById,
  getCaseById,
  updateApplicationById,
  updateApplicationPaperById,
  updateAuthorityById,
  uploadAuthorityFile,
} from "../../../Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import Button from "../../../../components/buttons/Button/Button";
import Panel from "../../../../components/Panel/Panel";
import Form from "../../../../components/forms/Form";
import InputNew from "../../../../components/InputNew/InputNew";
import AssociationTable, {
  AssociationTableListElement,
} from "../../../../components/AssociationTable";
import plusIcon from "../../../../assets/img3/svg/button-plus-icon.svg";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import CloseIcon from "../../../../components/svgIcons/CloseIcon";
import SelectAnotherTry from "../../../../components/Select/SelectAnotherTry";
import AnimatedDiv from "../../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import CustomReactDatepicker from "../../../../components/inputs/CustomReactDatepicker";
import moment from "moment";
import { parseISO } from "date-fns";

import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import ReliefsAndProofs from "../../../../components/forms/ApplicationsHubForms/ReliefsAndProofs";
import ListAddButton from "../../../../components/buttons/ListAddButton/ListAddButton";
import Table from "../../../../components/Table/Table";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import ReliefsAndProofsCustom from "../../../../components/forms/ApplicationsHubForms/ReliefsAndProofsCustom";
import {
  criminalPartyRoles,
  partyRoles,
  partyTypes,
} from "../../Cases/partyTypesAndRoles";
import RespondentSidebar, {
  groupPartiesByRoles,
} from "../../../../components/forms/ApplicationsHubForms/RespondentSidebar";
import Arrow from "../../../../components/buttons/Button/Arrow";
import ApplicationPapers, {
  VIEW_SINGLE_FORM_PAPERS,
} from "./AddEditApplicationPapers";
import ApplicationAuthorities, {
  VIEW_SINGLE_FORM_AUTHORITIES,
} from "./ApplicationAuthorities";
import { handleErrorMessage } from "../../../../components/Modal/modalErrorHandler";
import { formFlowType } from "../../../../utils/constants";
import { closeBtnText } from "../../../../utils/constants";

const NEW_APPLICATION_LABEL = "New Application";

const createReliefsWithLinkedProofsList = (
  reliefsList = [],
  proofsList = [],
  setReliefsWithLinkedProofsList
) => {
  const reliefsWithLinkedProofsList = [];
  reliefsList.forEach((relief) => {
    const proofsIdsOfCurrentRelief =
      relief && relief.proofs ? relief.proofs.map((proof) => proof.id) : [];
    relief.proofs = [];
    proofsList.forEach((proof) => {
      if (proofsIdsOfCurrentRelief.indexOf(proof.id) !== -1) {
        relief.proofs.push(proof);
      }
    });
    reliefsWithLinkedProofsList.push(relief);
  });
  setReliefsWithLinkedProofsList([...reliefsWithLinkedProofsList]);
};

const AddEditApplication = ({ flow, theme }) => {
  // page states
  const location = useLocation();
  const [selectedObjectFromLocation, setSelectedObjectFromLocation] = useState(
    location.state && location.state.selectedObject
      ? location.state.selectedObject
      : null
  );
  const [isLoading, setIsLoading] = useState(true);
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const [blockBlurEvent, setBlockBlurEvent] = useState(false);
  const [applicationWorkflowStage, setApplicationWorkflowStage] = useState(
    location.state && location.state.applicationWorkflowStage
      ? location.state.applicationWorkflowStage
      : 0
  );
  const [
    keyForApplicationPapersComponent,
    setKeyForApplicationPapersComponent,
  ] = useState(0);
  const { id, applicationId } = useParams();
  const [caseObject, setCaseObject] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  // application states
  const [applicationObject, setApplicationObject] = useState({
    reliefs: [],
    proofs: [],
    authorities: [],
    papers: [],
  });
  const [
    applicationPaperSubmitObject,
    setApplicationPaperSubmitObject,
  ] = useState(null);
  const [
    applicationAuthoritySubmitObject,
    setApplicationAuthoritySubmitObject,
  ] = useState(null);
  const [
    applicationPapersCurrentView,
    setApplicationPapersCurrentView,
  ] = useState("");
  const [
    applicationAuthoritiesCurrentView,
    setApplicationAuthoritiesCurrentView,
  ] = useState("");
  const [submitObject, setSubmitObject] = useState(null);
  const [applicationLabel, setApplicationLabel] = useState(null);
  const [reliefsList, setReliefsList] = useState([]);
  const [proofsList, setProofsList] = useState([]);
  const [
    reliefsWithLinkedProofsList,
    setReliefsWithLinkedProofsList,
  ] = useState([]);
  const [movingPartiesOptionsList, setMovingPartiesOptionsList] = useState([]);
  const [
    movingPartiesSelectedObject,
    setMovingPartiesSelectedObject,
  ] = useState(null);
  const [respondentParties, setRespondentParties] = useState([]);
  const [
    groupedRespondentPartiesByRoleObject,
    setGroupedRespondentPartiesByRoleObject,
  ] = useState({});
  const [date, setDate] = useState(null);
  const [movingPartiesList, setMovingPartiesList] = useState([]);

  // save data from response object to state
  const saveData = (applicationObject, caseObject) => {
    setApplicationLabel(applicationObject.label);
    setReliefsList(applicationObject.reliefs ?? []);
    setProofsList(applicationObject.proofs ?? []);
    createReliefsWithLinkedProofsList(
      applicationObject.reliefs,
      applicationObject.proofs,
      setReliefsWithLinkedProofsList
    );
    const movingPartiesList_ = caseObject.parties.filter((prt) => {
      const roles =
        caseObject.new_case_type === "Criminal"
          ? criminalPartyRoles
          : partyRoles;
      return roles.map((party) => party.value).indexOf(prt.role) !== -1;
    });
    setMovingPartiesList(movingPartiesList_);
    setMovingPartiesOptionsList(
      movingPartiesList_.map((party) => ({
        ...party,
        label: `${party.name} - ${party.role}`,
        value: party.id,
      }))
    );
    setMovingPartiesSelectedObject(
      applicationObject && applicationObject.moving_parties
        ? applicationObject.moving_parties.map((party) => {
            return {
              label: `${party.name} - ${party.role}`,
              value: party.id,
            };
          })[0]
        : []
    );
    setRespondentParties(applicationObject.respondent_parties);
    const groupedParties = groupPartiesByRoles(
      applicationObject.respondent_parties
    );
    setGroupedRespondentPartiesByRoleObject(groupedParties);
    setDate(applicationObject.date ? parseISO(applicationObject.date) : null);
  };

  // close RSB when change applicationWorkflowStage
  useEffect(() => {
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  }, [applicationWorkflowStage]);

  const setSubmitAndApplicationObject = (object) => {
    setApplicationObject((prevState) => {
      saveData({ ...prevState, ...object }, caseObject);
      return {
        ...prevState,
        ...object,
      };
    });
    setSubmitObject((prevState) => ({
      ...prevState,
      ...object,
    }));
  };

  // get data from api
  const getCaseObjectFromAPI = async () => {
    if (isLoading) {
      const caseObjectResp = await getCaseById(id, dispatch);
      if (caseObjectResp) {
        setCaseObject(caseObjectResp);
        const movingPartiesList = caseObjectResp.parties.filter((prt) => {
          const roles =
            caseObjectResp.new_case_type === "Criminal"
              ? criminalPartyRoles
              : partyRoles;
          return roles.map((party) => party.value).indexOf(prt.role) !== -1;
        });
        setMovingPartiesOptionsList(
          movingPartiesList.map((party) => ({
            label: `${party.name} - ${party.role}`,
            value: party.id,
          }))
        );
        return caseObjectResp;
      }
    }
  };

  const getDataFromAPI = async () => {
    if (isLoading) {
      const caseObjectResp = await getCaseById(id, dispatch);
      if (is_admin && !caseObjectResp) {
        history.push("/admin/all-cases");
      }
      const applicationObjectResp = await getApplicationById(
        applicationId,
        id,
        dispatch
      );
      if (caseObjectResp && applicationObjectResp) {
        setCaseObject(caseObjectResp);
        setApplicationObject(applicationObjectResp);
        saveData(applicationObjectResp, caseObjectResp);
        setIsLoading(false);
      }
    }
  };

  // make request to API
  useEffect(() => {
    const getData = async () => {
      if (!caseObject) {
        let case_ = await getCaseObjectFromAPI();
        saveData({ label: "" }, case_);
      }
      if (applicationId) {
        if (isLoading && !applicationObject.id && applicationId) {
          await getDataFromAPI();
        } else if (!isLoading && caseObject) {
          saveData(applicationObject, caseObject);
        }
      } else {
        if (applicationObject && caseObject) {
          saveData(applicationObject, caseObject);
        }
      }
      setIsLoading(false);
    };
    getData();
  }, [applicationObject]);
  const isDocumentReturn = () => {
    return (
      location &&
      location.state &&
      location.state.unique_data &&
      location.state.unique_data.length
    );
  };
  const returnToFile = () => {
    if (
      location &&
      location.state &&
      location.state.selectedObject &&
      (location.state.applicationWorkflowStage === 2 ||
        location.state.applicationWorkflowStage === 1)
    ) {
      return true;
    }
    return false;
  };
  // submit data to API
  const submitHandler = async (submitObj = submitObject) => {
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    if (!submitObj) {
      submitObj = {};
    }
    submitObj.proofs = applicationObject.proofs;
    submitObj.reliefs = applicationObject.reliefs;
    const resp_ = applicationId
      ? await updateApplicationById(applicationId, id, dispatch, submitObj)
      : await createNewApplication(id, dispatch, submitObj);
    if (resp_) {
      setApplicationObject(resp_);
      saveData(resp_, caseObject);
    }
    if (
      applicationWorkflowStage === 1 &&
      applicationPapersCurrentView === VIEW_SINGLE_FORM_PAPERS &&
      applicationPaperSubmitObject
    ) {
      if (applicationPaperSubmitObject.date) {
        applicationPaperSubmitObject.date = moment(
          applicationPaperSubmitObject.date
        ).format("YYYY-MM-DD");
      }
      let resp;
      if ("id" in applicationPaperSubmitObject) {
        resp = await updateApplicationPaperById(
          applicationPaperSubmitObject.id,
          applicationId,
          id,
          dispatch,
          applicationPaperSubmitObject
        );
      } else {
        resp = await createNewApplicationPaper(
          applicationId,
          id,
          dispatch,
          applicationPaperSubmitObject
        );
      }
      if (
        resp &&
        typeof applicationPaperSubmitObject.file === "object" &&
        applicationPaperSubmitObject.file &&
        applicationPaperSubmitObject.file.size !== 0
      ) {
        let formData = new FormData();
        formData.append("file", applicationPaperSubmitObject.file);
        await createApplicationPaperFileById(
          resp.id,
          applicationId,
          id,
          dispatch,
          formData
        );
      }
    } else if (
      applicationWorkflowStage === 2 &&
      applicationAuthoritiesCurrentView === VIEW_SINGLE_FORM_AUTHORITIES &&
      applicationAuthoritySubmitObject
    ) {
      applicationAuthoritySubmitObject["id_application"] = applicationId;
      let resp;
      if ("id" in applicationAuthoritySubmitObject) {
        resp = await updateAuthorityById(
          applicationAuthoritySubmitObject.fake_id,
          id,
          dispatch,
          applicationAuthoritySubmitObject
        );
      } else {
        resp = await createNewAuthority(
          id,
          dispatch,
          applicationAuthoritySubmitObject
        );
      }
      if (
        typeof applicationAuthoritySubmitObject.file === "object" &&
        applicationAuthoritySubmitObject.file &&
        applicationAuthoritySubmitObject.file.size !== 0
      ) {
        let formData = new FormData();
        formData.append("file", applicationAuthoritySubmitObject.file);
        await uploadAuthorityFile(
          resp && resp.id ? resp.id : resp.fake_id ?? resp.id,
          id,
          dispatch,
          formData
        );
      }
    }
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  const onClickDeleteHandler = async () => {
    if (applicationId) {
      dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "You won't be able to restore data",
        beforeCloseHandler: async () => {
          if (formFlowType.EDIT) {
            await deleteApplicationById(applicationId, id, dispatch);
            history.push(
              !is_admin
                ? `/app/cases/${id}/applications-hub`
                : `/admin/all-cases/${id}/applications-hub`
            );
          } else {
            history.push(
              !is_admin
                ? `/app/cases/${id}/applications-hub`
                : `/admin/all-cases/${id}/applications-hub`
            );
          }
        },
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
      dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
    } else {
      history.push(
        !is_admin
          ? `/app/cases/${id}/applications-hub`
          : `/admin/all-cases/${id}/applications-hub`
      );
    }
  };

  const onClickSaveAndCloseHandler = async () => {
    if (isDocumentReturn()) {
      history.push(
        !is_admin
          ? `/app/cases/${id}/documents`
          : `/admin/all-cases/${id}/documents`
      );
    } else {
      await submitHandler();
      if (returnToFile()) {
        history.push(
          !is_admin
            ? `/app/cases/${id}/documents/${location.state.selectedObject.file_id}`
            : `/admin/all-cases/${id}/documents/${location.state.selectedObject.file_id}`
        );
      } else {
        history.push(
          !is_admin
            ? `/app/cases/${id}/applications-hub`
            : `/admin/all-cases/${id}/applications-hub`
        );
      }
    }
  };

  const onClickAddReliefsHandler = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ReliefsAndProofs
          key={object.id}
          object={applicationObject}
          setObject={setSubmitAndApplicationObject}
          routeParams={{ caseId: id, applicationId }}
          alreadySavedReliefs={applicationObject.reliefs}
          alreadySavedProofs={applicationObject.proofs}
        />
      ),
      title: "Add Relief",
    });
  };

  const onClickAddCustomReliefsHandler = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ReliefsAndProofsCustom
          key={object.id}
          object={applicationObject}
          setObject={setSubmitAndApplicationObject}
          routeParams={{ caseId: id, applicationId }}
          alreadySavedReliefs={applicationObject.reliefs}
          alreadySavedProofs={applicationObject.proofs}
        />
      ),
      title: "Add Relief",
    });
  };

  // Un-link relief from current application
  const onClickDeleteReliefHandler = async (object) => {
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    // setIsSubmitting(true);
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    const reliefsWithoutDeletedElementList = reliefsWithLinkedProofsList.filter(
      (relief) => relief.id !== object.id
    );
    // const newLinkedProofsList = reliefsWithoutDeletedElementList.map((relief) =>
    //   relief.proofs.map((proof) => proof)
    // );
    const newLinkedProofsList = reliefsWithoutDeletedElementList.reduce(
      (accum, relief) => {
        relief.proofs.forEach((proof) => {
          accum.push(proof);
        });
        return accum;
      },
      []
    );
    const submitObj = {
      reliefs: reliefsWithoutDeletedElementList,
      proofs: newLinkedProofsList,
    };
    setSubmitAndApplicationObject(submitObj);
    // setIsSubmitting(false);
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  const onClickEditReliefHandler = (object, applicationObject) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ReliefsAndProofs
          key={object.id}
          object={object}
          setObject={setSubmitAndApplicationObject}
          routeParams={{ caseId: id, applicationId }}
          alreadySavedReliefs={applicationObject.reliefs}
          alreadySavedProofs={applicationObject.proofs}
          actionType={"edit"}
        />
      ),
      title: `Edit Relief: ${object.name}`,
    });
  };

  const onClickAddRespondentHandler = (applicationObject) => {
    if (!movingPartiesSelectedObject) {
      handleErrorMessage(`Select Moving party first`, dispatch);
      return false;
    }
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <RespondentSidebar
          applicationObject={applicationObject}
          setApplicationObject={setSubmitAndApplicationObject}
          movingPartiesSelectedObject={movingPartiesSelectedObject}
          caseObject={caseObject}
          routeParams={{ caseId: id, applicationId: applicationId }}
        />
      ),
      title: "Select Respondent Parties",
    });
  };
  const onClickImportApplicationPapersHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setSelectedObjectFromLocation(null);
    setApplicationWorkflowStage(1);
  };

  // inputs methods

  const onChangeInputHandler = (event) => {
    event.persist();
    if (event.target.value.length <= 125) {
      switch (event.target.name) {
        case "label":
          setApplicationLabel(event.target.value);
          break;

        default:
          break;
      }
      setSubmitAndApplicationObject({
        [event.target.name]: event.target.value,
      });
    }
  };

  const onChangeSelectedMovingPartyHandler = (selectedObject) => {
    setMovingPartiesSelectedObject(selectedObject);
    const submitObj = { moving_parties: [{ ...selectedObject }] };
    setSubmitAndApplicationObject(submitObj);
  };

  const onChangeDateHandler = async (date) => {
    setDate(date);
    const submitObj = { date: moment(date).format("YYYY-MM-DD") };
    setSubmitAndApplicationObject(submitObj);
    // await submitHandler(submitObj);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  // settings objects

  const respondentSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Add Respondent Party",
        icon: plusIcon,
        callback: () => onClickAddRespondentHandler(applicationObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          // handleAssociateWitness(evidenceObject);
          onClickAddRespondentHandler(applicationObject);
        },
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          const uniquePartyRolesList = Object.keys(
            groupedRespondentPartiesByRoleObject
          );
          const filteredListOfPartiesByPartyRole = respondentParties.filter(
            (x) => x.role !== uniquePartyRolesList[index]
          );
          const submitObj = {
            respondent_parties: [...filteredListOfPartiesByPartyRole],
          };
          setSubmitAndApplicationObject(submitObj);
        },
      },
    ],
    // Objects to render in rows
    objects: {
      // Object that contain proofs arrays grouped by relief key
      // ...witnessesTableObject,
      ...groupedRespondentPartiesByRoleObject,
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      // function that update object in view
      // setWitnessTableObject: (data) => setWitnessTableObject(data),
      setGroupedRespondentPartiesByRoleObject: (data) =>
        setGroupedRespondentPartiesByRoleObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element) => (
              <AssociationTableListElement key={element.id}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={async () => {
                      const listCopy = [...array];
                      const role = listCopy[0].role;
                      const groupedRespondentPartiesByRoleObjectCopy = {
                        ...groupedRespondentPartiesByRoleObject,
                      };
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...groupedRespondentPartiesByRoleObjectCopy,
                          [role]: listCopy,
                        };
                        setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        const submitObj = { respondent_parties: plainArray };
                        // setSubmitObject((prevState) => ({
                        //   ...prevState,
                        //   ...submitObj,
                        // }));
                        setSubmitAndApplicationObject(submitObj);
                        // await submitHandler(submitObj);
                      }
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  const reliefsTableSettings = {
    collapsed: false,
    actions: [
      {
        type: "edit",
        callback: (object) =>
          onClickEditReliefHandler(object, applicationObject),
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              await onClickDeleteReliefHandler(object);
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "name",
        label: "RELIEF",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "proofs",
        label: "PROOFS",
        renderer: (object) => {
          if (object.proofs && object.proofs.length) {
            return (
              <ul>
                {object.proofs.map((proof) => {
                  return (
                    <LiStyled key={proof.id}>
                      <Medium14Font>{proof.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
    ],
  };

  const pageConfig = [
    {
      path: !is_admin ? "/app/cases" : "/admin/all-cases",
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/applications-hub`
        : `/admin/all-cases/${id}/applications-hub`,
      title: `Applications Hub`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? applicationId
          ? `/app/cases/${id}/applications-hub/edit/${applicationId}`
          : `/app/cases/${id}/applications-hub/create`
        : applicationId
        ? `/admin/all-cases/${id}/applications-hub/edit/${applicationId}`
        : `/admin/all-cases/${id}/applications-hub/create`,
      title:
        applicationObject && applicationObject.label
          ? applicationObject.label
          : NEW_APPLICATION_LABEL,
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 mb-2 col-sm-8 mb-sm-0 ">
          <Bold30Font>
            {applicationLabel && applicationLabel !== ""
              ? applicationLabel
              : NEW_APPLICATION_LABEL}
          </Bold30Font>
        </div>
        <div className="col-12 col-sm-4 d-flex justify-content-end align-items-center mb-3">
          <Button
            clickHandler={onClickDeleteHandler}
            loading={isSubmitting}
            disabled={isSubmitting}
            style={{ marginRight: "20px" }}
            secondary
            delete_
            onMouseEnter={() => setBlockBlurEvent(true)}
            onMouseLeave={() => setBlockBlurEvent(false)}
          >
            Delete
          </Button>
          <Button
            clickHandler={onClickSaveAndCloseHandler}
            disabled={isSubmitting}
            loading={isSubmitting}
            type={"submit"}
            primary
            onMouseEnter={() => setBlockBlurEvent(true)}
            onMouseLeave={() => setBlockBlurEvent(false)}
          >
            <Bold14Font textColor={theme.colors.white}>
              {closeBtnText}
            </Bold14Font>
          </Button>
        </div>
      </div>
      <div className="container-fluid">
        <Panel
          onChange={(index) => {
            setSelectedObjectFromLocation(null);
            setApplicationWorkflowStage(index);
          }}
          activeItem={applicationWorkflowStage}
          items={[
            "Basic Info",
            "Application Papers",
            "Application Authorities",
          ]}
        />
      </div>

      {/* Basic Info */}
      {applicationWorkflowStage === 0 ? (
        <div className="container-fluid">
          <div className="row">
            <Form style={{ overflowY: "visible" }}>
              <div style={{ padding: "0 15px" }}>
                <div className="form-group">
                  <InputNew
                    // onBlur={onBlurInputHandler}
                    name="label"
                    type="text"
                    value={applicationLabel}
                    placeholder="Enter application label"
                    label="Application label"
                    onChange={onChangeInputHandler}
                    maxLength={125}
                  />
                </div>
                <div className="form-group row">
                  <div className="col-12 col-sm-6">
                    <SelectAnotherTry
                      label="Moving Party"
                      name="moving_parties"
                      placeholder={"Select moving party"}
                      options={movingPartiesOptionsList}
                      value={movingPartiesSelectedObject}
                      onChange={onChangeSelectedMovingPartyHandler}
                      // onBlur={onBlurInputHandler}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div
                    className={
                      respondentParties && respondentParties.length
                        ? "col-12"
                        : "col-12 col-sm-6"
                    }
                  >
                    <AssociationTable
                      settings={respondentSettings}
                      label="Respondent party"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-sm-6">
                    <CustomReactDatepicker
                      selected={date}
                      dateFormat={"dd/MM/yyyy"}
                      dateFormatCalendar={"dd MMMM yyyy"}
                      onChange={onChangeDateHandler}
                      label="Issue Date"
                      name="date"
                      placeholderText="Select Date"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="row">
            <div className="col">
              <Table
                settings={reliefsTableSettings}
                data={reliefsWithLinkedProofsList}
                addButtonLabel="Add Relief"
                title={"Reliefs"}
                addButtonFunction={onClickAddReliefsHandler}
                customButton={
                  <ListAddButton
                    label={"Add Custom Relief"}
                    clickHandler={onClickAddCustomReliefsHandler}
                  />
                }
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* Application Papers */}
      {applicationWorkflowStage === 1 ? (
        <ApplicationPapers
          key={keyForApplicationPapersComponent}
          applicationObject={applicationObject}
          blockBlurEvent={blockBlurEvent}
          caseObject={caseObject}
          setApplicationObject={setApplicationObject}
          setBlockBlurEvent={setBlockBlurEvent}
          resetStatesViaKeyValue={setKeyForApplicationPapersComponent}
          changeFlowStage={(index) => {
            setSelectedObjectFromLocation(null);
            setApplicationWorkflowStage(index);
          }}
          selectedObjectFromLocation={selectedObjectFromLocation}
          setSelectedObjectFromLocation={setSelectedObjectFromLocation}
          setApplicationPaperSubmitObject={setApplicationPaperSubmitObject}
          setApplicationPapersCurrentView={setApplicationPapersCurrentView}
        />
      ) : null}

      {/* Application Authorities */}
      {applicationWorkflowStage === 2 ? (
        <ApplicationAuthorities
          caseObject={caseObject}
          applicationObject={applicationObject}
          setApplicationObject={setApplicationObject}
          changeFlowStage={(index) => {
            setSelectedObjectFromLocation(null);
            setApplicationWorkflowStage(index);
          }}
          selectedObjectFromLocation={selectedObjectFromLocation}
          blockBlurEventParent={blockBlurEvent}
          setBlockBlurEventParent={setBlockBlurEvent}
          setApplicationAuthoritySubmitObject={
            setApplicationAuthoritySubmitObject
          }
          setSelectedObjectFromLocation={setSelectedObjectFromLocation}
          setApplicationAuthoritiesCurrentView={
            setApplicationAuthoritiesCurrentView
          }
        />
      ) : null}
      {applicationWorkflowStage === 0 ? (
        <div
          className="row mb-4 justify-content-end"
          style={{ paddingRight: "15px" }}
        >
          <ButtonNew
            withIcon
            secondary
            clickHandler={onClickImportApplicationPapersHandler}
          >
            <Fragment>
              <Bold14Font textColor={theme.colors.blue}>
                Import Application Papers
              </Bold14Font>
              <Arrow className="ml-1" />
            </Fragment>
          </ButtonNew>
        </div>
      ) : null}
    </Fragment>
  );
};

AddEditApplication.propTypes = {
  flow: PropTypes.oneOf([formFlowType.CREATE, formFlowType.EDIT]),
};

AddEditApplication.defaultProps = {
  flow: formFlowType.EDIT,
};

export default withTheme(AddEditApplication);
