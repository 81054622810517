import React, { Fragment, useEffect, useState } from "react";
import {
  deleteDocument,
  getCaseById,
  getDocument,
  updateDocument,
  uploadDocumentFile,
} from "../../Auth/auth";
import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
} from "../../../components/FontsNewComponent/Fonts";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { parseISO } from "date-fns";
import Form from "../../../components/forms/Form";
import Input from "../../../components/Input";
import CustomReactDatepicker from "../../../components/inputs/CustomReactDatepicker";
import UploadSingleFileInput from "../../../components/inputs/UploadSingleFileInput";
import AssociationTable, {
  AssociationTableListElement,
} from "../../../components/AssociationTable";
import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import CloseIcon from "../../../components/svgIcons/CloseIcon";
import BundlesSelectForm from "./BundlesSelectForm";
import moment from "moment";
import { criminalPartyRoles, partyRoles } from "../Cases/partyTypesAndRoles";
import { groupPartiesByRoles } from "../../../components/forms/ApplicationsHubForms/RespondentSidebar";
import PanelParties from "../../../components/forms/CasesForms/PanelParties";
import {
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  EVIDENCE_DOCUMENTS,
} from "./Documents";
import { maxFileSize } from "../../../utils";
import { closeBtnText } from "../../../utils/constants";

const EditDocument = () => {
  const [caseObject, setCaseObject] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [document, setDocument] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [handlingSave, setHandlingSave] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [partiesList, setPartiesList] = useState([]);
  const [documentPartiesList, setDocumentPartiesList] = useState([]);
  const [groupedPartiesByRoleObject, setGroupedPartiesByRoleObject] = useState(
    {}
  );
  const [file, setFile] = useState(new File([""], ""));
  const { id, documentId } = useParams();
  const [date, setDate] = useState(null);
  const [name, setName] = useState("");
  const [bundles, setBundles] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageConfig, setPageConfig] = useState([]);
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  const saveData = (caseResp, resp) => {
    // setName(resp.name);
    setName(resp.name);
    setDate(resp.date ? parseISO(resp.date) : null);
    setBundles(resp.bundles);
    setFile(new File([""], resp.file));
    setPageConfig([
      {
        path: "/app/cases",
        title: `${is_admin ? "All Cases" : "Cases"}`,
        activeMenuItem: CASES,
      },
      {
        path: !is_admin ? "/app/cases" : "/admin/all-cases",
        title: caseResp.label,
        activeMenuItem: CASES,
      },
      {
        path: !is_admin
          ? `/app/cases/${id}/documents`
          : `/admin/all-cases/${id}/documents`,
        title: `Documents Hub`,
        activeMenuItem: CASES,
      },
      {
        path: !is_admin
          ? `/app/cases/${id}/documents/edit/${documentId}`
          : `/admin/all-cases/${id}/documents/edit/${documentId}`,
        title: resp.name,
        activeMenuItem: CASES,
      },
    ]);
  };

  useEffect(() => {
    if (file.size !== 0) {
      setFile(file);
      // const submitData = async () => {
      //   let formData = new FormData();
      //   formData.append("file", file);
      //   const resp = await uploadDocumentFile(
      //     documentId,
      //     id,
      //     dispatch,
      //     formData
      //   );
      // };
      // submitData();
    }
  }, [file]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const caseResp = await getCaseById(id, dispatch);
      const resp = await getDocument(id, documentId, dispatch);
      if (resp.classes && resp.classes.id) {
        if (resp.document_type === EVIDENCE_DOCUMENTS) {
          history.push(
            !is_admin
              ? `/app/cases/${id}/trial-hub/evidence/edit/${document.classes.fake_id}`
              : `/admin/all-cases/${id}/trial-hub/evidence/edit/${document.classes.fake_id}`
          );
        } else if (resp.document_type === AUTHORITIES_DOCUMENTS) {
          history.push(
            !is_admin
              ? `/app/cases/${id}/trial-hub/authorities/edit/${document.classes.fake_id}`
              : `/admin/all-cases/${id}/trial-hub/authorities/edit/${document.classes.fake_id}`
          );
        } else if (resp.document_type === APPLICATION_DOCUMENTS) {
          history.push(
            !is_admin
              ? `/app/cases/${id}/applications-hub/${document.classes.fake_id_application}`
              : `/admin/all-cases/${id}/applications-hub/${document.classes.fake_id_application}`
          );
        }
      }
      if (caseResp) {
        setCaseObject(caseResp);
        let roles =
          caseResp.new_case_type === "Criminal"
            ? criminalPartyRoles
            : partyRoles;
        const respondentPartiesRolesList = roles.map((party) => party.value);
        const filteredRespondentPartiesFromCase = caseResp.parties.filter(
          (party) => respondentPartiesRolesList.indexOf(party.role) !== -1
        );
        setPartiesList(filteredRespondentPartiesFromCase);
        const groupedObject = groupPartiesByRoles(
          filteredRespondentPartiesFromCase
        );
        setGroupedPartiesByRoleObject(groupedObject);
        setDocument(resp);
        saveData(caseResp, resp);
        setIsLoading(false);
        return true;
      } else {
        if (is_admin && !caseResp) {
          history.push("/admin/all-cases");
        }
      }
      setIsLoading(false);
      return false;
    };
    getData();
  }, []);
  useEffect(() => {
    if (document && document.parties) {
      const roles =
        caseObject.new_case_type === "Criminal"
          ? criminalPartyRoles
          : partyRoles;
      const respondentPartiesRolesList = roles.map((party) => party.value);
      const filteredRespondentPartiesFromCase = document.parties.filter(
        (party) => respondentPartiesRolesList.indexOf(party.role) !== -1
      );
      setPartiesList(filteredRespondentPartiesFromCase);
      const groupedObject = groupPartiesByRoles(
        filteredRespondentPartiesFromCase
      );
      setDocumentPartiesList(groupedObject);
    }
  }, [document]);
  const onDocChange = (e) => {
    setName(e.target.value);
  };

  const handleDelete = async (event) => {
    event.persist();
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteDocument(id, documentId, dispatch);
        history.push(
          !is_admin
            ? `/app/cases/${id}/documents`
            : `/admin/all-cases/${id}/documents`
        );
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const submitOnEnter = async (e) => {
    e.preventDefault();
    if (!handlingSave) {
      await handleSave();
    }
    if (history.action === "PUSH") {
      return history.goBack();
    } else {
      history.push(
        !is_admin
          ? `/app/cases/${id}/documents`
          : `/admin/all-cases/${id}/documents`
      );
    }
  };

  const handleSave = async (date_) => {
    setHandlingSave(true);
    let d;
    if (typeof date_ !== "undefined") {
      d = date_;
    } else {
      d = date;
    }
    let data = {
      name: name,
      date: d ? moment(d).format("YYYY-MM-DD") : "",
      parties: JSON.stringify(document.parties),
      bundles: JSON.stringify(bundles),
    };
    let formData = new FormData();
    if (file.size > 0) {
      formData.append("file", file);
    }
    Object.keys(data).forEach((v) => {
      formData.append(v, data[v]);
    });
    const resp = await uploadDocumentFile(documentId, id, dispatch, formData);
    if (resp) {
      setDocument(resp);
      saveData(caseObject, resp);
      setIsLoading(false);
    }
    setHandlingSave(false);
  };

  const onChangeDateHandler = (date) => {
    setDate(date);
  };

  const updateBundles = async (data) => {
    setBundles(data);
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const handleBundles = (object, class_name) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <BundlesSelectForm
          saving={isSaving}
          class_name={class_name}
          bundles={object}
          idCase={id}
          callback={updateBundles}
        />
      ),
      title: "Assign Bundles",
    });
  };
  const onClickAddHandler = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <PanelParties
          caseRoles={true}
          returnData={updateParties}
          savedPartiesList={document.parties}
          applicationPaperObject={document}
          setApplicationPaperObject={setDocument}
          caseObject={caseObject}
          routeParams={{ caseId: id }}
        />
      ),
      title: "Select Parties",
    });
  };
  const updateParties = async (parties) => {
    setDocument({ ...document, ...parties });
  };
  const partiesSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Add Party",
        icon: plusIcon,
        callback: () => onClickAddHandler(),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          onClickAddHandler();
        },
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          const uniquePartyRolesList = Object.keys(groupedPartiesByRoleObject);
          const filteredListOfPartiesByPartyRole = partiesList.filter(
            (x) => x.role !== uniquePartyRolesList[index]
          );
          await updateParties({ parties: [] });
        },
      },
    ],
    objects: {
      ...documentPartiesList,
    },
    setObjectsFunctions: {
      setGroupedPartiesByRoleObject: (data) =>
        setGroupedPartiesByRoleObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element) => (
              <AssociationTableListElement key={element.id}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={async () => {
                      const listCopy = [...array];
                      const role = listCopy[0].role;
                      const groupedPartiesByRoleObjectCopy = {
                        ...groupedPartiesByRoleObject,
                      };
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...groupedPartiesByRoleObjectCopy,
                          [role]: listCopy,
                        };
                        setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        await updateParties({ parties: plainArray });
                      }
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };
  const bundlesSettings = {
    actions: [
      {
        type: "addButton",
        buttonText: "Assign to Bundles",
        icon: plusIcon,
        callback: (e) => {
          handleBundles(bundles, document.document_type);
        },
      },
      {
        type: "edit",
        clickHandler: (index) => {
          handleBundles(bundles, document.document_type);
        },
      },
      {
        type: "delete",
        clickHandler: () => {
          setBundles([]);
          updateBundles([]);
        },
      },
    ],
    objects: {
      bundles,
    },
    setObjectsFunctions: {
      setWitnessTableObject: (data) => setBundles(data),
    },
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element) => (
              <AssociationTableListElement key={element.id}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={() => {
                      setBundles(array.filter((v) => v.id !== element.id));
                      updateBundles(array.filter((v) => v.id !== element.id));
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-6">
          <Bold30Font>{document.name}</Bold30Font>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center">
          <ButtonNew
            clickHandler={handleDelete}
            loading={onDelete}
            disabled={onDelete}
            style={{ marginRight: "20px" }}
            secondary
            delete_
          >
            Delete
          </ButtonNew>
          <ButtonNew
            clickHandler={(e) => {
              submitOnEnter(e);
            }}
            type={"submit"}
            loading={handlingSave}
            disabled={handlingSave}
            primary
          >
            {closeBtnText}
          </ButtonNew>
        </div>
      </div>
      <div className="mt-5">
        <Form style={{ overflow: "visible" }} className="pl-2 pr-2">
          <div>
            <div className="form-group">
              <Input
                name="label"
                type="text"
                value={name}
                placeholder="Enter doc label"
                label="Doc Label"
                onChange={onDocChange}
                maxLength={125}
              />
            </div>
            <div className="form-group row">
              <div className="col-12 col-sm-6">
                <CustomReactDatepicker
                  selected={date}
                  popperPlacement="top-start"
                  onChange={(date) => onChangeDateHandler(date)}
                  dateFormat={"dd/MM/yyyy"}
                  dateFormatCalendar={"dd MMMM yyyy"}
                  label="Date"
                  name="date"
                  placeholderText="Select Date"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12 col-sm-6">
                <UploadSingleFileInput
                  label="Upload File"
                  file={file}
                  setFile={setFile}
                  validTypes={[
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "application/pdf",
                  ]}
                  maxFileSize={maxFileSize}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className={bundles.length ? "col-12" : "col-12 col-sm-6"}>
                <AssociationTable
                  settings={bundlesSettings}
                  label="Multiple Bundles"
                />
              </div>
            </div>
            <div className="form-group row">
              <div
                className={partiesList.length ? "col-12" : "col-12 col-sm-6"}
              >
                <AssociationTable settings={partiesSettings} label="Parties" />
              </div>
            </div>
            <button style={{ display: "none" }} />
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default EditDocument;
