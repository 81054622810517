import React, { Fragment, useState } from "react";
import Avatar from "react-avatar";
import UserBlockStyled from "./UserBlockStyled";
import ButtonNew from "../../buttons/Button/ButtonNew";
import blueBlockIcon from "../../../assets/img/svg/blueBlockIcon.svg";
import { useHistory } from "react-router-dom";
import { route } from "../../../routes";
import {
  blockUnblockUser,
  deleteAdminUser,
} from "../../../containers/Auth/auth";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SELECT_USER,
  SHOW_MODAL,
} from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../../styled-components/Theme/Theme";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import CaseCardTypeStyled from "../CaseCard/styled/CaseCardTypeStyled";
import iconCountry from "../../../assets/img/svg/iconCountry.svg";
import { blockToggleUser } from "../../../defaultActions";
import moment from "moment";

const UserDetails = ({ user }) => {
  const currentUser = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [blocking, setBlocking] = useState(false);
  const [object, setObject] = useState(user);
  const dispatch = useDispatch();
  const blockToggle = () => {
    setBlocking(true);
    blockToggleUser(object, setObject, dispatch);
    setBlocking(false);
  };
  const goToPaymentHistory = () => {
    history.push(`${route.admin.baseApp.user}/${object.id}/payment-history`, {
      userObject: object,
    });
  };
  const deleteFunction = () => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteAdminUser(object.id, dispatch);
        history.push(route.admin.baseApp.userList);
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };
  return (
    <UserBlockStyled className="p-4 mt-3 mb-5 row">
      <div className="col-lg-4">
        <div className="row">
          <div className="col-sm-6">
            <Avatar
              src={user.photo}
              round={"120px"}
              name={user.name}
              size={120}
            />
          </div>
          <div className="col-sm-6 d-flex flex-column">
            <Bold30Font>{user.name}</Bold30Font>
            <Medium14Font className="mt-1" textColor={theme.colors.darkOpacity}>
              {user.email}
            </Medium14Font>
            <CaseCardTypeStyled
              className="d-flex align-items-center mr-0 pr-1 mt-2"
              style={{ width: "fit-content" }}
            >
              <img className="ml-1 mr-1" src={iconCountry} />
              {user.country}
            </CaseCardTypeStyled>
          </div>
        </div>
      </div>
      <div className="col-lg-8 d-flex flex-column justify-content-between">
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-end align-items-center">
            {!user.is_admin ? (
              <Fragment>
                <ButtonNew
                  style={{ height: "44px" }}
                  clickHandler={goToPaymentHistory}
                  secondary
                >
                  Payment History
                </ButtonNew>
                <div
                  className="ml-3"
                  style={{
                    width: "1px",
                    backgroundColor: theme.colors.gray,
                    height: "20px",
                  }}
                />
              </Fragment>
            ) : null}
            <ButtonNew
              style={{ height: "44px" }}
              className="ml-3"
              clickHandler={deleteFunction}
              secondary
              delete_={true}
            >
              Delete
            </ButtonNew>
            <ButtonNew
              style={{ height: "44px" }}
              className="ml-3"
              clickHandler={blockToggle}
              loading={blocking}
              withIcon
              secondary
            >
              <img className="mr-1" src={blueBlockIcon} alt={"block"} />{" "}
              {!object.active ? "Un-Block" : "Block"}
            </ButtonNew>
            <ButtonNew
              style={{ height: "44px" }}
              primary
              clickHandler={() => {
                history.push(`${route.admin.baseApp.user}/${user.id}`);
              }}
              className="ml-3"
            >
              Edit
            </ButtonNew>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-end">
            <div className="mr-3">
              <Bold14Font>Role:</Bold14Font>
              <Medium14Font className="ml-1">{user.role}</Medium14Font>
            </div>
            <div className="mr-3">
              <Bold14Font>Cases:</Bold14Font>
              <Medium14Font className="ml-1">{user.cases}</Medium14Font>
            </div>
            <div className="mr-3">
              <Bold14Font>Space usage:</Bold14Font>
              <Medium14Font className="ml-1">{user.space}</Medium14Font>
            </div>
            <div className="mr-3">
              <Bold14Font>Last login:</Bold14Font>
              <Medium14Font className="ml-1">{moment(user.last_login).format("DD/MM/YY")}</Medium14Font>
            </div>
          </div>
        </div>
      </div>
    </UserBlockStyled>
  );
};

export default UserDetails;
