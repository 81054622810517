import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import { route } from "../../../routes";
import PageConfiguration from "../BreadCrumbs/PageConfiguration";
import DivCardStyled from "./styled/DivCardStyled";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../assets/img/svg/deleteCard.svg";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { Bold18Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import moment from "moment";
import Table from "../../Table";
import FormHeader from "../../forms/Form/styled/FormHeader";
import {
  cancelUserSubscription,
  deleteCard,
  getActiveSubscription,
  swapSubscription,
} from "../../../containers/Auth/auth";
import {
  HIDE_MODAL,
  MODAL_ADD_CARD_ELEMENT,
  MODAL_CONFIRM_ELEMENT,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  UPDATE_USER,
} from "../../../redux/types";
import DivAddCardStyled from "./styled/DivAddCardStyled";
import ListAddButton from "../../buttons/ListAddButton";
import ModalForm from "../../forms/AddCardForm/ModalForm";
import FreeLicensePlanCard from "../../FreeLicensePlanCard";
import { USER_LIST } from "../BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { Bold14Font } from "../../Fonts/Fonts";
import NoRecords from "../../NoRecords";

const PaymentHistory = ({
  history_,
  active,
  isCanceled,
  setIsCanceled,
  userObject,
  theme,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // TODO: Can I do this with hooks?
  const userFromReduxStore = useSelector((state) => state.auth.user);
  const user = userObject ? userObject : userFromReduxStore;
  const userIsAdmin = useSelector((state) => state.auth.user.is_admin);

  const pageConfig = !userIsAdmin
    ? [
        {
          path: `${route.baseApp.subscription.plans}`,
          title: "Plans",
        },
        {
          path: `${route.baseApp.subscription.paymentHistory}`,
          title: "Payment History",
        },
      ]
    : [
        {
          path: route.admin.baseApp.userList,
          title: "User List",
          activeMenuItem: USER_LIST,
        },
        {
          path: `${route.admin.baseApp.user}/${userObject.id}/details`,
          title: userObject.name,
          activeMenuItem: USER_LIST,
        },
        {
          path: `${route.admin.baseApp.user}/${userObject.id}/details/payment-history`,
          title: "Payment History",
          activeMenuItem: USER_LIST,
        },
      ];

  let ends;
  if (!user.trial_ends_at || !(user.features && user.features.length)) {
    ends = null;
  } else {
    let now = moment(new Date());
    let trial = moment.utc(user.trial_ends_at);
    if (now < trial) {
      ends = trial.format("D/M/YY");
    } else {
      ends = null;
    }
  }
  const settings = {
    actions: [],
    fields: [
      {
        name: "plan",
        label: "SUBSCRIPTION PLAN",
        renderer: (object) => {
          return <Medium14Font>{object.plan}</Medium14Font>;
        },
      },
      {
        name: "id",
        label: "TRANSACTION ID",
        renderer: (object) => {
          return (
            <Medium14Font
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {object.id}
            </Medium14Font>
          );
        },
      },
      {
        name: "date",
        label: "DATE",
        renderer: (object) => {
          return <Medium14Font>{object.date}</Medium14Font>;
        },
      },
      {
        name: "total",
        label: "TOTAL",
        renderer: (object) => {
          return <Medium14Font>{object.total}</Medium14Font>;
        },
      },
    ],
  };
  const dispatch = useDispatch();

  const removeCard = async () => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "That you want to delete card?",
      beforeCloseHandler: async () => {
        let resp = await deleteCard(dispatch);
        if (resp) {
          dispatch({ type: UPDATE_USER, payload: resp.user });
        }
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };
  const cancelSubscription = async () => {
    if (!isLoading) {
      setIsLoading(true);
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "That you want to cancel subscription?",
        beforeCloseHandler: async () => {
          let resp = await cancelUserSubscription(
            { product_id: active.product_id },
            dispatch
          );
          if (resp) {
            dispatch({ type: UPDATE_USER, payload: resp.user });
            setIsCanceled(true);
          }
        },
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_CONFIRM_ELEMENT });
      setIsLoading(false);
    }
  };
  const afterCardSubmitted = (user) => {
    dispatch({ type: UPDATE_USER, payload: user });
    dispatch({ type: HIDE_MODAL });
  };
  const addCard = async () => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: <ModalForm afterSubmit={afterCardSubmitted} />,
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_ADD_CARD_ELEMENT });
  };

  const renewSubscriptionHandler = async () => {
    if (!isLoading) {
      setIsLoading(true);
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "Do you want to re-new your current subscription?",
        beforeCloseHandler: async () => {
          const resp = await getActiveSubscription(dispatch);
          const data = {
            product_id: resp.data.product_id,
            price_id: resp.data.price_id,
          };
          const respSwapSubscription = await swapSubscription(data, dispatch);
          if (respSwapSubscription.result === "success") {
            setIsCanceled(false);
          }
        },
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_CONFIRM_ELEMENT });
      setIsLoading(false);
    }
  };
  const active_ = active
    ? moment(active.current_period_end) >= moment(new Date())
    : false;
  const adminLeftSideInfo =
    history_ && history_.length && !active_ ? (
      <Fragment>
        <div style={{ marginBottom: "30px" }}>
          {active_ ? (
            <Medium14Font>Free until {active.trial_ends_at}</Medium14Font>
          ) : null}
        </div>
        <FreeLicensePlanCard
          checked={false}
          subscriptionPlanObject={active}
          withPriceLayout
          noPointer
          style={{ marginBottom: "12px" }}
        />
        <Medium14Font as="div">
          Started on {history_[history_.length - 1].date}
        </Medium14Font>
        <Medium14Font as="div">
          {active_ ? "Expires" : "Expired"} on{" "}
          {active && active.current_period_end ? active.current_period_end : ""}
        </Medium14Font>
      </Fragment>
    ) : null;

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className={"row p-3"}>
        <div className="col-lg-4">
          {user.card_last_four ? (
            <DivCardStyled className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <Bold18Font textColor={theme.colors.white}>
                  {user.card_brand ? user.card_brand.toUpperCase() : ""}
                </Bold18Font>
                <Bold18Font className="ml-3" textColor={theme.colors.white}>
                  {user.card_last_four}
                </Bold18Font>
              </div>
              {!userIsAdmin ? (
                <img
                  onClick={removeCard}
                  style={{ cursor: "pointer" }}
                  src={deleteIcon}
                />
              ) : null}
            </DivCardStyled>
          ) : (
            <DivAddCardStyled className="d-flex justify-content-center align-items-center mb-4">
              {!userIsAdmin ? (
                <ListAddButton label="Add Card" clickHandler={addCard} />
              ) : (
                <Bold14Font textColor={theme.colors.burgundy}>
                  User has no card
                </Bold14Font>
              )}
            </DivAddCardStyled>
          )}
          {!userIsAdmin ? (
            <Fragment>
              {user.card_last_four ? (
                <ButtonNew
                  className="mb-3"
                  clickHandler={addCard}
                  secondary
                  wide
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Update Card
                </ButtonNew>
              ) : null}
              {typeof isCanceled !== "undefined" ? (
                !isCanceled ? (
                  <ButtonNew
                    className="mb-4"
                    secondary
                    wide
                    clickHandler={cancelSubscription}
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    Cancel Subscription
                  </ButtonNew>
                ) : active_ && active_.price_id && active_.product_id ? (
                  <ButtonNew
                    primary
                    wide
                    clickHandler={renewSubscriptionHandler}
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    Renew Subscription
                  </ButtonNew>
                ) : null
              ) : null}
              {ends ? (
                <div className="text-center">
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    Total during free trial. No charge until {ends}
                  </Medium14Font>
                </div>
              ) : null}
            </Fragment>
          ) : (
            adminLeftSideInfo
          )}
        </div>
        <div className="col-lg-8">
          <FormHeader
            withOutMargin
            style={{
              border: "1px solid #dcdce6",
              backgroundColor: theme.colors.white,
            }}
            className="d-flex align-items-center"
          >
            <Bold18Font textColor={theme.colors.dark}>
              Payment History
            </Bold18Font>
          </FormHeader>
          <div className="pl-3">
            {history_ && history_.length ? (
              <Table data={history_} settings={settings} />
            ) : (
              <NoRecords>
                <Bold18Font textColor={"#0f122f"}>
                  You don't have any payments.
                </Bold18Font>
              </NoRecords>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PaymentHistory.defaultProps = {
  userObject: null,
};

export default withTheme(PaymentHistory);
