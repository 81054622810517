import React, { Fragment, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { format } from "date-fns";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import "antd/dist/antd.css";
import "../../../assets/css/customSelect.css";
import CustomReactDatepicker from "../../inputs/CustomReactDatepicker";
import CustomDatepickerRangeDivInput from "../../inputs/CustomReactDatepicker/CustomDatepickerRangeDivInput";
import { theme } from "../../../styled-components/Theme/Theme";
import TrialHubFilterDropdown from "../TrialHubFilterDropdown";
import FlagRelevanceFilter from "../../FlagRelevanceFilter/FlagRelevanceFilter";

const dateFormat = "dd MMM yyyy";

const TrialHubFilters = ({
  evidence,
  caseObject,
  themesFilterOptions,
  setThemesFilterOptions,
  onThemesFilterChange,
  themesFilterSelectOption,
  subThemesFilterOptions,
  setSubThemesFilterOptions,
  onSubThemesFilterChange,
  subThemesFilterSelectOption,
  datePickerStartDate,
  setDatePickerStartDate,
  datePickerEndDate,
  setDatePickerEndDate,
  witnessFilterOptions,
  setWitnessFilterOptions,
  onWitnessFilterChange,
  witnessFilterSelectedOption,
  onFlagRelevanceChange,
  flagRelevance,
  ...props
}) => {
  const { path } = useRouteMatch();
  const page = useRouteMatch(`${path}/:page`).params.page;
  // Set witness, themes, sub themes option objects for filters
  useEffect(() => {
    setWitnessFilterOptions([
      {
        value: 0,
        label: "All Witnesses",
      },
    ]);
    if (evidence && evidence.length) {
      const uniqueWitnesses = [];
      evidence.forEach((item) => {
        if (item.witnesses && item.witnesses.length) {
          item.witnesses.forEach((element) => {
            if (uniqueWitnesses.indexOf(element.name) === -1) {
              uniqueWitnesses.push(element.name);
              setWitnessFilterOptions((prevState) => [
                ...prevState,
                {
                  value: element.id,
                  label: element.name,
                },
              ]);
            }
          });
        }
      });
    }
  }, [evidence]);

  const onChangeDatesHandler = (dates) => {
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
  };

  const onClearDatesHandler = () => {
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
  };

  //Evidence filters
  if (page === "evidence" || page === "authorities") {
    return (
      <Fragment>
        <div className="row mb-1">
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <Medium14Font style={{ marginRight: "11px" }}>
              Filter by:
            </Medium14Font>
          </div>
        </div>
        <div className="row mb-3">
          <FlagRelevanceFilter
            size={page === "evidence" ? 2 : 4}
            flagRelevance={flagRelevance}
            caseObject={caseObject}
            onFlagRelevanceChange={onFlagRelevanceChange}
          />
          {page === "evidence" ? (
            <Fragment>
              <div className="col-xl-3 col-lg-3 col-sm-6 mb-1 mb-xl-0">
                <CustomReactDatepicker
                  selected={datePickerStartDate}
                  customInput={
                    <CustomDatepickerRangeDivInput
                      startDate={
                        datePickerStartDate
                          ? format(datePickerStartDate, dateFormat)
                          : ""
                      }
                      endDate={
                        datePickerEndDate
                          ? format(datePickerEndDate, dateFormat)
                          : ""
                      }
                      clearDatesHandler={onClearDatesHandler}
                    />
                  }
                  startDate={datePickerStartDate}
                  endDate={datePickerEndDate}
                  onChange={onChangeDatesHandler}
                  dateFormat={"dd/MM/yyyy"}
                  dateFormatCalendar={"dd MMMM yyyy"}
                  selectsRange
                  shouldCloseOnSelect={false}
                  disabledKeyboardNavigation
                />
              </div>
              <div
                className={
                  !evidence
                    ? "col-xl-2 col-lg-3 col-sm-6 d-flex justify-content-center position-relative"
                    : "col-xl-3 col-lg-3 col-sm-6"
                }
              >
                <TrialHubFilterDropdown
                  controlWidth={"100%"}
                  defaultValue={witnessFilterSelectedOption}
                  options={witnessFilterOptions}
                  placeholder={"Witnesses"}
                  onChange={onWitnessFilterChange}
                />
              </div>
            </Fragment>
          ) : null}
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="row mb-1">
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "15px" }}
          >
            <Medium14Font style={{ marginRight: "11px" }}>
              Filter by:
            </Medium14Font>
          </div>
        </div>
        <div className="row" {...props}>
          {/*<div className="col d-flex justify-content-center">*/}
          <FlagRelevanceFilter
            size={4}
            flagRelevance={flagRelevance}
            caseObject={caseObject}
            onFlagRelevanceChange={onFlagRelevanceChange}
          />
          {/*</div>*/}
        </div>
      </Fragment>
    );
  }
  return null;
};

TrialHubFilters.propTypes = {};

export default TrialHubFilters;
