import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWitnessById, getXExamWitnessById } from "../../../Auth/auth";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../../../../components/FontsNewComponent/Fonts";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import PulseLoader from "react-spinners/PulseLoader";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import moment from "moment";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import CasesMenu from "../../../../components/baseAppComponents/CasesMenu";
import TrialHubSearchSortBar from "../../../../components/baseAppComponents/TrialHubSearchSortBar/TrialHubSearchSortBar";
import CustomReactDatepicker from "../../../../components/inputs/CustomReactDatepicker";
import CustomDatepickerRangeDivInput from "../../../../components/inputs/CustomReactDatepicker/CustomDatepickerRangeDivInput";
import { format, parseISO } from "date-fns";
import Table from "../../../../components/Table";
import { SHOW_RIGHT_SIDE_BAR } from "../../../../redux/types";
import LinkEvidenceForm from "../../../../components/forms/WitnessForms/LinkEvidenceForm";
import NoRecords from "../../../../components/NoRecords";
import { route } from "../../../../routes";
import WitnessBasicInfo from "./WitnessBasicInfo";
import { setTagField } from "../../../../utils/function";
import FlagRelevanceFilter from "../../../../components/FlagRelevanceFilter/FlagRelevanceFilter";
import flagRelevanceCheck from "../../../../components/FlagRelevanceFilter/flagRelevanceCheck";
import KeySections from "../../../../components/KeySections";

const dateFormat = "dd MMM yyyy";

const dateFormatToCompare = "YYYYMMDD";

const dateToInt = (date) => parseInt(moment(date).format(dateFormatToCompare));

const WitnessDetails = ({ theme }) => {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [witnessObject, setWitnessObject] = useState({});

  // search bar & filters states
  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectedSortFunction, setSelectedSortFunction] = useState(1);
  // DatePicker
  const [datePickerStartDate, setDatePickerStartDate] = useState(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState(null);
  const [evidence, setEvidence] = useState([]);
  const [themesFilterOptions, setThemesFilterOptions] = useState([
    {
      value: 0,
      label: "All Themes",
    },
  ]);
  const [themesFilterSelectedOption, setThemesFilterSelectOption] = useState(
    themesFilterOptions[0]
  );
  const [subThemesFilterOptions, setSubThemesFilterOptions] = useState([
    {
      value: 0,
      label: "All Subtheme",
    },
  ]);
  const [
    subThemesFilterSelectedOption,
    setSubThemesFilterSelectOption,
  ] = useState(subThemesFilterOptions[0]);

  const { id, witnessId, xExamId } = useParams();
  const isXExamWitnessDetailsPage =
    useRouteMatch(`${route.baseApp.trialHub.xExamDetails}`) ||
    useRouteMatch(`${route.admin.baseApp.trialHub.xExamDetails}`);
  const history = useHistory();
  const dispatch = useDispatch();

  // check: !(isXExamWitnessDetailsPage && isXExamWitnessDetailsPage.isExact) ? OLD_VALUE : NEW_VALUE
  const checkIsXExamFlowIsFalse = () => {
    return true;
  };

  const [flagRelevance, setFlagRelevance] = useState([]);

  const onFlagRelevanceChange = (options) => {
    setFlagRelevance(options);
  };

  const tabsPanelItems = [
    {
      id: 1,
      text: checkIsXExamFlowIsFalse()
        ? "Witness Evidence"
        : "Topics &  Objectives",
      activeType: 0,
    },
    {
      id: 2,
      text: `Key Sections (${witnessObject && witnessObject.sub_evidence ? witnessObject.sub_evidence.length : null})`,
      activeType: 1,
    },
    {
      id: 3,
      text: "Relevance",
      activeType: 2,
    },
  ];

  // get data from api
  useEffect(() => {
    const getData = async () => {
      const resp =  await getWitnessById(id, witnessId, dispatch);
      if (resp) {
        // If X-Exam flow - set unique tags from all topics linked to this witness
        if (checkIsXExamFlowIsFalse()) {
          setWitnessObject(resp);
        } else {
          // If X-Exam flow - set unique tags from all topics linked to this witness
          const uniqueParentElements = [];

          const mapObject = {
            causes_of_action: [],
            causes_of_action_defensive: [],
            issues_parent: [],
            issues: [],
            themes_parent: [],
            themes: [],
          };

          resp.topics.forEach((object) => {
            object.causes_of_action_parent.forEach((el) => {
              if (
                uniqueParentElements.findIndex(
                  (item) => item.id === el.type.id
                ) === -1
              ) {
                uniqueParentElements.push(el.type);
              }
            });

            Object.keys(mapObject).forEach((key) => {
              object[key].forEach((el) => {
                if (
                  mapObject[key].findIndex((item) => item.id === el.id) === -1
                ) {
                  mapObject[key].push(el);
                }
              });
            });
          });
          setWitnessObject({
            ...resp,
            causes_of_action_parent: [...uniqueParentElements],
            ...mapObject,
          });
        }
        setIsLoading(false);
      }
    };
    if (Object.keys(witnessObject).length === 0) {
      getData();
    }
  }, [witnessObject]);

  // Set witness, themes, sub themes option objects for filters
  useEffect(() => {
    if (
      witnessObject[checkIsXExamFlowIsFalse() ? "evidences" : "topics"] &&
      witnessObject[checkIsXExamFlowIsFalse() ? "evidences" : "topics"].length
    ) {
      const uniqueThemes = [];
      const uniqueSubThemes = [];
      const withTags = [];
      const witnessObjectWithTaggedEvidences = { ...witnessObject };

      setTagField(
        witnessObject.case,
        witnessObject[checkIsXExamFlowIsFalse() ? "evidences" : "topics"]
      ).forEach((item, index, array) => {
        if (item.themes && item.themes.length) {
          item.themes.forEach((element) => {
            if (uniqueSubThemes.indexOf(element.name) === -1) {
              uniqueSubThemes.push(element.name);
              setSubThemesFilterOptions((prevState) => [
                ...prevState,
                {
                  value: element.id,
                  label: element.name,
                },
              ]);
            }
            if (
              element.theme &&
              uniqueThemes.indexOf(element.theme.name) === -1
            ) {
              uniqueThemes.push(element.theme.name);
              setThemesFilterOptions((prevState) => [
                ...prevState,
                {
                  value: element.theme.id,
                  label: element.theme.name,
                },
              ]);
            }
          });
        }
      });
    }
  }, [witnessObject]);

  const setEvidenceToWitness = (object) => {
    const newEvidencesList = [...object.evidences];

    setWitnessObject((prevState) => ({
      ...prevState,
      evidences: [...newEvidencesList],
    }));
  };

  const showRightSideBarWithContent = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <LinkEvidenceForm
          key={witnessObject.id}
          witnessObject={witnessObject}
          callback={setEvidenceToWitness}
          selectedMenuTab={index}
          caseId={id}
        />
      ),
      title: "Retrieve Evidence",
    });
  };

  const onClearDatesHandler = () => {
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
  };

  const onChangeDatesHandler = (dates) => {
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
  };

  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const clearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  const onThemesFilterChangeHandler = (selectedOption) => {
    setThemesFilterSelectOption(selectedOption);
  };

  const onSubThemesFilterChangeHandler = (selectedOption) => {
    setSubThemesFilterSelectOption(selectedOption);
  };

  const onFilterDatesAlphabetChangeHandler = (value) => {
    setSelectedSortFunction(value.value);
  };

  const editWitnessHandler = () => {
    if (checkIsXExamFlowIsFalse()) {
      history.push(
        !is_admin
          ? `/app/cases/${id}/trial-hub/witness/edit/${witnessObject.id}`
          : `/admin/all-cases/${id}/trial-hub/witness/edit/${witnessObject.id}`
      );
    } else {
      history.push(
        !is_admin
          ? `/app/cases/${id}/trial-hub/x-exam/edit/${xExamId}`
          : `/admin/all-cases/${id}/trial-hub/x-exam/edit/${xExamId}`
      );
    }
  };

  const getType = (type) => {
    setActiveTab(type);
  };

  const searchField = (fieldValue) => {
    if (typeof fieldValue === "string") {
      return (
        fieldValue.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1
      );
    } else if (typeof fieldValue === "object") {
      return (
        fieldValue.filter((string) => {
          if (string && string.name !== null) {
            return (
              string.name
                .toLowerCase()
                .indexOf(searchBarValue.toLowerCase()) !== -1
            );
          }
          return false;
        }).length > 0
      );
    }
  };

  const searchThemeField = (fieldValue) => {
    return (
      fieldValue.filter((item) => {
        return item.id === themesFilterSelectedOption.value;
      }).length > 0
    );
  };

  // Filter by sub-theme id
  const searchSubThemeField = (fieldValue) => {
    return fieldValue.name === subThemesFilterSelectedOption.label;
  };

  // TODO: Check ID!
  const removeCurrentWitnessFromListFilter = (value) => {
    return !(value.fake_id === witnessObject.id);
  };

  const filterEvidences = (value) => {
    let check = true;

    if (checkIsXExamFlowIsFalse()) {
      if (searchBarValue && check) {
        check =
          (value.label && searchField(value.label)) ||
          (value.objectives &&
            value.objectives.length &&
            searchField(value.objectives));
        // TODO: Add search by TAGS!
      }
    } else {
      if (searchBarValue && check) {
        check =
          (value.name && searchField(value.name)) ||
          (value.objectives &&
            value.objectives.length &&
            searchField(value.objectives));
        // TODO: Add search by TAGS!
      }
    }

    // Filter by date
    if (check) {
      const checkedDate = dateToInt(value.date);
      let dateBefore;
      let dateAfter;
      if (datePickerStartDate && datePickerEndDate) {
        dateBefore = dateToInt(datePickerStartDate);
        dateAfter = dateToInt(datePickerEndDate);
        check = dateBefore <= checkedDate && checkedDate <= dateAfter;
      } else if (datePickerStartDate) {
        dateBefore = dateToInt(datePickerStartDate);
        check = dateBefore <= checkedDate;
      } else if (datePickerEndDate) {
        dateAfter = dateToInt(datePickerEndDate);
        check = checkedDate <= dateAfter;
      }
    }

    // // Filter by theme name
    // if (themesFilterSelectedOption.value !== 0 && check) {
    //   check =
    //     value.themes && value.themes.length && searchThemeField(value.themes);
    // }

    // const list = [];

    // if (subThemesFilterSelectedOption.value !== 0 && check) {
    //   if (value.themes.length) {
    //     value.themes.forEach((sub_theme) => {
    //       const subThemeCheck = searchSubThemeField(sub_theme);
    //       if (list.indexOf(subThemeCheck) === -1) {
    //         list.push(subThemeCheck);
    //       }
    //     });
    //     check = list.indexOf(true) !== -1;
    //   }
    // }

    if (check && flagRelevance.length) {
      check = flagRelevanceCheck(value, flagRelevance);
    }

    return check;
  };

  const evidenceTableSettings = {
    collapsed: false,
    canSort: true,
    clickAction: (object) => {
      if (checkIsXExamFlowIsFalse()) {
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/evidence/${object.fake_id}`
            : `/admin/all-cases/${id}/trial-hub/evidence/${object.fake_id}`
        );
      }
    },
    actions: [],
    fields: [
      {
        name: "id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
      },
      {
        canSort: true,
        name: checkIsXExamFlowIsFalse() ? "label" : "name",
        label: checkIsXExamFlowIsFalse() ? "EVIDENCE" : "TOPIC",
        renderer: (object) => {
          return (
            <Medium14Font>
              {checkIsXExamFlowIsFalse() ? object.label : object.name}
            </Medium14Font>
          );
        },
      },
      {
        name: checkIsXExamFlowIsFalse() ? "date" : null,
        canSort: true,
        label: checkIsXExamFlowIsFalse() ? "DATE" : null,
        renderer: (object) => {
          return checkIsXExamFlowIsFalse() ? (
            <Medium14Font>
              {object.date ? format(parseISO(object.date), "dd MMM yyyy") : ""}
            </Medium14Font>
          ) : null;
        },
      },
      {
        name: "objectives",
        label: "OBJECTIVES & X-EXAM LINES",
        renderer: (object) => {
          if (object.objectives && object.objectives.length) {
            if (
              witnessObject.exam_lines &&
              witnessObject.exam_lines.evidences &&
              witnessObject.exam_lines.evidences.indexOf(object.id) === -1
            ) {
              return "";
            }
            return (
              <ul>
                {object.objectives.map((objective, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>
                      {objective.name}
                      {objective.exam_lines && objective.exam_lines.length
                        ? objective.exam_lines.map((examLine, idx) => (
                            <LiStyled key={idx} style={{ marginLeft: "20px" }}>
                              <Medium14Font>{examLine.name}</Medium14Font>
                            </LiStyled>
                          ))
                        : null}
                    </Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "witnesses",
        label: "OTHER WITNESSES",
        renderer: (object) => {
          if (object.witnesses && object.witnesses.length) {
            return (
              <ul>
                {object.witnesses
                  .filter(removeCurrentWitnessFromListFilter)
                  .map((witness, index) => (
                    <LiStyled key={index}>
                      <Medium14Font>{witness.name}</Medium14Font>
                    </LiStyled>
                  ))}
              </ul>
            );
          }
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ],
  };
  const topicsTableSettings = {
    collapsed: false,
    [checkIsXExamFlowIsFalse() ? "clickAction" : null]: (object) => {
      if (checkIsXExamFlowIsFalse()) {
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/evidence/${object.fake_id}`
            : `/admin/all-cases/${id}/trial-hub/evidence/${object.fake_id}`
        );
      }
    },
    actions: [],
    fields: [
      {
        name: "id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
        width: "70px",
      },
      {
        name: "name",
        canSort: true,
        label: "TOPIC",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
        width: "200px",
      },
      {
        name: "objectives",
        label: "OBJECTIVES & X-EXAM LINES",
        renderer: (object) => {
          if (object.objectives && object.objectives.length) {
            if (
              witnessObject.exam_lines &&
              witnessObject.exam_lines.evidences &&
              witnessObject.exam_lines.evidences.indexOf(object.id) === -1
            ) {
              return "";
            }
            return (
              <ul>
                {object.objectives.map((objective, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>
                      {objective.name}
                      {objective.exam_lines && objective.exam_lines.length
                        ? objective.exam_lines.map((examLine, idx) => (
                            <LiStyled key={idx} style={{ marginLeft: "20px" }}>
                              <Medium14Font>{examLine.name}</Medium14Font>
                            </LiStyled>
                          ))
                        : null}
                    </Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "evidences",
        label: "EVIDENCE",
        renderer: (object) => {
          if (object.evidences && object.evidences.length) {
            return (
              <ul>
                {object.evidences.map((evidence, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{evidence.label}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ],
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: witnessObject.case.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: checkIsXExamFlowIsFalse()
        ? !is_admin
          ? `/app/cases/${id}/trial-hub/witness`
          : `/admin/all-cases/${id}/trial-hub/witness`
        : !is_admin
        ? `/app/cases/${id}/trial-hub/x-exam`
        : `/admin/all-cases/${id}/trial-hub/x-exam`,
      title: checkIsXExamFlowIsFalse()
        ? `Trial Hub - Witness`
        : "Trial Hub - X-Exam",
      activeMenuItem: CASES,
    },
    {
      path: checkIsXExamFlowIsFalse()
        ? !is_admin
          ? `/app/cases/${id}/trial-hub/witness/${witnessId}`
          : `/admin/cases/${id}/trial-hub/witness/${witnessId}`
        : !is_admin
        ? `/app/cases/${id}/trial-hub/x-exam/${xExamId}`
        : `/admin/all-cases/${id}/trial-hub/x-exam/${xExamId}`,
      title: witnessObject.name ?? "New Witness",
      activeMenuItem: CASES,
    },
  ];

  const noRecordsFragment = (
    <NoRecords>
      <Bold18Font textColor={"#0f122f"}>
        Your Linked Evidence will appear here
      </Bold18Font>
    </NoRecords>
  );

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-6">
          <Bold30Font>{witnessObject.name ?? "New Witness"}</Bold30Font>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center mb-3">
          <div
            className={`w-100 d-flex flex-wrap ${
              checkIsXExamFlowIsFalse()
                ? "justify-content-between"
                : "justify-content-end"
            }`}
            style={{ maxWidth: "240px" }}
          >
            {checkIsXExamFlowIsFalse() ? (
              <ButtonNew
                clickHandler={() => showRightSideBarWithContent(null, 1)}
                loading={isLoading}
                disabled={isLoading}
                // style={{ marginRight: "20px" }}
                secondary
                // delete_
                style={{ marginRight: "10px", marginBottom: "4px" }}
              >
                Note Testimony
              </ButtonNew>
            ) : null}
            <ButtonNew
              clickHandler={editWitnessHandler}
              loading={isLoading}
              disabled={isLoading}
              // style={{ marginRight: "20px" }}
              primary
              // delete_
              style={{ marginBottom: "4px" }}
            >
              Edit
            </ButtonNew>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <CasesMenu
            casesMenu={tabsPanelItems}
            noBadges
            noButton
            active={activeTab}
            activeFont={Bold18Font}
            font={Medium18Font}
            getType={getType}
          />
        </div>
      </div>

      {/* Basic Info */}
      {activeTab === 2 ? (
        <WitnessBasicInfo
          checkIsXExamFlowIsFalse={checkIsXExamFlowIsFalse()}
          witnessObject={witnessObject}
        />
      ) : null}

      {/* Witness Evidence OR Topics & Objectives */}
      {activeTab === 0 ? (
        <Fragment>
          <div className="row">
            <div className="col">
              <TrialHubSearchSortBar
                searchBarValue={searchBarValue}
                onSearchBarChangeHandler={onSearchBarChangeHandler}
                clearSearchBarHandler={clearSearchBarHandler}
                searchBarInputName="search"
                searchBarPlaceholder="Search"
                onFilterDatesAlphabetChangeHandler={
                  onFilterDatesAlphabetChangeHandler
                }
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col">
              <Medium14Font style={{ marginRight: "11px" }}>
                Filter by:
              </Medium14Font>
            </div>
          </div>
          <div
            className="row align-items-center"
            style={{ maxWidth: "900px", marginBottom: "60px" }}
          >
            <FlagRelevanceFilter
              size={isXExamWitnessDetailsPage ? 4 : 3}
              caseObject={witnessObject.case}
              flagRelevance={flagRelevance}
              onFlagRelevanceChange={onFlagRelevanceChange}
            />

            {/* Date */}
            {!isXExamWitnessDetailsPage ? (
              <div className="col d-flex justify-content-center">
                <div style={{ maxWidth: "230px", width: "100%" }}>
                  <CustomReactDatepicker
                    // selected={datePickerStartDate}
                    customInput={
                      <CustomDatepickerRangeDivInput
                        startDate={
                          datePickerStartDate
                            ? format(datePickerStartDate, dateFormat)
                            : ""
                        }
                        endDate={
                          datePickerEndDate
                            ? format(datePickerEndDate, dateFormat)
                            : ""
                        }
                        clearDatesHandler={onClearDatesHandler}
                      />
                    }
                    startDate={datePickerStartDate}
                    endDate={datePickerEndDate}
                    onChange={onChangeDatesHandler}
                    dateFormat={"dd/MM/yyyy"}
                    dateFormatCalendar={"dd MMMM yyyy"}
                    selectsRange
                    shouldCloseOnSelect={false}
                    disabledKeyboardNavigation
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col">
              {checkIsXExamFlowIsFalse() ? (
                <Fragment>
                  {witnessObject.evidences && witnessObject.evidences.length ? (
                    <Table
                      data={setTagField(
                        witnessObject.case,
                        witnessObject.evidences.filter(filterEvidences)
                      )}
                      settings={evidenceTableSettings}
                    />
                  ) : (
                    noRecordsFragment
                  )}
                </Fragment>
              ) : (
                <div className="col">
                  {witnessObject.topics && witnessObject.topics.length ? (
                    <Table
                      data={setTagField(
                        witnessObject.case,
                        witnessObject.topics.filter(filterEvidences)
                      )}
                      settings={topicsTableSettings}
                    />
                  ) : (
                    noRecordsFragment
                  )}
                </div>
              )}
            </div>
          </div>
        </Fragment>
      ) : null}
      {activeTab === 1 ? (
        <KeySections
          isWitness
          evidenceObject={witnessObject}
          caseObject={witnessObject.case}
        />
      ) : null}
    </Fragment>
  );
};

export default withTheme(WitnessDetails);
