import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import RsbCaseCardPreviewDotStyled from "./styled/RSBCaseCardPreviewDotStyled";
import {
  Bold14Font,
  Bold24Font,
  Medium14Font,
} from "../FontsNewComponent/Fonts";
import { route } from "../../routes";
import { useHistory } from "react-router-dom";
import {acceptInvite, rejectInvite, teamLimit} from "../../containers/Auth/auth";
import {
  GET,
  HIDE_RIGHT_SIDE_BAR,
  MODAL_CONFIRM_ELEMENT,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../styled-components/Theme/Theme";
import { withTheme } from "styled-components/macro";
import personIcon from "../../assets/img3/svg/person.svg";
import pencilIcon from "../../assets/img3/svg/pancil_icon.svg";
import moment from "moment";
import {
  TagElementCyan,
  TagElementPurple,
} from "../TagElementStyled/TagElementStyled";
import ButtonNew from "../buttons/Button/ButtonNew";
import InputNew from "../InputNew/InputNew";

const teamSizeInputRange = {
  MIN: 1,
  MAX: 100,
};

const RSBCaseCardAdminTeamRequestPreview = ({
  caseObject,
  moveFromTeamLimit,
  afterCloseCallback,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(true);
  const [archived, setArchived] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [teamSizeNumber, setTeamSizeNumber] = useState(caseObject.max_users);
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  useEffect(() => {
    if (afterCloseCallback) {
      return () => afterCloseCallback();
    }
  }, []);

  const onClickAcceptInviteHandler = async () => {
    setButtonIsLoading(true);
    const submitObject = {
      type: 'accept',
      limit: teamSizeNumber,
    };
    const resp = await teamLimit(caseObject.id, submitObject, dispatch);
    if (resp) {
      moveFromTeamLimit(caseObject.id, 'accept');
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
    setButtonIsLoading(false);
  };

  const onClickRejectInviteHandler = async () => {
    setButtonIsLoading(true);
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "Are you sure you want to reject team size increase?",
      beforeCloseHandler: async () => {
        const submitObject = {
          type: 'reject',
        };
        const resp = await teamLimit(caseObject.id, submitObject, dispatch);
        if (resp) {
          moveFromTeamLimit(caseObject.id, 'reject');
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        }
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_CONFIRM_ELEMENT });

    setButtonIsLoading(false);
  };

  const onChangeTeamSizeInputHandler = (event) => {
    if (
      (event.target.value >= teamSizeInputRange.MIN &&
        event.target.value <= teamSizeInputRange.MAX) ||
      event.target.value === ""
    ) {
      setTeamSizeNumber(event.target.value);
    } else if (event.target.value < teamSizeInputRange.MIN) {
      setTeamSizeNumber(teamSizeInputRange.MIN);
    } else if (event.target.value > teamSizeInputRange.MAX) {
      setTeamSizeNumber(teamSizeInputRange.MAX);
    }
  };

  const onClickAddHandler = (number) => {
    const event = {
      target: {
        value: parseInt(teamSizeNumber) + parseInt(number),
      },
    };
    onChangeTeamSizeInputHandler(event);
  };

  return (
    <Fragment>
      {/* Case Type and Firm */}
      <div className="row px-3 mb-3">
        <div className="col d-flex justify-content-between">
          <RsbCaseCardPreviewDotStyled isArchive={archived} />
          <TagElementPurple overflow style={{ marginRight: "10px" }}>
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject.case_type ? caseObject.case_type.name : "no type"}
            </Bold14Font>
          </TagElementPurple>
          <TagElementCyan overflow>
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject.firm_name || "no firm"}
            </Bold14Font>
          </TagElementCyan>
        </div>
      </div>
      {/* Case label */}
      <div className="row px-3 mb-4">
        <div className="col">
          <Bold24Font as="p">{caseObject.label}</Bold24Font>
        </div>
      </div>
      <div
        className="px-3"
        style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}
      >
        <div className="form-group">
          <InputNew
            type="number"
            name="team_size"
            value={teamSizeNumber}
            onChangeHandler={onChangeTeamSizeInputHandler}
            required
            label="Team Size Limit"
            containerStyle={{ padding: "0" }}
            min={teamSizeInputRange.MIN}
            max={teamSizeInputRange.MAX}
          />
        </div>
        <div className="form-group">
          <div className="d-flex justify-content-between">
            <ButtonNew primary clickHandler={() => onClickAddHandler(5)}>
              Add +5
            </ButtonNew>
            <ButtonNew primary clickHandler={() => onClickAddHandler(10)}>
              Add +10
            </ButtonNew>
          </div>
        </div>
      </div>
      {/* Buttons */}
      <div
        className="container-fluid pt-3 px-3"
        style={{ borderTop: `1px solid ${theme.colors.gray}` }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-between mb-4">
            <div className="d-flex" title="team size">
              <img src={personIcon} alt="" className="img-fluid mr-2" />
              <Medium14Font>{caseObject.max_users}</Medium14Font>
            </div>
            <div className="d-flex" title="last updated">
              <img src={pencilIcon} alt="" className="img-fluid mr-2" />
              <Medium14Font>
                {moment(caseObject.updated_at).format("DD/MM/YY")}
              </Medium14Font>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <ButtonNew
              primary
              wide
              clickHandler={onClickAcceptInviteHandler}
              loading={buttonIsLoading}
              disabled={buttonIsLoading}
            >
              Accept
            </ButtonNew>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <ButtonNew
              danger
              wide
              clickHandler={onClickRejectInviteHandler}
              loading={buttonIsLoading}
              disabled={buttonIsLoading}
            >
              Reject
            </ButtonNew>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RSBCaseCardAdminTeamRequestPreview.propTypes = {
  caseObject: PropTypes.object.isRequired,
};

export default withTheme(RSBCaseCardAdminTeamRequestPreview);
