import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import { format, parseISO } from "date-fns";

import {
  deleteEvidence,
  getAuthoritiesList,
  getEvidenceList,
  updateAuthorityById,
  updateEvidence,
} from "../../../Auth/auth";

import { PulseLoader } from "react-spinners";

import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import Table from "../../../../components/Table";
import LeftSideBarUserMenu from "../../../../components/baseAppComponents/LeftSideBarUserLayout/LeftSideBarUserMenu";
import LiStyled from "../../../../components/Table/Styled/LiStyled";

import {
  Bold18Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";

import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import AssociateWitnessForm from "../../../../components/forms/EvidenceForms/AssociateWitnessForm";
import AssociateElementsThemesIssuesForm from "../../../../components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import NoRecords from "../../../../components/NoRecords";
import EvidenceObjectivesForm from "../../../../components/forms/DocumentForms/EvidenceObjectivesForm";
import { setTagField } from "../../../../utils/function";
import EvidencePreviewDetail from "../../../../components/forms/EvidenceForms/EvidencePreviewDetail";

const Evidence = ({
  caseObject,
  evidence,
  setEvidence,
  setIsTrialHubLoading,
  isAuthoritiesFlowFalse,
  setActiveItem,
  theme,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  // Show Associate Witness form in right side bar
  const handleAssociateWitness = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateWitnessForm
          key={object.id}
          evidenceObject={object}
          setEvidenceObject={setEvidenceObject}
          caseId={id}
          caseObject={caseObject}
          // selectedMenuTab={index}
          // afterSubmit={associateWitnessChange}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Associate Witness",
    });
  };

  // Show EvidenceObjectivesForm in right side bar
  const handleEvidenceObjectives = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <EvidenceObjectivesForm
          key={object.id}
          evidenceObject={object}
          setEvidenceObject={setEvidenceObject}
          saveToAPIMethod
        />
      ),
      title: object.name ? "Edit: " + object.name : "Objectives",
    });
  };
  // Make changes to evidence in list
  const setEvidenceObject = (object) => {
    const evidenceCopy = [...evidence];
    const evidenceIndex = evidenceCopy.findIndex((el) => el.id === object.id);
    evidenceCopy.splice(evidenceIndex, 1, object);
    setEvidence([...evidenceCopy]);
  };

  const sendFlagRelevanceData = async (submitObject, objectId) => {
    if (Object.keys(submitObject).length) {
      const resp = isAuthoritiesFlowFalse
        ? await updateEvidence(objectId, id, dispatch, submitObject)
        : await updateAuthorityById(objectId, id, dispatch, submitObject);
      if (resp) {
        setEvidenceObject(resp);
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        // setIsLoading(false);
      }
    }
  };

  // Show Flag Relevance form in right side bar
  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateElementsThemesIssuesForm
          key={object.id}
          caseId={id}
          evidenceObject={object}
          setEvidenceObject={setEvidenceObject}
          sendData={(submitObject) =>
            sendFlagRelevanceData(submitObject, object.id)
          }
          // selectedMenuTab={index}
          // afterSubmit={associateWitnessChange}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  const createEvidenceObjectWithCase = (id) => {
    const evidenceObject = evidence.find((el) => el.id === id);
    return {
      ...evidenceObject,
      // case: caseObject,
    };
  };
  const handleEvidenceDelete = (ev) => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteEvidence(ev.id, id, dispatch);
        const evidenceCopy = [...evidence];
        const evidenceIndex = evidenceCopy.findIndex(
          (el) => el.id === evidence.id
        );
        evidenceCopy.splice(evidenceIndex, 1);
        setEvidence([...evidenceCopy]);
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };
  const popupMenuEvidence = [
    {
      id: 1,
      title: "Add Witness",
      onClickHandler: (_, id) => {
        const evidenceWithCase = createEvidenceObjectWithCase(id);
        handleAssociateWitness(evidenceWithCase);
      },
    },
    {
      id: 2,
      title: "Add Objective",
      onClickHandler: (_, id) => {
        const evidenceWithCase = createEvidenceObjectWithCase(id);
        handleEvidenceObjectives(evidenceWithCase);
      },
    },
    {
      id: 3,
      title: "Flag Relevance",
      onClickHandler: (_, id) => {
        handleAssociateElementsThemesIssues(createEvidenceObjectWithCase(id));
      },
    },
    {
      id: 4,
      title: "Edit",
      onClickHandler: (history, evidenceId) =>
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/evidence/edit/${evidenceId}`
            : `/admin/all-cases/${id}/trial-hub/evidence/edit/${evidenceId}`
        ),
    },
    {
      id: 5,
      title: "Delete",
      onClickHandler: (history, evidenceId) => {
        dispatch({
          type: SAVE_MODAL_DATA,
          payload: "You won't be able to restore data",
          beforeCloseHandler: async () => {
            await deleteEvidence(evidenceId, id, dispatch);
            const evidenceCopy = [...evidence];
            const evidenceIndex = evidenceCopy.findIndex(
              (el) => el.id === evidenceId
            );
            evidenceCopy.splice(evidenceIndex, 1);
            setEvidence([...evidenceCopy]);
          },
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
      },
    },
  ];
  const popupMenuAuthorities = [
    {
      id: 1,
      title: "Flag Relevance",
      onClickHandler: (_, id) => {
        handleAssociateElementsThemesIssues(createEvidenceObjectWithCase(id));
      },
    },
    {
      id: 2,
      title: "Edit",
      onClickHandler: (history, evidenceId) =>
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/authorities/edit/${evidenceId}`
            : `/admin/all-cases/${id}/trial-hub/authorities/edit/${evidenceId}`
        ),
    },
  ];

  const evidenceTableSettings = {
    collapsed: true,
    clickAction: (object) => {
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: (
          <EvidencePreviewDetail
            key={object.id}
            caseId={id}
            caseObject={caseObject}
            evidence={object}
            goToDetails={true}
            handleEvidenceDelete={handleEvidenceDelete}
            detailsLink={`${url}/${object.id}`}
          />
        ),
        title: "Evidence Preview",
      });
    },
    actions: [
      {
        type: "options",
        popupComponent: (
          <LeftSideBarUserMenu
            menuItems={popupMenuEvidence}
            style={{ width: "150px", height: "220px" }}
          />
        ),
        callback: (object, event, state, setState, rowIndex) => {
          // show or hide popup menu on appropriate row
          if (state === rowIndex) {
            setState(null);
          } else {
            setState(rowIndex);
          }
        },
      },
    ],
    canSort: true,
    fields: [
      {
        name: "id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.id}</Medium14Font>;
        },
      },
      {
        name: "label",
        label: "LABEL",
        sortCondition: 'string',
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "sub_evidence_count",
        label: "KEY SECTIONS",
        canSort: true,
        renderer: (object) => {
          if (object.sub_evidence && object.sub_evidence.length) {
            return <Medium14Font onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: `${url}/${object.id}`,
                state: { subevidence: true },
              });
            }} underline textColor={theme.colors.blue}>{object.sub_evidence.length}</Medium14Font>
          }
          return ''
        },
      },
      {
        name: "witnesses",
        label: "WITNESSES",
        renderer: (object) => {
          if (object.witnesses && object.witnesses.length) {
            return (
              <ul>
                {object.witnesses.map((witness, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{witness.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "date",
        label: "DATE",
        canSort: true,
        sortCondition: 'date',
        renderer: (object) => {
          return (
            <Medium14Font as="div" style={{ whiteSpace: "nowrap" }}>
              {object.date ? format(parseISO(object.date), "dd MMM yyyy") : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "objectives",
        label: "OBJECTIVES",
        renderer: (object) => {
          if (object.objectives && object.objectives.length) {
            return (
              <ul>
                {object.objectives.map((objective, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{objective.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "ref",
        label: "REF",
        renderer: (object) => {
          return <Medium14Font>{object.reference}</Medium14Font>;
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ],
  };

  const authoritiesTableSettings = {
    collapsed: true,
    clickAction: (object) => {
      history.push(`${url}/${object.id}`);
    },
    actions: [
      {
        type: "options",
        popupComponent: (
          <LeftSideBarUserMenu
            menuItems={popupMenuAuthorities}
            style={{ width: "150px", height: "90px" }}
          />
        ),
        callback: (object, event, state, setState, rowIndex) => {
          // show or hide popup menu on appropriate row
          if (state === rowIndex) {
            setState(null);
          } else {
            setState(rowIndex);
          }
        },
      },
    ],
    canSort: true,
    fields: [
      {
        name: "id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.id}</Medium14Font>;
        },
      },
      {
        name: "title",
        label: "TITLE",
        sortCondition: 'string',
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.title}</Medium14Font>;
        },
      },
      {
        name: "type",
        label: "TYPE",
        sortCondition: 'string',
        canSort: true,
        renderer: (object) => {
          return (
            <div className="d-flex flex-column">
              <Medium14Font>{object.type}</Medium14Font>
            </div>
          );
        },
      },
      {
        name: "jurisdiction",
        label: "JURISDICTION",
        renderer: (object) => {
          return (
            <div className="d-flex flex-column">
              <Medium14Font>{object.jurisdiction}</Medium14Font>
            </div>
          );
        },
      },
      {
        name: "citation",
        label: "CITATION",
        sortCondition: 'string',
        canSort: true,
        renderer: (object) => {
          return (
            <div className="d-flex flex-column">
              <Medium14Font>{object.citation}</Medium14Font>
            </div>
          );
        },
      },
      {
        name: "key_sections",
        label: "SECTIONS",
        sortCondition: 'string',
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.key_sections}</Medium14Font>;
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ],
  };

  useEffect(() => {
    const getData = async () => {
      const evidenceResp = isAuthoritiesFlowFalse
        ? await getEvidenceList(id, dispatch)
        : await getAuthoritiesList(id, dispatch);
      if (evidenceResp) {
        setEvidence(evidenceResp);
        setIsLoading(false);
        setIsTrialHubLoading(false);
      }
    };
    if (setActiveItem) {
      setActiveItem(isAuthoritiesFlowFalse ? 0 : 3);
    }

    getData();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: isAuthoritiesFlowFalse
        ? `/app/cases/${id}/trial-hub/evidence`
        : `/app/cases/${id}/trial-hub/authorities`,
      title: isAuthoritiesFlowFalse
        ? `Trial Hub - Evidence`
        : "Trial Hub - Authorities",
      activeMenuItem: CASES,
    },
  ];
  return (
    <div className="container-fluid">
      <PageConfiguration configArray={pageConfig} />
      {evidence.length ? (
        <Table
          data={setTagField(caseObject, evidence)}
          settings={
            isAuthoritiesFlowFalse
              ? evidenceTableSettings
              : authoritiesTableSettings
          }
        />
      ) : (
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            Your {isAuthoritiesFlowFalse ? "Evidence" : "Authorities"} will
            appear here
          </Bold18Font>
        </NoRecords>
      )}
    </div>
  );
};

Evidence.propTypes = {
  evidence: PropTypes.arrayOf(PropTypes.object).isRequired,
  setEvidence: PropTypes.func.isRequired,
  isAuthoritiesFlowFalse: PropTypes.bool,
};

Evidence.defaultProps = {
  isAuthoritiesFlowFalse: true,
};

export default withTheme(Evidence);
