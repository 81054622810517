import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import moment from "moment";
import { parseISO } from "date-fns";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import Button from "../../../../components/buttons/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Panel from "../../../../components/Panel";

import { useDispatch, useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import Arrow from "../../../../components/buttons/Button/Arrow";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import InputNew from "../../../../components/InputNew/InputNew";
import Form from "../../../../components/forms/Form";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import RadioButtonGroup from "../../../../components/buttons/RadioButtonGroup";
import SelectAnotherTry from "../../../../components/Select/SelectAnotherTry";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SET_LAST_PAGE,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import AssociateWitnessForm, {
  createWitnessListByRoles,
} from "../../../../components/forms/EvidenceForms/AssociateWitnessForm";
import UploadSingleFileInput from "../../../../components/inputs/UploadSingleFileInput";
import TextArea from "../../../../components/inputs/TextArea";
import {
  createNewEvidence,
  deleteEvidence,
  getEvidenceById,
  updateEvidence,
  uploadEvidenceFile,
} from "../../../Auth/auth";
import CustomReactDatepicker from "../../../../components/inputs/CustomReactDatepicker";
import AssociateElementsThemesIssuesForm from "../../../../components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import AssociationTable, {
  AssociationTableListElement,
} from "../../../../components/AssociationTable";
import plusIcon from "../../../../assets/img3/svg/button-plus-icon.svg";
import deleteObjectiveIcon from "../../../../assets/img3/svg/delete-objective-input-icon.png";
import CloseIcon from "../../../../components/svgIcons/CloseIcon";
import ButtonAnotherTry from "../../../../components/buttons/Button/ButtonAnotherTry";
import AnimatedDiv from "../../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import RelatedEvidenceForm from "../../../../components/forms/DocumentForms/RelatedEvidenceForm";
import { handleFlatRelevanceElements } from "../../../../utils/function";
import { maxFileSize } from "../../../../utils";
import { closeBtnText, formFlowType } from "../../../../utils/constants";

// evidence types constants:
const TESTIMONY = "Testimony";
const COMMUNICATION = "Communication";
const DOCUMENT = "Document";
const IMAGE_MEDIA = "Image/Media";
const AFFIDAVIT = "Affidavit";
const OTHER = "Other";

// evidence status constants:
const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";
const MIXED = "Mixed";

// sub-types options
export const subtypeCommunicationList = [
  {
    label: "Email",
    value: "Email",
  },
  {
    label: "Letter",
    value: "Letter",
  },
  {
    label: "Text Message",
    value: "Text Message",
  },
  {
    label: "Telephone",
    value: "Telephone",
  },
  {
    label: "Conversation",
    value: "Conversation",
  },
  {
    label: "Fax",
    value: "Fax",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const subtypeDocumentList = [
  {
    label: "Report",
    value: "Report",
  },
  {
    label: "Memo/Note",
    value: "Memo/Note",
  },
  {
    label: "Guidance/Standard",
    value: "Guidance/Standard",
  },
  {
    label: "Records",
    value: "Records",
  },
  {
    label: "Contract Docs",
    value: "Contract Docs",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const subtypeImageMediaList = [
  {
    label: "Photo",
    value: "Photo",
  },
  {
    label: "Video",
    value: "Video",
  },
  {
    label: "Map",
    value: "Map",
  },
  {
    label: "Plan/Drawing",
    value: "Plan/Drawing",
  },
  {
    label: "Audio",
    value: "Audio",
  },
];

const evidenceTypesButtonsObjects = [
  {
    title: <Medium14Font>{TESTIMONY}</Medium14Font>,
    type: TESTIMONY,
  },
  {
    title: <Medium14Font>{COMMUNICATION}</Medium14Font>,
    type: COMMUNICATION,
  },
  {
    title: <Medium14Font>{DOCUMENT}</Medium14Font>,
    type: DOCUMENT,
  },
  {
    title: <Medium14Font>{IMAGE_MEDIA}</Medium14Font>,
    type: IMAGE_MEDIA,
  },
  {
    title: <Medium14Font>{AFFIDAVIT}</Medium14Font>,
    type: AFFIDAVIT,
  },
  {
    title: <Medium14Font>{OTHER}</Medium14Font>,
    type: OTHER,
  },
];

const evidenceStatusButtonsObjects = [
  {
    title: <Medium14Font>{SUPPORTING}</Medium14Font>,
    type: SUPPORTING,
  },
  {
    title: <Medium14Font>{OPPOSING}</Medium14Font>,
    type: OPPOSING,
  },
  {
    title: <Medium14Font>{MIXED}</Medium14Font>,
    type: MIXED,
  },
];

const priorityButtonsObjects = [
  {
    title: <Medium14Font>1</Medium14Font>,
    type: 1,
  },
  {
    title: <Medium14Font>2</Medium14Font>,
    type: 2,
  },
  {
    title: <Medium14Font>3</Medium14Font>,
    type: 3,
  },
  {
    title: <Medium14Font>4</Medium14Font>,
    type: 4,
  },
];

const coreBookButtonsObjects = [
  {
    title: <Medium14Font>Yes</Medium14Font>,
    type: 1,
  },
  {
    title: <Medium14Font>No</Medium14Font>,
    type: 0,
  },
];

const AddEditEvidence = ({ setActiveItem, flow, theme }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(flow === formFlowType.EDIT);
  const [blockBlurEvent, setBlockBlurEvent] = useState(false);
  const [evidenceObject, setEvidenceObject] = useState({});
  const [witnessList, setWitnessList] = useState([]);
  const [witnessesTableObject, setWitnessTableObject] = useState({});
  const [uniquePartyRolesList, setUniquePartyRolesList] = useState([]);
  const [emptyObject, setEmptyObject] = useState(false);
  const [label, setLabel] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [evidenceType, setEvidenceType] = useState(null);
  const [subtypeOptions, setSubtypeOptions] = useState(null);
  const [selectedSubtype, setSelectedSubtype] = useState(null);
  const [date, setDate] = useState("");
  const [file, setFile] = useState(new File([""], "Select file"));
  //TODO set setRelatedEvidences updates DB
  const [relatedEvidences, setRelatedEvidences] = useState([]);
  const [elementsParentList, setElementsParentList] = useState([]);
  const [elementsList, setElementsList] = useState([]);
  const [defenceElementsList, setDefenceElementsList] = useState([]);
  const [themesParentList, setThemesParentList] = useState([]);
  const [themesList, setThemesList] = useState([]);
  const [issuesParentList, setIssuesParentList] = useState([]);
  const [issuesList, setIssuesList] = useState([]);
  const [objectivesList, setObjectivesList] = useState([{ name: "" }]);
  const [summary, setSummary] = useState("");
  const [preempt, setPreempt] = useState("");
  const [notes, setNotes] = useState("");
  const [evidenceStatus, setEvidenceStatus] = useState("");
  const [priority, setPriority] = useState(null);
  const [coreBook, setCoreBook] = useState(null);
  const [reference, setReference] = useState("");
  const [submitObject, setSubmitObject] = useState({});
  const [submitObjectOnClose, setSubmitObjectOnClose] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  let { id, evidenceId } = useParams();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const caseObject = useSelector((state) => state.data.store.case);

  const stateFromLocation = useLocation().state;

  const [addEvidenceStage, setAddEvidenceStage] = useState(
    stateFromLocation && stateFromLocation.openTab
      ? stateFromLocation.openTab
      : 0
  );
  const updateSubmitObject = (data) => {
    setSubmitObject({ ...submitObject, ...data });
  };
  const setEvidenceAndSubmitObjects = (object) => {
    setEvidenceObject((prevState) => {
      saveData({ ...prevState, ...object });
      return {
        ...prevState,
        ...object,
      };
    });
    setSubmitObject({ ...submitObject, ...object });
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const saveData = (evidenceResp) => {
    setLabel(evidenceResp.label ?? "");
    setEvidenceType(evidenceResp.type ?? "");
    setSelectedSubtype(evidenceResp.subtype ?? null);
    setDate(evidenceResp.date ? parseISO(evidenceResp.date) : "");
    // TODO: Change this!
    if (evidenceResp.file) {
      setFile(new File([""], evidenceResp.file));
    }
    setWitnessList(evidenceResp.witnesses ?? []);
    setRelatedEvidences(evidenceResp.related_evidence ?? []);
    setElementsParentList(evidenceResp.causes_of_action_parent ?? []);
    setElementsList(evidenceResp.causes_of_action ?? []);
    setDefenceElementsList(evidenceResp.causes_of_action_defensive ?? []);
    setThemesParentList(evidenceResp.themes_parent ?? []);
    setThemesList(evidenceResp.themes ?? []);
    setIssuesParentList(evidenceResp.issues_parent ?? []);
    setIssuesList(evidenceResp.issues ?? []);
    setObjectivesList(
      evidenceResp.objectives && evidenceResp.objectives.length
        ? evidenceResp.objectives
        : [{ name: "" }]
    );
    setSummary(evidenceResp.summary ?? "");
    setPreempt(evidenceResp.preempt ?? "");
    setNotes(evidenceResp.notes ?? "");
    setEvidenceStatus(evidenceResp.status ?? "");
    setPriority(evidenceResp.priority);
    setCoreBook(evidenceResp.core_book);
    setReference(evidenceResp.reference ?? "");
    setFrom(evidenceResp.from ?? "");
    setTo(evidenceResp.to ?? "");
  };

  // TODO: Try combine this and next use effect to one!
  useEffect(() => {
    const getData = async () => {
      const evidenceResp = await getEvidenceById(evidenceId, id, dispatch);
      if (evidenceResp) {
        setEvidenceObject(evidenceResp);
        saveData(evidenceResp);
        setIsLoading(false);
      }
    };
    if (flow === formFlowType.EDIT) {
      getData();
    }
  }, []);

  // useEffect(() => {
  //   if (!isLoading) {
  //     setIsLoading(true);
  //     saveData({ ...evidenceObject });
  //     setIsLoading(false);
  //   }
  // }, [evidenceObject]);

  // Transform witnesses for table view
  useEffect(() => {
    if (witnessList) {
      const witnessesTableObjectCopy = createWitnessListByRoles(witnessList);
      setUniquePartyRolesList(Object.keys(witnessesTableObjectCopy));
      setWitnessTableObject({ ...witnessesTableObjectCopy });
    }
  }, [witnessList, evidenceObject]);

  // set subtype options based on evidence type value
  useEffect(() => {
    if (evidenceType) {
      switch (evidenceType) {
        case TESTIMONY:
        case AFFIDAVIT:
        case OTHER:
          setSubtypeOptions(null);
          break;

        case COMMUNICATION:
          setSubtypeOptions(subtypeCommunicationList);
          break;

        case DOCUMENT:
          setSubtypeOptions(subtypeDocumentList);
          break;

        case IMAGE_MEDIA:
          setSubtypeOptions(subtypeImageMediaList);
          break;

        default:
          setSubtypeOptions(null);
      }
    }
  }, [evidenceType]);

  // upload file if file state updated
  // useEffect(() => {
  //   if (file.size !== 0) {
  //     const submitData = async () => {
  //       let formData = new FormData();
  //       formData.append("file", file);
  //       const resp = await uploadEvidenceFile(
  //         evidenceObject.id,
  //         id,
  //         dispatch,
  //         formData
  //       );
  //     };
  //     submitData();
  //   }
  // }, [file]);

  // Add radio buttons data and subtype to submitObject if radio buttons changes
  useEffect(() => {
    if (evidenceType) {
      setEvidenceAndSubmitObjects({
        type: evidenceType,
        // subtype: selectedSubtype,
      });
    }
    if (selectedSubtype) {
      setEvidenceAndSubmitObjects({
        subtype: selectedSubtype,
      });
    }
    if (evidenceStatus) {
      setEvidenceAndSubmitObjects({
        status: evidenceStatus,
      });
    }
    if (priority) {
      setEvidenceAndSubmitObjects({
        priority: priority,
      });
    }
    if (coreBook === 0 || coreBook === 1) {
      setEvidenceAndSubmitObjects({
        core_book: coreBook,
      });
    }
  }, [evidenceType, selectedSubtype, evidenceStatus, priority, coreBook]);

  // onSetCheckedEvidenceTypeButton - change checked btn state & reset subtype field
  const onSetCheckedEvidenceTypeButton = (type) => {
    setEvidenceType(type);
    setEvidenceAndSubmitObjects({ type, subtype: null });
    setSelectedSubtype(null);
  };

  //Show Associate Witness form in right side bar
  const handleAssociateWitness = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateWitnessForm
          key={object.id}
          evidenceObject={object}
          // setEvidenceObject={setEvidenceObject}
          selectedMenuTab={index}
          caseId={id}
          caseObject={caseObject}
          // afterSubmit={associateWitnessChange}
          returnFunction={setEvidenceAndSubmitObjects}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Associate Witness",
    });
  };

  // const sendFlagRelevanceData = async (submitObject) => {
  //   if (Object.keys(submitObject).length) {
  //     const resp = await updateEvidence(evidenceId, id, dispatch, submitObject);
  //     if (resp) {
  //       setEvidenceObject(resp);
  //       dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  //       // setIsLoading(false);
  //     }
  //   }
  // };
  // const updateEvidence_ = async (submitObject) => {
  //   if (Object.keys(submitObject).length) {
  //     const resp = await updateEvidence(evidenceId, id, dispatch, submitObject);
  //     if (resp) {
  //       setEvidenceObject(resp);
  //       dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  //     }
  //   }
  // };

  // Show Flag Relevance form in right side bar
  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateElementsThemesIssuesForm
          key={evidenceObject.id}
          // caseId={id}
          evidenceObject={object}
          // setEvidenceObject={setEvidenceObject}
          selectedMenuTab={index}
          sendData={setEvidenceAndSubmitObjects}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  // add new objective input
  const addObjectiveHandler = (event) => {
    event.preventDefault();
    setObjectivesList((prevState) => {
      setEvidenceAndSubmitObjects({ objectives: [...prevState, { name: "" }] });
      return [...prevState, { name: "" }];
    });
  };

  // delete objective input
  const deleteObjectiveHandler = (event, index) => {
    event.preventDefault();
    const objectivesListCopy = [...objectivesList];
    objectivesListCopy.splice(index, 1);

    setObjectivesList([...objectivesListCopy]);
  };

  // submit form and update evidenceObject on success
  const handleSubmit = async (submitObject, updateFromReturn = true) => {
    setOnSubmit(true);
    // Check if label empty, change it to New Evidence (ID)
    // if (submitObject && submitObject.label === "") {
    //   submitObject.label = `New Evidence(${evidenceObject.id})`;
    // }
    const evidenceResp =
      flow === formFlowType.CREATE
        ? await createNewEvidence(id, dispatch, submitObject)
        : await updateEvidence(evidenceId, id, dispatch, submitObject);
    let fileResp;
    if (evidenceResp && updateFromReturn) {
      if (file.size !== 0) {
        let formData = new FormData();
        formData.append("file", file);
        fileResp = await uploadEvidenceFile(
          evidenceResp.id,
          id,
          dispatch,
          formData
        );
      }
      // setEvidenceObject(fileResp ?? evidenceResp);
      // set breadcrumbs last page after update
      dispatch({
        type: SET_LAST_PAGE,
        payload: {
          path: !is_admin
            ? `/app/cases/${id}/trial-hub/evidence/edit/${evidenceResp.id}`
            : `/admin/all-cases/${id}/trial-hub/evidence/edit/${evidenceResp.id}`,
          title: evidenceResp.label,
          activeMenuItem: CASES,
        },
      });
      setOnSubmit(false);
      return true;
    }
    setOnSubmit(false);
  };

  // const submitOnEnter = (event) => {
  //   event.preventDefault();
  //   handleSubmit();
  // };

  // const onBlurHandler = (event) => {
  //   if (!blockBlurEvent) {
  //     event.persist();
  //     setSubmitObject((prevState) => ({
  //       ...prevState,
  //       [event.target.name]: event.target.value,
  //     }));
  //   } else {
  //     setSubmitObjectOnClose({
  //       ...submitObject,
  //       [event.target.name]: event.target.value,
  //     });
  //   }
  // };

  // Set submitObject, when objectives list changes
  // useEffect(() => {
  //   setSubmitObjectOnClose((prevState) => {
  //     if (objectivesList.length && objectivesList[0]) {
  //       const objectiveListFiltered = [
  //         ...objectivesList.filter((el) => el.name !== ""),
  //       ];
  //       return {
  //         ...prevState,
  //         objectives: [...objectiveListFiltered],
  //       };
  //     }
  //   });
  // }, [objectivesList]);

  const onBlurObjectiveFieldsHandler = (event) => {
    if (!blockBlurEvent) {
      setEvidenceAndSubmitObjects({
        objectives: objectivesList,
      });
    } else {
      setEvidenceAndSubmitObjects({
        // ...submitObject,
        objectives: objectivesList.filter((el) => el.name !== ""),
      });
      setSubmitObjectOnClose({
        ...submitObject,
        objectives: objectivesList.filter((el) => el.name !== ""),
      });
    }
  };

  const saveAndCloseHandler = async () => {
    const resp = await handleSubmit({
      ...submitObject,
      ...submitObjectOnClose,
    });
    if (resp) {
      setActiveItem(0);
      if (history.action === "PUSH") {
        return history.goBack();
      } else {
        return history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/evidence`
            : `/admin/all-cases/${id}/trial-hub/evidence`
        );
      }
    }
  };

  const onChangeDateHandler = (date) => {
    setDate(date);
    setEvidenceAndSubmitObjects({
      date:
        moment(date).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(date).format("YYYY-MM-DD"),
    });
  };

  const updateRelatedEvidence = (list) => {
    setRelatedEvidences(list);
    // updateEvidence_({ related_evidence: obj });
    setEvidenceAndSubmitObjects({ related_evidence: list });
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const handleEvidences = (evidenceObject) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: evidenceObject,
      content: (
        <RelatedEvidenceForm
          key={evidenceObject.id}
          evidenceObject={evidenceObject}
          setEvidenceObject={updateRelatedEvidence}
          evidenceId={evidenceObject.id}
          caseObject={caseObject}
          caseId={id}
        />
      ),
      title: "Related Evidence",
    });
  };

  const evidenceSettings = {
    actions: [
      {
        type: "addButton",
        buttonText: "Add related Evidence",
        icon: plusIcon,
        callback: (e) => {
          handleEvidences(evidenceObject);
        },
      },
      {
        type: "edit",
        clickHandler: (index) => {
          handleEvidences(evidenceObject);
        },
      },
      {
        type: "delete",
        clickHandler: () => {
          updateRelatedEvidence([]);
        },
      },
    ],
    objects: {
      relatedEvidences,
    },
    setObjectsFunctions: {
      setWitnessTableObject: (data) => setRelatedEvidences(data),
    },
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element) => (
              <AssociationTableListElement key={element.id}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.label}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={() => {
                      updateRelatedEvidence(
                        array.filter((v) => v.id !== element.id)
                      );
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  // Association Witness Settings
  const associateWitnessSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Witness",
        icon: plusIcon,
        callback: () => handleAssociateWitness(evidenceObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateWitness(evidenceObject);
        },
      },
      {
        type: "delete",
        clickHandler: (index) => {
          const filteredListOfWitnessesByPartyRole = witnessList.filter((x) => {
            if (x.party) {
              return x.party.role !== uniquePartyRolesList[index];
            } else {
              x.party = { role: "Unassigned" };
              return x.party.role !== uniquePartyRolesList[index];
            }
          });
          setEvidenceAndSubmitObjects({
            witnesses: [...filteredListOfWitnessesByPartyRole],
          });
        },
      },
    ],
    // Objects to render in rows
    objects: {
      ...witnessesTableObject,
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      setWitnessTableObject: (data) => setWitnessTableObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element, index) => (
              <AssociationTableListElement key={index}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={() => {
                      const listCopy = [...array];
                      const role = listCopy[0].party
                        ? listCopy[0].party.role
                        : "Unassigned";
                      const witnessesTableObjectCopy = {
                        ...witnessesTableObject,
                      };
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...witnessesTableObjectCopy,
                          [role]: listCopy,
                        };
                        setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        updateSubmitObject({
                          witnesses: plainArray,
                        });
                      }
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  const flagRelevanceSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Elements, Themes, Issues",
        icon: plusIcon,
        callback: () => handleAssociateElementsThemesIssues(evidenceObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateElementsThemesIssues(evidenceObject, index);
        },
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          let submitObj;
          switch (index) {
            case 0:
              submitObj = {
                causes_of_action: [],
                causes_of_action_parent: [],
                causes_of_action_defensive: [],
              };
              setEvidenceAndSubmitObjects(submitObj);
              break;

            case 1:
              submitObj = {
                themes: [],
                themes_parent: [],
              };
              setEvidenceAndSubmitObjects(submitObj);
              break;

            case 2:
              submitObj = {
                issues: [],
                issues_parent: [],
              };
              setEvidenceAndSubmitObjects(submitObj);
              break;

            default:
              break;
          }
        },
      },
    ],
    // Objects to render in rows
    objects: handleFlatRelevanceElements(
      elementsParentList,
      elementsList,
      defenceElementsList,
      issuesParentList,
      issuesList,
      themesParentList,
      themesList,
      caseObject
    ),
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      setElements: setElementsList,
      setThemes: setThemesList,
      setIssues: setIssuesList,
    },
    setParentObjectsFunctions: {
      setElementsParent: setElementsParentList,
      setThemesParent: setThemesParentList,
      setIssuesParent: setIssuesParentList,
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow, setParentArray) => {
          if (array.length) {
            return array.map((element, index) => (
              <element.tagColor key={index} as="li" index={element.index}>
                <Bold14Font style={{ color: "inherit" }}>
                  {element.name}
                </Bold14Font>
                <ButtonAnotherTry
                  tertiary
                  style={{ lineHeight: "0", padding: "0", marginLeft: "6px" }}
                  clickHandler={() => {
                    const listCopy = array.filter(
                      (el) => el.typeCOA === "children"
                    );
                    const listParentCopy = array.filter(
                      (el) => el.typeCOA === "parent"
                    );
                    const listDefElementsCopy = array.filter(
                      (el) => el.typeCOA === "children_def"
                    );
                    if (element.typeCOA === "parent") {
                      const findElementIndex = listParentCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listParentCopy.splice(findElementIndex, 1);
                        setParentArray([...listParentCopy]);
                      }
                    } else if (element.typeCOA === "children") {
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        setArray([...listCopy]);
                      }
                    } else if (element.typeCOA === "children_def") {
                      const findElementIndex = listDefElementsCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listDefElementsCopy.splice(findElementIndex, 1);
                        setDefenceElementsList([...listDefElementsCopy]);
                      }
                    }
                    switch (indexOfRow) {
                      case 0:
                        updateSubmitObject({
                          causes_of_action: listCopy,
                          causes_of_action_defensive: listDefElementsCopy,
                          causes_of_action_parent: listParentCopy,
                        });
                        break;

                      case 1:
                        updateSubmitObject({
                          themes: listCopy,
                          themes_parent: listParentCopy,
                        });
                        break;

                      case 2:
                        updateSubmitObject({
                          issues: listCopy,
                          issues_parent: listParentCopy,
                        });
                        break;

                      default:
                        break;
                    }
                  }}
                >
                  <CloseIcon stroke={"inherit"} />
                </ButtonAnotherTry>
              </element.tagColor>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  if (emptyObject) {
    return (
      <div className="h-100 flex-column justify-content-center align-items-center d-flex">
        <Bold30Font>You don't have access to that case</Bold30Font>
        <Button
          secondary
          clickHandler={() => {
            history.goBack();
          }}
        >
          Go back
        </Button>
      </div>
    );
  }

  const onEvidenceFieldsChange = (event) => {
    event.persist();
    switch (event.target.name) {
      case "label":
        if (event.target.value.length <= 125) setLabel(event.target.value);
        break;
      case "summary":
        if (event.target.value.length <= 300) setSummary(event.target.value);
        break;
      case "preempt":
        if (event.target.value.length <= 300) setPreempt(event.target.value);
        break;
      case "notes":
        if (event.target.value.length <= 300) setNotes(event.target.value);
        break;
      case "reference":
        if (event.target.value.length <= 125) setReference(event.target.value);
        break;
      case "from":
        if (event.target.value.length <= 255) setFrom(event.target.value);
        break;
      case "to":
        if (event.target.value.length <= 255) setTo(event.target.value);
        break;
      default:
        break;
    }
    setEvidenceAndSubmitObjects({
      [event.target.name]: event.target.value,
    });
  };

  const onSubTypeChanged = (selectedOption) => {
    setSelectedSubtype(selectedOption.value);
    setEvidenceAndSubmitObjects({
      subtype: selectedOption.value,
    });
    // handleSubmit();
  };

  const onObjectiveChangeHandler = (event, index) => {
    event.preventDefault();
    const objectiveListCopy = [...objectivesList];
    objectiveListCopy[index].name = event.target.value;
    // setObjectivesList(objectiveListCopy);
    setEvidenceAndSubmitObjects({ objectives: objectiveListCopy });
  };

  const handleDelete = async (event) => {
    event.persist();
    if (evidenceId) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "You won't be able to restore data",
        beforeCloseHandler: async () => {
          await deleteEvidence(evidenceId, id, dispatch);
          history.push(
            !is_admin
              ? `/app/cases/${id}/trial-hub/evidence`
              : `/admin/all-cases/${id}/trial-hub/evidence`
          );
        },
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    } else {
      history.push(
        !is_admin
          ? `/app/cases/${id}/trial-hub/evidence`
          : `/admin/all-cases/${id}/trial-hub/evidence`
      );
    }
  };

  // TODO: Doews I need this check?
  let pageConfig;
  if (evidenceObject) {
    pageConfig = [
      {
        path: !is_admin ? `/app/cases` : `/admin/all-cases`,
        title: `${is_admin ? "All Cases" : "Cases"}`,
        activeMenuItem: CASES,
      },
      {
        path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
        title: caseObject.label ? caseObject.label : "New Case",
        activeMenuItem: CASES,
      },
      {
        path: !is_admin
          ? `/app/cases/${id}/trial-hub/evidence`
          : `/admin/all-cases/${id}/trial-hub/evidence`,
        title: `Trial Hub - Evidence`,
        activeMenuItem: CASES,
      },
      {
        path: !is_admin
          ? flow === formFlowType.CREATE
            ? `/app/cases/${id}/trial-hub/evidence/create`
            : `/app/cases/${id}/trial-hub/evidence/edit/${evidenceObject.id}`
          : flow === formFlowType.CREATE
          ? `/admin/all-cases/${id}/trial-hub/evidence/create`
          : `/admin/all-cases/${id}/trial-hub/evidence/edit/${evidenceObject.id}`,
        title: evidenceObject.label ?? `New evidence`,
        activeMenuItem: CASES,
      },
    ];
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-8">
          <Bold30Font>{evidenceObject.label ?? "New Evidence"}</Bold30Font>
        </div>
        <div className="col-12 col-sm-4 d-flex justify-content-end align-items-center mb-3">
          <Button
            clickHandler={handleDelete}
            loading={onDelete}
            disabled={onDelete}
            style={{ marginRight: "20px" }}
            secondary
            delete_
          >
            Delete
          </Button>
          <Button
            clickHandler={saveAndCloseHandler}
            disabled={onSubmit}
            loading={onSubmit}
            type={"submit"}
            primary
            onMouseEnter={() => setBlockBlurEvent(true)}
            onMouseLeave={() => setBlockBlurEvent(false)}
          >
            <Bold14Font textColor={theme.colors.white}>
              {closeBtnText}
            </Bold14Font>
          </Button>
        </div>
      </div>
      <div className="container-fluid">
        <Panel
          onChange={(index) => {
            setAddEvidenceStage(index);
          }}
          activeItem={addEvidenceStage}
          items={["Evidence Info", "Objectives", "Further Details"]}
        />
      </div>

      {/* Evidence Info */}
      {addEvidenceStage === 0 ? (
        <div className="container-fluid">
          {/*<form*/}
          {/*// onSubmit={submitOnEnter}*/}
          {/*>*/}
          <div className="row">
            <Form style={{ overflowY: "visible" }}>
              <div style={{ padding: "0 15px" }}>
                <div className="form-group">
                  <InputNew
                    // onBlur={onBlurHandler}
                    name="label"
                    type="text"
                    value={label}
                    placeholder="Enter evidence label"
                    label="Evidence label"
                    onChange={onEvidenceFieldsChange}
                    maxLength={125}
                  />
                </div>
                <div className="form-group">
                  <RadioButtonGroup
                    label="Evidence Type"
                    itemsObjects={evidenceTypesButtonsObjects}
                    checkedButton={evidenceType}
                    setCheckedButton={onSetCheckedEvidenceTypeButton}
                    w100
                  />
                </div>
                {subtypeOptions ? (
                  <AnimatedDiv className="form-group row">
                    <div className="col-12 col-sm-6">
                      <SelectAnotherTry
                        label="Sub-type"
                        name="subtype"
                        options={subtypeOptions}
                        value={
                          subtypeOptions !== null
                            ? subtypeOptions.filter(
                                (option) => option.value === selectedSubtype
                              )
                            : null
                        }
                        onChange={onSubTypeChanged}
                      />
                    </div>
                  </AnimatedDiv>
                ) : null}
                <div className="form-group row">
                  <div className="col-12 col-sm-6">
                    <CustomReactDatepicker
                      selected={date}
                      onChange={(date) => onChangeDateHandler(date)}
                      dateFormat={"dd/MM/yyyy"}
                      dateFormatCalendar={"dd MMMM yyyy"}
                      label="Set Date"
                      name="date"
                      placeholderText="Select Date"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-sm-6">
                    <UploadSingleFileInput
                      label="Upload File"
                      file={file}
                      setFile={setFile}
                      validTypes={[
                        "image/jpeg",
                        "image/jpg",
                        "image/png",
                        "application/pdf",
                      ]}
                      maxFileSize={maxFileSize}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div
                    className={
                      witnessList.length ? "col-12" : "col-12 col-sm-6"
                    }
                  >
                    {/* Associate Witness */}
                    <AssociationTable
                      settings={associateWitnessSettings}
                      label="Associate Witness"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div
                    className={
                      relatedEvidences.length ? "col-12" : "col-12 col-sm-6"
                    }
                  >
                    <AssociationTable
                      settings={evidenceSettings}
                      label="Related Evidence"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div
                    className={
                      elementsList.length ||
                      defenceElementsList.length ||
                      elementsParentList.length ||
                      themesList.length ||
                      themesParentList.length ||
                      issuesList.length ||
                      issuesParentList.length
                        ? "col-12"
                        : "col-12 col-sm-6"
                    }
                  >
                    {/* Flag Relevance */}
                    <AssociationTable
                      settings={flagRelevanceSettings}
                      label="Flag Relevance"
                    />
                  </div>
                </div>
              </div>
              <button style={{ display: "none" }} />
            </Form>
          </div>
          {/*</form>*/}
          <div className="row mb-5 justify-content-end">
            <Button
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setAddEvidenceStage(1);
              }}
            >
              <Fragment>
                <Bold14Font textColor={theme.colors.blue}>
                  Objectives
                </Bold14Font>{" "}
                <Arrow className="ml-1" />
              </Fragment>
            </Button>
          </div>
        </div>
      ) : null}

      {/* Objectives */}
      {addEvidenceStage === 1 ? (
        <div className="container-fluid">
          {/*<form*/}
          {/*// onSubmit={submitOnEnter}*/}
          {/*>*/}
          <div className="row">
            <Form>
              <div style={{ padding: "0 15px" }}>
                {objectivesList.map((objective, index) => (
                  <div className="form-group">
                    <div className="d-flex align-items-center position-relative">
                      <InputNew
                        // onBlur={onBlurObjectiveFieldsHandler}
                        type="text"
                        name="objectives"
                        value={objectivesList[index].name}
                        placeholder="Enter Key Objectives"
                        label={index === 0 ? "Objectives" : null}
                        onChange={(event) =>
                          onObjectiveChangeHandler(event, index)
                        }
                        maxLength={125}
                      />
                      {index !== 0 ? (
                        <ButtonNew
                          tertiary
                          clickHandler={(event) =>
                            deleteObjectiveHandler(event, index)
                          }
                          onMouseEnter={() => setBlockBlurEvent(true)}
                          onMouseLeave={() => setBlockBlurEvent(false)}
                          style={{
                            padding: 0,
                            position: "absolute",
                            right: "-12px",
                          }}
                        >
                          <img src={deleteObjectiveIcon} alt="" />
                        </ButtonNew>
                      ) : null}
                    </div>
                  </div>
                ))}
                <div style={{ marginBottom: "30px" }}>
                  <Button
                    tertiary
                    clickHandler={addObjectiveHandler}
                    onMouseEnter={() => setBlockBlurEvent(true)}
                    onMouseLeave={() => setBlockBlurEvent(false)}
                  >
                    Add Objective
                  </Button>
                </div>
                <div className="form-group">
                  <TextArea
                    name="summary"
                    label="Summary"
                    placeholder="Enter your summary"
                    onChange={onEvidenceFieldsChange}
                    value={summary}
                    // onBlur={onBlurHandler}
                  />
                </div>
                <div className="form-group">
                  <TextArea
                    name="notes"
                    label="Question Cues & Notes"
                    placeholder="Enter your Question Cues & Notes"
                    onChange={onEvidenceFieldsChange}
                    value={notes}
                    // onBlur={onBlurHandler}
                  />
                </div>
                <div className="form-group">
                  <TextArea
                    name="preempt"
                    label="Preempt"
                    placeholder="Enter your preempt"
                    onChange={onEvidenceFieldsChange}
                    value={preempt}
                    // onBlur={onBlurHandler}
                  />
                </div>
              </div>
              <button style={{ display: "none" }} />
            </Form>
          </div>
          {/*</form>*/}
          <div className="row mb-5 justify-content-end">
            <Button
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setAddEvidenceStage(0);
              }}
            >
              <Fragment>
                <Arrow
                  className="ml-1"
                  style={{ transform: "rotate(180deg)" }}
                />
                <Bold14Font textColor={theme.colors.blue}>
                  Evidence Info
                </Bold14Font>
              </Fragment>
            </Button>
            <Button
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setAddEvidenceStage(2);
              }}
            >
              <Fragment>
                <Bold14Font textColor={theme.colors.blue}>
                  Further Details
                </Bold14Font>
                <Arrow className="ml-1" />
              </Fragment>
            </Button>
          </div>
        </div>
      ) : null}

      {addEvidenceStage === 2 ? (
        <div className="container-fluid">
          {/*<form*/}
          {/*// onSubmit={submitOnEnter}*/}
          {/*>*/}
          <div className="row">
            <Form>
              <div style={{ padding: "0 15px" }}>
                <div className="form-group">
                  <RadioButtonGroup
                    checkedButton={evidenceStatus}
                    setCheckedButton={setEvidenceStatus}
                    itemsObjects={evidenceStatusButtonsObjects}
                    label="Evidence Status"
                  />
                </div>
                <div className="form-group">
                  <InputNew
                    label="Author"
                    name="from"
                    // onBlur={onBlurHandler}
                    placeholder="Author"
                    value={from}
                    onChange={onEvidenceFieldsChange}
                  />
                </div>
                {evidenceType === COMMUNICATION ? (
                  <div className="form-group">
                    <InputNew
                      label="Recipient"
                      name="to"
                      // onBlur={onBlurHandler}
                      placeholder="Recipient"
                      value={to}
                      onChange={onEvidenceFieldsChange}
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <RadioButtonGroup
                    checkedButton={priority}
                    setCheckedButton={setPriority}
                    itemsObjects={priorityButtonsObjects}
                    label="Priority - High to Low"
                  />
                </div>
                <div className="form-group">
                  <RadioButtonGroup
                    label="Core Book"
                    itemsObjects={coreBookButtonsObjects}
                    checkedButton={coreBook}
                    setCheckedButton={setCoreBook}
                  />
                </div>
                <div className="form-group row">
                  <div className="col-12 col-sm-6">
                    <InputNew
                      placeholder="Enter Reference"
                      name="reference"
                      label="Reference"
                      value={reference}
                      onChangeHandler={onEvidenceFieldsChange}
                      // onBlur={onBlurHandler}
                    />
                  </div>
                </div>
              </div>
              <button style={{ display: "none" }} />
            </Form>
          </div>
          {/*</form>*/}
          <div className="row mb-5 justify-content-end">
            <Button
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setAddEvidenceStage(1);
              }}
            >
              <Fragment>
                <Arrow
                  className="ml-1"
                  style={{ transform: "rotate(180deg)" }}
                />
                <Bold14Font textColor={theme.colors.blue}>
                  Objectives
                </Bold14Font>
              </Fragment>
            </Button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

AddEditEvidence.propTypes = {
  flow: PropTypes.oneOf([formFlowType.CREATE, formFlowType.EDIT]),
};

AddEditEvidence.defaultProps = {
  flow: formFlowType.EDIT,
};

export default withTheme(AddEditEvidence);
