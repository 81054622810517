import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import {
  deleteDocument,
  getCaseById,
  getDocumentsList,
  updateUserSettings,
} from "../../Auth/auth";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../../components/FontsNewComponent/Fonts";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import Menu from "../../../components/baseAppComponents/Menu/Menu";
import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import editIcon from "../../../assets/img3/svg/edit-icon-white.svg";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import DocumentCard from "../../../components/DocumentCard/DocumentCard";
import NoRecords from "../../../components/NoRecords";
import { route } from "../../../routes";
import Evidence from "../TrialHub/Evidence/Evidence";
import { theme } from "../../../styled-components/Theme/Theme";
import ViewDocument from "./ViewDocument";
import EditDocument from "./EditDocument";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
  UPDATE_USER,
} from "../../../redux/types";
import NewBundleForm from "../../../components/forms/DocumentForms/NewBundleForm";
import EditBundlesForm from "../../../components/forms/DocumentForms/EditBundlesForm";
import AddNewDocumentForm from "../../../components/forms/EvidenceForms/AddNewDocumentForm";
import useWindowSize from "../../../hooks/useWindowSize";
import SearchFilterBar from "../../../components/baseAppComponents/SearchFilterBar";
import { searchFilterBarTypes } from "../../../components/baseAppComponents/SearchFilterBar/SearchFilterBar";
import {
  CARD_VIEW,
  documentTypesMap,
  TABLE_VIEW,
} from "../../../utils/constants";
import Table from "../../../components/Table/Table";
import AnimatedDiv from "../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import LeftSideBarUserMenu from "../../../components/baseAppComponents/LeftSideBarUserLayout/LeftSideBarUserMenu";
import LiStyled from "../../../components/Table/Styled/LiStyled";
import moment from "moment";
import AssignToBundleForm from "../../../components/forms/DocumentForms/AssignToBundleForm";
import PageTitle from "../../../components/PageTitle";
import SubEvidence from "../../../components/forms/SubEvidence/SubEvidence";

export const ALL = "ALL_DOCUMENTS";
export const EVIDENCE_DOCUMENTS = "App\\Evidence";
export const CASE_DOCUMENTS = "App\\Cases";
export const AUTHORITIES_DOCUMENTS = "App\\Authority";
export const SUB_EVIDENCE = "App\\SubEvidence";
export const APPLICATION_DOCUMENTS = "App\\Papers";
export const WITNESSES = "App\\Witness";
export const TOPIC = "App\\Topic";
export const EXAMWITNESS = "App\\ExamWitness";
export const APPLICATION = "App\\Application";
export const DOCUMENT = "App\\Documents";
export const OTHER = "App\\Other";

const order = [
  {
    value: 1,
    label: "Last updated",
  },
  {
    value: 3,
    label: "Alphabetical",
  },
];

const DocumentsRoute = () => {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  return (
    <Switch>
      <Route
        exact={true}
        path={`${
          !is_admin
            ? route.baseApp.documents.container
            : route.admin.baseApp.documents.container
        }`}
      >
        <Documents />
      </Route>
      <Route
        exact
        path={`${
          !is_admin
            ? route.baseApp.documents.documentDetails
            : route.admin.baseApp.documents.documentDetails
        }`}
      >
        <ViewDocument />
      </Route>
      <Route
        exact
        path={`${
          !is_admin
            ? route.baseApp.documents.addEditDocument
            : route.admin.baseApp.documents.addEditDocument
        }`}
      >
        <EditDocument />
      </Route>
    </Switch>
  );
};

const defaultViewOption = { label: "View cards", value: CARD_VIEW };

const setClassName = (width, className) => {
  if ((width > 1200 && width < 1600) || width < 992) {
    return className;
  }
  return "";
};

const Documents = () => {
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [activeItem, setActiveItem] = useState(ALL);
  const [caseObject, setCaseObject] = useState({});
  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectedSortFunction, setSelectedSortFunction] = useState(1);
  const [documents, setDocuments] = useState([]);
  const [bundlesOptions, setBundlesOptions] = useState([
    { value: null, label: "All" },
  ]);
  const [selectedBundle, setSelectedBundle] = useState("");

  const userSettings = useSelector((state) => state.auth.user.settings);
  const [selectViewOptions, setSelectViewOptions] = useState([
    defaultViewOption,
    { label: "View table", value: TABLE_VIEW },
  ]);
  const [selectViewOption, setSelectViewOption] = useState(
    userSettings &&
      userSettings.default_document_view &&
      selectViewOptions &&
      selectViewOptions.length
      ? selectViewOptions.find((el) => {
          return el.value === userSettings.default_document_view;
        })
      : defaultViewOption
  );
  const [selectedRowInTable, setSelectedRowInTable] = useState(0);

  const is_admin = useSelector((state) => state.auth.user.is_admin);

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const sortAlphabetical = (a, b, order = "ASC") => {
    let item_a = Object.assign({}, order === "DESC" ? b : a);
    let item_b = Object.assign({}, order === "DESC" ? a : b);
    if (!item_a.name) {
      item_a.name = "New document";
    }
    if (!item_b.name) {
      item_b.name = "New document";
    }
    item_a.name =
      typeof item_a.name === "string"
        ? item_a.name.toLowerCase()
        : item_a.name.toString();
    item_b.name =
      typeof item_b.name === "string"
        ? item_b.name.toLowerCase()
        : item_b.name.toString();
    return item_a.name.localeCompare(item_b.name, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const sortByIdAscending = (itemA, itemB) => {
    return itemA.id - itemB.id;
  };

  const sortByIdDescending = (itemA, itemB) => {
    return itemB.id - itemA.id;
  };

  const sortByDateTime = (item_a, item_b) => {
    return moment(item_b.updated_at).diff(moment(item_a.updated_at));
  };

  const setSortFunction = (a, b) => {
    switch (selectedSortFunction) {
      // // from newest to oldest id
      // case 1:
      //   return sortByIdAscending(a, b);

      // sort by updated_at field
      case 1:
        return sortByDateTime(a, b);
      // from oldest to newest id
      case 2:
        return sortByIdDescending(a, b);
      //alphabetically a-Z
      case 3:
        return sortAlphabetical(a, b);
      // alphabetically from Z to A
      case 4:
        return sortAlphabetical(a, b, "DESC");
      default:
        break;
    }
  };

  const addBundleCallback = () => {};
  const addDocument = async () => {
    /*    setCreating(true);
    let resp = await addDocumentCall(id, dispatch);
    setCreating(false);
    if (resp) {
      history.push(!is_admin ? `/app/cases/${id}/documents/edit/${resp.id}` : `/admin/all-cases/${id}/documents/edit/${resp.id}`);
    }*/
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: <AddNewDocumentForm callback={addBundleCallback} caseId={id} />,
      title: "Add Documents",
    });
  };
  const onFilterDatesAlphabetChangeHandler = (value) => {
    setSelectedSortFunction(value.value);
  };

  const filter = (value) => {
    let check = true;
    if (activeItem !== ALL) {
      check = value.document_type === activeItem;
    }
    if (check && searchBarValue) {
      if (!value.name) {
        value.name = "New Document";
      }
      check =
        value.name.toUpperCase().indexOf(searchBarValue.toUpperCase()) !== -1;
    }
    if (check && selectedBundle) {
      check =
        value.bundles &&
        value.bundles.find((b) => {
          return b.id === selectedBundle;
        });
    }
    return check;
  };

  const panelItems = [
    {
      text: "All",
      amount: documents.length,
      activeType: ALL,
    },
    {
      text: "Case Documents",
      amount: documents.filter((v) => v.document_type === "App\\Cases").length,
      activeType: CASE_DOCUMENTS,
    },
    {
      text: "Evidence",
      amount: documents.filter((v) => v.document_type === "App\\Evidence")
        .length,
      activeType: EVIDENCE_DOCUMENTS,
    },
    {
      text: "Authorities",
      amount: documents.filter((v) => v.document_type === "App\\Authority")
        .length,
      activeType: AUTHORITIES_DOCUMENTS,
    },
    {
      text: "Application Papers",
      amount: documents.filter((v) => v.document_type === "App\\Papers").length,
      activeType: APPLICATION_DOCUMENTS,
    },
  ];
  const reloadDocuments = async () => {
    const documents_list = await getDocumentsList(id, dispatch);
    setDocuments(documents_list);
    let list = [];
    let options_ = [];
    documents_list.forEach((v) => {
      if (v.bundles && v.bundles.length) {
        v.bundles.forEach((b) => {
          if (list.indexOf(b.id) === -1) {
            list.push(b.id);
            options_.push({ value: b.id, label: b.label });
          }
        });
      }
    });
    setBundlesOptions([...[{ value: null, label: "All" }], ...options_]);
  };
  const getData = async () => {
    const caseResp = await getCaseById(id, dispatch);
    if (caseResp) {
      setCaseObject(caseResp);
      await reloadDocuments();
      setIsDocumentLoading(false);
      return true;
    } else {
      if (is_admin && !caseResp) {
        history.push("/admin/all-cases");
      }
    }
    return false;
  };
  useEffect(() => {
    getData();
  }, []);

  if (isDocumentLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/documents`
        : `/admin/all-cases/${id}/documents`,
      title: `Documents Hub`,
      activeMenuItem: CASES,
    },
  ];
  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };
  const onFilterTypesValueChangeHandler = (select) => {
    setSelectedBundle(select.value);
  };

  const clearSearchBarHandler = (event) => {
    setSearchBarValue("");
  };
  const filteredDocs = documents.sort(setSortFunction).filter(filter);

  const onChangeSelectViewOption = async (selectedOption) => {
    setSelectViewOption(selectedOption);
    const resp = await updateUserSettings({
      settings: {
        ...userSettings,
        default_document_view: selectedOption.value,
      },
    });
    if (resp) {
      dispatch({ type: UPDATE_USER, payload: resp });
    }
  };

  const openBundlePanel = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: document,
      content: <NewBundleForm reloadDocuments={reloadDocuments} idCase={id} />,
      title: "New Bundle",
    });
  };

  const openEditBundlesPanel = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: document,
      content: (
        <EditBundlesForm reloadDocuments={reloadDocuments} idCase={id} />
      ),
      title: "Edit Bundles",
    });
  };

  const searchFilterBarSettingsObject = {
    fields: [
      {
        type: searchFilterBarTypes.SEARCH,
        fieldName: "search",
        placeholder: "Search...",
        connectedData: {
          state: searchBarValue,
          setState: setSearchBarValue,
        },
        style: { width: "66.66%" },
      },
      {
        type: searchFilterBarTypes.FILTER,
        fieldName: "view",
        placeholder: "View",
        connectedData: {
          state: selectViewOptions,
          setState: onChangeSelectViewOption,
          defaultValue: selectViewOption,
        },
        style: { width: "16.66%" },
      },
      {
        type: searchFilterBarTypes.FILTER,
        fieldName: "bundle",
        placeholder: "Bundle",
        connectedData: {
          state: bundlesOptions,
          setState: (option) => setSelectedBundle(option.value),
          noDefaultValue: true,
        },
        style: { width: "16.66%" },
      },
    ],
  };
  const displayForm = (doc, case_) => {
    const clone = [...documents];
    const index = clone.findIndex((v) => v.id === doc.id);
    if (index !== -1) {
      clone[index] = doc;
      setDocuments(clone);
    }
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: doc,
      content: (
        <SubEvidence
          id={doc.id}
          sub_evidence={doc.sub_evidence}
          caseObject={caseObject}
          callback={displayForm}
          caseId={id}
          documentId={doc.id}
        />
      ),
      title: "Key Sections",
    });
  };
  const viewDocument = (object, pushState) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push(`/app/cases/${id}/documents/${object.id}`, pushState ?? null);
  };

  const deleteDocumentFromView = async (documentId) =>
    setDocuments((prevState) =>
      prevState.filter((document) => document.id !== documentId)
    );

  const onClickAssignToBundleButton = (_, docId) => {
    const documentObject = documents.find((doc) => doc.id === docId);

    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: documentObject,
      content: (
        <AssignToBundleForm
          key={documentObject.id}
          caseId={id}
          afterSaveHandler={reloadDocuments}
        />
      ),
      title: "Assign to bundle",
    });
  };

  const popupMenuDocuments = [
    {
      id: 1,
      title: "View",
      onClickHandler: (_, id) => viewDocument({ id: id }),
    },
    {
      id: 2,
      title: "Assign to bundle",
      onClickHandler: (_, id) => onClickAssignToBundleButton(_, id),
    },
    {
      id: 3,
      title: "Edit",
      onClickHandler: (_, id) => {
        const doc = documents.find((el) => el.id === id);
        if (doc && doc.document_type === "App\\Cases") {
          history.push(`/app/cases/${caseObject.id}/documents/edit/${id}`);
          return;
        }
        viewDocument({ id: id }, { openEditDocPanel: true });
      },
    },
    {
      id: 4,
      title: "Delete",
      onClickHandler: (_, documentId) => {
        dispatch({
          type: SAVE_MODAL_DATA,
          payload: "You won't be able to restore data",
          beforeCloseHandler: async () => {
            await deleteDocument(id, documentId, dispatch);
            await deleteDocumentFromView(documentId);
          },
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
      },
    },
  ];

  const docsTableSettings = {
    collapsed: true,
    canSort: true,
    clickAction: (object) => viewDocument(object),
    actions: [
      {
        type: "options",
        popupComponent: (
          <LeftSideBarUserMenu
            menuItems={popupMenuDocuments}
            style={{
              width: "150px",
              height: "fit-content",
              left: "133px",
              bottom: "14px",
            }}
          />
        ),
        callback: (object, event, state, setState, rowIndex) => {
          // show or hide popup menu on appropriate row
          if (state === rowIndex) {
            setState(null);
          } else {
            setState(rowIndex);
          }
        },
      },
    ],
    fields: [
      {
        name: "name",
        label: "NAME",
        sortCondition: 'string',
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "document_type_name",
        label: "CLASS",
        sortCondition: 'string',
        canSort: true,
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.document_type
                ? documentTypesMap[object.document_type] === "Paper"
                  ? "Application Paper"
                  : documentTypesMap[object.document_type]
                : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "created_at",
        label: "Date created",
        sortCondition: 'date',
        canSort: true,
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.created_at
                ? moment(object.created_at).format("DD MMM YYYY")
                : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "sub_evidence_count",
        canSort: true,
        label: "KEY SECTIONS",
        renderer: (object, originalObject) => {
          if (originalObject.sub_evidence && originalObject.sub_evidence.length) {
            return (
              <Medium14Font
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: originalObject,
                    content: (
                      <SubEvidence
                        id={originalObject.id}
                        sub_evidence={originalObject.sub_evidence}
                        caseObject={caseObject}
                        callback={displayForm}
                        // annotManager={annotManager_}
                        caseId={id}
                        documentId={object.id}
                      />
                    ),
                    title: "Key Sections",
                  });
                }}
                underline
                textColor={theme.colors.blue}
              >
                {originalObject.sub_evidence.length}
              </Medium14Font>
            );
          }
        },
      },
      {
        name: "bundles",
        label: "BUNDLES",
        renderer: (object) => {
          if (object.bundles && object.bundles.length) {
            return (
              <ul>
                {object.bundles.map((bundle, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{bundle.label}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
    ],
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <PageTitle pageLabel="Documents Hub" colClassName="col-12 mb-4" />
      <div
        className={`d-flex ${
          (width < 1515 && width > 1199.98) || width < 840
            ? "flex-wrap justify-content-start"
            : "justify-content-between"
        } align-items-center mb-4`}
      >
        <Menu
          className={width < 1515 ? "mb-3" : ""}
          font={Bold18Font}
          activeFont={Bold18Font}
          getType={setActiveItem}
          casesMenu={panelItems}
          active={activeItem}
        />
      </div>
      <div className="row mb-3">
        {/*<div className="col d-flex flex-md-wrap">*/}
        <div
          className={`col d-flex justify-content-between align-items-center${setClassName(
            width,
            " flex-wrap"
          )}`}
        >
          <SearchFilterBar
            settingsObject={searchFilterBarSettingsObject}
            className={`mr-3${setClassName(width, " mb-2")}`}
          />
          <div className="d-sm-flex flex-wrap flex-lg-nowrap justify-content-end align-items-center">
            <ButtonNew
              className="mr-sm-3 mb-2 mb-md-0"
              loading={creating}
              disabled={creating}
              clickHandler={() => {
                addDocument();
              }}
              primary
            >
              <div className="d-flex align-items-center justify-content-center">
                <img src={plusIcon} alt="" className="img-fluid mr-2" />
                <Bold14Font style={{ color: "inherit", whiteSpace: "nowrap" }}>
                  Add Documents
                </Bold14Font>
              </div>
            </ButtonNew>
            <ButtonNew
              className="mr-sm-3 mb-2 mb-md-0"
              secondary
              clickHandler={openBundlePanel}
            >
              <div className="d-flex align-items-center justify-content-center">
                <img
                  style={{
                    backgroundColor: theme.colors.blue,
                    borderRadius: "8px",
                  }}
                  src={plusIcon}
                  alt=""
                  className="img-fluid mr-2"
                />
                <Bold14Font
                  style={{ color: theme.colors.blue, whiteSpace: "nowrap" }}
                >
                  New Bundle
                </Bold14Font>
              </div>
            </ButtonNew>
            <ButtonNew
              className="mb-2 mb-md-0"
              secondary
              clickHandler={openEditBundlesPanel}
            >
              <div className="d-flex align-items-center justify-content-center">
                <img
                  style={{
                    backgroundColor: theme.colors.blue,
                    borderRadius: "8px",
                    opacity: 0.9,
                  }}
                  src={editIcon}
                  alt=""
                  className="img-fluid mr-2"
                />
                <Bold14Font
                  style={{ color: theme.colors.blue, whiteSpace: "nowrap" }}
                >
                  Edit Bundles
                </Bold14Font>
              </div>
            </ButtonNew>
          </div>
        </div>
      </div>
      {/*<div className="row mb-4">*/}
      {/*  <div className="col-12 d-flex flex-wrap justify-content-start justify-content-md-end"></div>*/}
      {/*</div>*/}
      <div className="row pb-5 justify-content-center">
        {filteredDocs.length ? (
          selectViewOption && selectViewOption.value === CARD_VIEW ? (
            filteredDocs
              .filter((v) => {
                return v.file;
              })
              .map((value, index) => {
                return (
                  <div
                    key={index}
                    className={
                      width < 1600
                        ? "col-12 col-sm-6 col-lg-4 col-xl-4 mb-3"
                        : "col-xl-3 mb-3"
                    }
                  >
                    <DocumentCard
                      key={value.id}
                      onKeySectionClickHandler={(e) => {
                        e.stopPropagation();
                        window.doc_object = value;
                        dispatch({
                          type: SHOW_RIGHT_SIDE_BAR,
                          url: history.location.pathname,
                          editableObject: value,
                          content: (
                            <SubEvidence
                              id={value.id}
                              sub_evidence={value.sub_evidence}
                              caseObject={caseObject}
                              callback={displayForm}
                              caseId={id}
                              documentId={value.id}
                            />
                          ),
                          title: "Key Sections",
                        });
                      }}
                      reloadDocuments={reloadDocuments}
                      documentObject={value}
                    />
                  </div>
                );
              })
          ) : (
            <AnimatedDiv className="col-12" style={{ padding: "0 30px" }}>
              <Table
                data={filteredDocs.filter((v) => {
                  return v.file;
                })}
                settings={docsTableSettings}
                selectedId={selectedRowInTable}
              />
            </AnimatedDiv>
          )
        ) : (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <NoRecords>
              <Bold18Font textColor={"#0f122f"}>
                Your Documents will appear here
              </Bold18Font>
            </NoRecords>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DocumentsRoute;
