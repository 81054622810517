import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Bold30Font,
  Medium14Font,
} from "../../../components/FontsNewComponent/Fonts";
import {
  generateDocWitness,
  getCaseById,
  getWitnessById,
  getWitnessProofsElements,
  getWitnessProofsIssues,
  getWitnessProofsThemes,
  updateWitnessById,
} from "../../Auth/auth";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch, Link } from "react-router-dom";
import { route } from "../../../routes";
import WitnessBasicInfo from "../TrialHub/Witnesses/WitnessBasicInfo";
import SelectNew from "../../../components/Select/SelectNew";
import { setTagField, setTagFieldClean } from "../../../utils/function";
import EvidencePreview from "../../../components/forms/EvidenceForms/EvidencePreview";
import { SHOW_RIGHT_SIDE_BAR } from "../../../redux/types";
import magnifierIcon from "../../../assets/img3/svg/magnifier-icon.svg";
import CasesSearchFilterBar from "../../../components/baseAppComponents/CasesSearchFilterBar";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import { pdf } from "@react-pdf/renderer";
import { PdfWitness } from "../../../components/Pdf/PdfWitness";
import { saveAs } from "file-saver";
import unlock from "../../../assets/img3/svg/unlock.svg";
import lock from "../../../assets/img3/svg/lock.svg";
import Tables from "../../../components/Tables";
import moment from "moment";
import EvidenceEditNote from "../../../components/forms/EvidenceForms/EvidenceEditNote";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function WitnessProofs() {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [dataToPdf, setDataToPdf] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [caseObject, setCaseObject] = useState({});
  const [witnessObject, setWitnessObject] = useState([]);
  const [searchType, setSearchType] = useState("theme");
  const [options, setOptions] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [isLoadingWitness, setIsLoadingWitness] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [data, setData] = useState([]);
  const [canDrag, setCanDrag] = useState(false);
  const { id, witness_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isXExamWitnessDetailsPage =
    useRouteMatch(`${route.baseApp.trialProofs.exWitnessProofs}`) ||
    useRouteMatch(`${route.admin.baseApp.trialProofs.exWitnessProofs}`);
  const panelOptions = [
    {
      value: 0,
      label: "Element",
    },
    {
      value: 1,
      label: "Theme",
    },
    {
      value: 2,
      label: "Issue",
    },
  ];
  const checkIsXExamFlowIsFalse = () => {
    return !(isXExamWitnessDetailsPage && isXExamWitnessDetailsPage.isExact);
  };
  const { url } = useRouteMatch();
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const resp = await getCaseById(id, dispatch);
      if (is_admin && !resp) {
        history.push("/admin/all-cases");
      }
      setCaseObject(resp);
      setIsLoading(false);
    };
    loadData();
  }, []);
  const loadWitnessData = async () => {
    if (!isLoadingWitness) {
      setIsLoadingWitness(true);
      const resp = await getWitnessById(id, witness_id, dispatch);
      // if (checkIsXExamFlowIsFalse()) {
        setWitnessObject(resp);
      // } else {
      //   const uniqueParentElements = [];
      //   const mapObject = {
      //     causes_of_action: [],
      //     causes_of_action_defensive: [],
      //     issues_parent: [],
      //     issues: [],
      //     themes_parent: [],
      //     themes: [],
      //   };
      //   resp.topics.forEach((object) => {
      //     object.causes_of_action_parent.forEach((el) => {
      //       if (
      //         uniqueParentElements.findIndex(
      //           (item) => item.id === el.type.id
      //         ) === -1
      //       ) {
      //         uniqueParentElements.push(el.type);
      //       }
      //     });
      //
      //     Object.keys(mapObject).forEach((key) => {
      //       object[key].forEach((el) => {
      //         if (
      //           mapObject[key].findIndex((item) => item.id === el.id) === -1
      //         ) {
      //           mapObject[key].push(el);
      //         }
      //       });
      //     });
      //   });
      //   setWitnessObject({
      //     ...resp,
      //     causes_of_action_parent: [...uniqueParentElements],
      //     ...mapObject,
      //   });
      // }
      setIsLoadingWitness(false);
    }
  };
  const setNewOrder = async (items) => {
    setData(items);
    const data_to_send = {};
    let type;
    if (selectedTab === 1) {
      type = "themes";
    } else if (selectedTab === 0) {
      type = "elements";
    } else if (selectedTab === 2) {
      type = "issues";
    }
    if (!data_to_send[type]) {
      data_to_send[type] = {};
    }
    const items_to_send = [];
    items.forEach((item) => {
      let tmp = {
        key: item.key,
        id: item.id,
        evidences: item.evidences.map((v) => v.id),
      };
      items_to_send.push(tmp);
    });
    data_to_send[type] = items_to_send;
    let resp = await updateWitnessById(id, witness_id, dispatch, {
      ordering: { ...witnessObject.ordering, ...data_to_send },
    });
    setWitnessObject(resp);
  };
  useEffect(() => {
    const reloadWitness = async () => {
      await loadWitnessData();
      await getDataFromApi();
    };
    reloadWitness();
  }, [witness_id]);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    return setNewOrder(items);
  };
  const getDataFromApi = async () => {
    setIsLoadingTab(true);
    let resp;
    const name = checkIsXExamFlowIsFalse() ? "Evidence" : "Topic";
    switch (selectedTab) {
      case 1:
        resp = await getWitnessProofsThemes(
          id,
          witness_id,
          dispatch,
          !checkIsXExamFlowIsFalse()
        );
        setOptions([
          { value: "theme", label: "Search Theme" },
          { value: "evidence", label: `Search ${name}` },
        ]);
        setSearchType("theme");
        setData(resp);
        break;
      case 2:
        resp = await getWitnessProofsIssues(
          id,
          witness_id,
          dispatch,
          !checkIsXExamFlowIsFalse()
        );
        setOptions([
          { value: "issue", label: "Search Issue" },
          { value: "evidence", label: `Search ${name}` },
        ]);
        setSearchType("issue");
        setData(resp);
        break;
      case 0:
        resp = await getWitnessProofsElements(
          id,
          witness_id,
          dispatch,
          !checkIsXExamFlowIsFalse()
        );
        setOptions([
          { value: "element", label: "Search Element" },
          { value: "evidence", label: `Search ${name}` },
        ]);
        setSearchType("element");
        setData(resp);
        break;
      default:
        break;
    }
    setSearchBarValue("");
    setIsLoadingTab(false);
  };
  useEffect(() => {
    getDataFromApi();
  }, [selectedTab]);
  const path_ = `/${
    !is_admin ? "app/cases" : "admin/all-cases"
  }/${id}/trial-proofs/${
    checkIsXExamFlowIsFalse() ? "witness-proofs" : "x-exam-witness-proofs"
  }/`;
  const pageConfig = [
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs`
        : `/admin/all-cases/${id}/trial-proofs`,
      title: `Trial Proofs`,
      activeMenuItem: CASES,
    },
    {
      path: `/${path_}${witness_id}`,
      title: checkIsXExamFlowIsFalse()
        ? `Witness Proofs`
        : "X-Exam Witness Proofs",
      activeMenuItem: CASES,
    },
  ];
  const witnessOption = caseObject.id
    ? caseObject.witnesses.filter(v => {
      if (checkIsXExamFlowIsFalse()) {
        return !v.party || v.party.client;
      } else {
        return v.party && !v.party.client;
      }
    }).map(
        (v) => {
          return { label: v.name, value: v.fake_id };
        }
      )
    : [];
  const onWitnessChange = (select) => {
    history.push(`${path_}${select.value}`);
  };
  const openBackground = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <WitnessBasicInfo
          hideBorders
          checkIsXExamFlowIsFalse={checkIsXExamFlowIsFalse()}
          witnessObject={witnessObject}
          caseObject={caseObject}
        />
      ),
      title: "Background",
    });
  };
  const previewEvidence = (evidence) => {
    let data = { ...evidence, ...{ id: evidence.fake_id } };
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <EvidencePreview
          key={data.id}
          caseId={id}
          evidence={data}
          goToDetails={true}
        />
      ),
      title: "Preview",
    });
  };
  const editNote = (evidence) => {
    let data = { ...evidence, ...{ id: evidence.fake_id } };
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <EvidenceEditNote
          key={data.id}
          caseId={id}
          evidence={data}
          callback={() => {
            getDataFromApi();
          }}
          goToDetails={true}
        />
      ),
      title: "Edit Note",
    });
  };
  const updateObject = (resp) => {
    getDataFromApi();
  };

  const filterKey = (v) => {
    let check = true;
    if (searchType === "issue") {
      check =
        `${v.issue ? v.issue.name + " - " : ""} ${v.name}`
          .toLowerCase()
          .indexOf(searchBarValue.toLowerCase()) !== -1;
    } else if (searchType === "element") {
      check =
        `${
          v.causes_of_action
            ? v.causes_of_action.type.name + " - "
            : v.type
            ? v.type.name + " - "
            : ""
        } ${v.name ?? ""}`
          .toLowerCase()
          .indexOf(searchBarValue.toLowerCase()) !== -1;
    } else if (searchType === "theme") {
      check =
        `${v.theme ? v.theme.name + " - " : ""} ${v.name}`
          .toLowerCase()
          .indexOf(searchBarValue.toLowerCase()) !== -1;
    }
    return check;
  };
  const filter = (v) => {
    let check = true;
    if (searchBarValue && searchType === "evidence") {
      if (checkIsXExamFlowIsFalse()) {
        check =
          v.label.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1;
      } else {
        check =
          v.name.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1;
      }
    }
    return check;
  };
  const generatePdfData = (data_, selectedTab) => {
    const return_ = [];
    data_.filter(filterKey).forEach((v) => {
      let temp_obj = {
        headers: [
          {
            name: "id",
            label: "ID",
            width: "5%",
          },
          {
            name: "evidence",
            label: "Evidence",
            width: "12%",
          },
          {
            name: "objectives",
            label: "Objectives & EXAM LINES",
            width: "18%",
          },
          {
            name: "notes",
            label: "Notes",
            width: "12%",
          },
          {
            name: "preempt",
            label: "Preempt",
            width: "10%",
          },
          {
            name: "tags",
            label: "Tags",
            width: "15%",
          },
          {
            name: "reference",
            label: "Reference",
            width: "13%",
          },
          {
            name: "witness",
            label: "Other Witness",
            width: "15%",
          },
        ],
        rows: [],
      };
      if (selectedTab === 1) {
        temp_obj.title = `${v.theme ? v.theme.name + " - " : ""}${v.name}`;
      } else if (selectedTab === 0) {
        temp_obj.title = `${
          v.causes_of_action
            ? v.causes_of_action.type.name + " - "
            : v.type
            ? v.type.name
            : ""
        }${v.name && v.type ? " - " : " "}${v.name ? v.name : ""}`;
      } else if (selectedTab === 2) {
        temp_obj.title = `${v.issue ? v.issue.name + " - " : ""}${v.name}`;
      }
      setTagFieldClean(v.evidences.filter(filter)).forEach((ev) => {
        let row = {
          id: ev.fake_id ?? ev.id,
          reference: ev.reference,
          tags: ev.tags_clean,
          summary: ev.summary,
          sub_evidence: ev.sub_evidence ? ev.sub_evidence.map(v => v.label) : [],
          evidence: ev.label,
          date: ev.date ? moment(ev.date).format("DD/MM/YY") : "",
          notes: ev.notes,
          preempt: ev.preempt,
          objectives: [],
        };
        if (ev.objectives && ev.objectives.length) {
          if (
            (checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
              witnessObject.exam_lines.evidences &&
              witnessObject.exam_lines.evidences.indexOf(ev.id) === -1)
              ||
            (!checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
              witnessObject.exam_lines.topics &&
              witnessObject.exam_lines.topics.indexOf(ev.id) === -1)
          ) {
          } else {
            ev.objectives.forEach((obj) => {
              let objectives__ = {
                name: "",
                value: [],
              };
              objectives__.name = obj.name;
              if (obj.exam_lines && obj.exam_lines.length) {
                objectives__.value = obj.exam_lines.map((x) => {
                  return x.name;
                });
              } else {
                objectives__.value = [];
              }
              row.objectives.push(objectives__);
            });
          }
        }
        row.witness = ev.witnesses
          .filter((v) => v.fake_id !== parseInt(witness_id))
          .map((w) => w.name);
        temp_obj.rows.push(row);
      });
      return_.push(temp_obj);
    });
    return return_;
  };
  const generatePdfDataXExam = (data_, selectedTab) => {
    const return_ = [];
    data_.filter(filterKey).forEach((v) => {
      let temp_obj = {
        headers: [
          {
            name: "id",
            label: "ID",
            width: "5%",
          },
          {
            name: "topic",
            label: "Topic",
            width: "15%",
          },
          {
            name: "evidence",
            label: "Evidence",
            width: "15%",
          },
          {
            name: "objectives",
            label: "Objectives & EXAM LINES",
            width: "35%",
          },
          {
            name: "tags",
            label: "Tags",
            width: "30%",
          },
        ],
        rows: [],
      };
      if (selectedTab === 1) {
        temp_obj.title = `${v.theme ? v.theme.name + " - " : ""}${v.name}`;
      } else if (selectedTab === 0) {
        temp_obj.title = `${
          v.causes_of_action
            ? v.causes_of_action.type.name + " - "
            : v.type
            ? v.type.name
            : ""
        }${v.name && v.type ? " - " : " "}${v.name ? v.name : ""}`;
      } else if (selectedTab === 2) {
        temp_obj.title = `${v.issue ? v.issue.name + " - " : ""}${v.name}`;
      }
      setTagFieldClean(v.evidences.filter(filter)).forEach((ev) => {
        let row = {
          id: ev.fake_id ?? ev.id,
          tags: ev.tags_clean,
          topic: ev.name,
          sub_evidence: ev.sub_evidence ? ev.sub_evidence.map(v => v.label) : [],
          evidence: ev.evidences.map((v) => {
            return v.label;
          }),
          evidence_objects: setTagFieldClean(ev.evidences.filter(filter)),
          objectives: [],
        };
        if (ev.objectives && ev.objectives.length) {
          if (
            (checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
            witnessObject.exam_lines.evidences &&
            witnessObject.exam_lines.evidences.indexOf(ev.id) === -1)
           ||  (!checkIsXExamFlowIsFalse() && witnessObject.exam_lines &&
              witnessObject.exam_lines.topics &&
              witnessObject.exam_lines.topics.indexOf(ev.id) === -1)
          ) {
          } else {
            ev.objectives.forEach((obj) => {
              let objectives__ = {
                name: "",
                value: [],
              };
              objectives__.name = obj.name;
              if (obj.exam_lines && obj.exam_lines.length) {
                objectives__.value = obj.exam_lines.map((x) => {
                  return x.name;
                });
              } else {
                objectives__.value = [];
              }
              row.objectives.push(objectives__);
            });
          }
        }
        temp_obj.rows.push(row);
      });
      return_.push(temp_obj);
    });
    return return_;
  };
  useEffect(() => {
    const pdf_data = checkIsXExamFlowIsFalse()
      ? generatePdfData(data, selectedTab)
      : generatePdfDataXExam(data, selectedTab);
    setDataToPdf(pdf_data);
  }, [data, selectedTab, searchBarValue]);
  const PdfExport = () => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject) {
            let type = `${
              selectedTab === 1
                ? "Themes"
                : selectedTab === 0
                ? "Elements"
                : selectedTab === 2
                ? "Issues"
                : "Background"
            }`;
            let document_type = checkIsXExamFlowIsFalse()
              ? "Witness Proofs"
              : "X-Exam Witness Proofs";
            setExporting(true);
            const blob = await pdf(
              <PdfWitness
                isBackground={selectedTab === 3}
                document_type={document_type}
                caseName={caseObject.label}
                witness={witnessObject}
                type={type}
                data={dataToPdf}
              />
            ).toBlob();
            saveAs(blob, `${witnessObject.name} ${type}.pdf`);
            setExporting(false);
          }
        }}
        loading={exporting}
        disabled={exporting}
        className="float-right"
        primary
      >
        Export to PDF
      </ButtonNew>
    );
  };
  const DocExport = ({ as_pdf }) => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject) {
            let type = `${
              selectedTab === 1
                ? "Themes"
                : selectedTab === 0
                ? "Elements"
                : selectedTab === 2
                ? "Issues"
                : "Background"
            }`;
            let document_type = checkIsXExamFlowIsFalse()
              ? "Witness Proofs"
              : "X-Exam Witness Proofs";
            setExporting(true);
            let data_to_doc = {
              document_type,
              case_name: caseObject.label,
              witness: witnessObject,
              type: type,
              data: dataToPdf,
            };
            if (!checkIsXExamFlowIsFalse()) {
              data_to_doc.ex = true;
            }
            if (as_pdf) {
              data_to_doc.as_pdf = true;
            }
            const response = await generateDocWitness(dispatch, data_to_doc);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            setExporting(false);
            link.href = url;
            link.setAttribute(
              "download",
              `${witnessObject.name} ${type}.${as_pdf ? "pdf" : "docx"}`
            );
            document.body.appendChild(link);
            link.click();
          }
        }}
        loading={exporting}
        disabled={exporting}
        className={`float-right ${!as_pdf ? "mr-2" : ""}`}
        primary
      >
        Export to {as_pdf ? "PDF" : "Docx"}
      </ButtonNew>
    );
  };
  const onTabChange = (select) => {
    setSelectedTab(select.value);
  };
  const ExportToPdf = useMemo(() => {
    return selectedTab === 3 ? <PdfExport /> : <DocExport as_pdf={true} />;
  }, [dataToPdf, witnessObject, selectedTab, exporting]);
  const ExportToDoc = useMemo(() => {
    return <DocExport />;
  }, [dataToPdf, witnessObject, exporting]);
  const content_ = useMemo(() => {
    return <Tables />;
  }, [data, selectedTab, searchBarValue, canDrag]);
  let content;
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }
  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-7  mb-3">
          <Bold30Font style={{ marginRight: "10px" }}>
            {caseObject.label ?? "New Case"}
          </Bold30Font>{" "}
          <Bold30Font style={{ opacity: 0.5 }}>
            {checkIsXExamFlowIsFalse()
              ? "Witness Proofs"
              : "X-Exam Witness Proofs"}
          </Bold30Font>
        </div>
        {selectedTab === 3 || (dataToPdf && dataToPdf.length) ? (
          <Fragment>
            <div className="col-12 col-sm-5 mb-3">
              {ExportToPdf}
              {selectedTab !== 3 ? ExportToDoc : null}
            </div>
          </Fragment>
        ) : null}
        <div className="col-12 mb-3">
          <div className="row">
            <div className="ml-3 d-flex align-items-center">
              <Medium14Font>Select Witness: </Medium14Font>
              <SelectNew
                styles={{
                  container: (state) => ({
                    ...state,
                    width: "300px",
                  }),
                }}
                className={"ml-2"}
                name="witness"
                classNamePrefix={"Select"}
                options={witnessOption}
                defaultValue={witnessOption.find(
                  (option) => option.value === parseInt(witness_id)
                )}
                onChange={onWitnessChange}
              />
            </div>
            <div className="ml-3 d-flex align-items-center">
              <Medium14Font style={{ marginRight: "11px", width: "70px" }}>
                Group By:
              </Medium14Font>
              <SelectNew
                styles={{
                  container: (state) => ({
                    ...state,
                    width: "200px",
                  }),
                }}
                defaultValue={panelOptions.find((v) => v.value === selectedTab)}
                options={panelOptions}
                classNamePrefix={"Select"}
                onChange={onTabChange}
              />
            </div>
          </div>
        </div>
        {isLoadingWitness ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <PulseLoader size={30} color={theme.colors.blue} />
          </div>
        ) : (
          <Fragment>
            <div className="col-12 mb-3 d-flex flex-wrap justify-content-between">
              <Bold24Font>{witnessObject.name}</Bold24Font>
              <div className="d-flex">
                <ButtonNew clickHandler={openBackground} primary className={"pl-4 pr-4"}>
                  Background
                </ButtonNew>
                <ButtonNew
                  className={"ml-2"}
                  clickHandler={() => {
                    setCanDrag(!canDrag);
                  }}
                  secondary
                >
                  <img
                    src={canDrag ? unlock : lock}
                    alt=""
                    style={{ width: "16px", height: "16px" }}
                    className="img-fluid mr-2"
                  />
                  Re-order
                </ButtonNew>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      {isLoadingWitness ? null : isLoadingTab ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <PulseLoader size={30} color={theme.colors.blue} />
        </div>
      ) : (
        <Fragment>
          {data && data.length ? (
            <CasesSearchFilterBar
              style={{
                width: "100%",
                marginBottom: "15px",
                background: `url(${magnifierIcon}) no-repeat left 20px top 13px, ${theme.colors.white}`,
              }}
              type="text"
              value={searchBarValue}
              onChange={(e) => {
                setSearchBarValue(e.target.value);
              }}
              placeholder={"Search"}
              name={"search"}
              hideSorting={true}
              switchType={true}
              searchBarValue={searchBarValue}
              onSearchBarChangeHandler={(e) => {
                setSearchBarValue(e.target.value);
              }}
              searchBarPlaceholder={
                options && options.find((v) => v.value === searchType)
                  ? options.find((v) => v.value === searchType).label
                  : "Search"
              }
              searchBarInputName={"search_case"}
              onSwitchTypeValueChangeHandler={(el) => setSearchType(el.value)}
              switchTypeOptions={options}
            />
          ) : null}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"tables"}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Tables
                    caseObject={caseObject}
                    witnessObject={witnessObject}
                    updateObject={updateObject}
                    setNewOrder={setNewOrder}
                    filterKey={filterKey}
                    filter={filter}
                    canDrag={canDrag}
                    selectedTab={selectedTab}
                    data={data}
                    url={url}
                    checkIsXExamFlowIsFalse={checkIsXExamFlowIsFalse}
                    previewEvidence={previewEvidence}
                    editNote={editNote}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Fragment>
      )}
    </Fragment>
  );
}
