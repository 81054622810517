import React, { Fragment, useEffect, useRef, useState } from "react";
import Label from "../AddCardForm/Label";
import { useDispatch, useSelector } from "react-redux";
import {
  getBundles,
  getDocument,
  updateApplicationPaperById,
} from "../../../containers/Auth/auth";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import { theme } from "../../../styled-components/Theme/Theme";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import styled from "styled-components/macro";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { format, parseISO } from "date-fns";
import {
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { useHistory } from "react-router-dom";
import AssociateProofs from "../ApplicationsHubForms/AssociateProofs";
import PapersBasicInfoForm from "./PapersBasicInfoForm";
import moment from "moment";
import SelectAnotherTry from "../../Select/SelectAnotherTry";

const Separator = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  margin-bottom: 15px;
`;

const PapersDocProperties = ({ caseObject, callback, caseID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bundlesOptionsList, setBundlesOptionsList] = useState([]);
  const [selectedBundlesList, setSelectedBundlesList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const paper = useSelector((state) => state.rightSideBar.editableObject);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const form = useRef(null);

  const loadBundles = async () => {
    if (paper.bundles.length) {
      setSelectedBundlesList(
        paper.bundles.map((bundle) => ({
          label: bundle.label,
          value: bundle.id,
        }))
      );
    }
    const resp = await getBundles(dispatch, caseID);
    if (resp) {
      setBundlesOptionsList(
        resp
          .filter((bundle) => bundle.class_name === paper.document_type)
          .map((bundle) => ({ label: bundle.label, value: bundle.id }))
      );
    }
    setIsLoading(false);
  };
  const onClickEditOnTrialHubHandler = () => {
    history.push(
      !is_admin
        ? `/app/cases/${caseID}/applications-hub/edit/${paper.classes.application.fake_id}`
        : `/admin/all-cases/${caseID}/applications-hub/edit/${paper.classes.application.fake_id}`,
      {
        applicationWorkflowStage: 1,
        selectedObject: paper.classes,
      }
    );
  };
  useEffect(() => {
    loadBundles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const data_ = {
        bundles: paper.bundles,
        label: paper.label,
        probative_status: paper.probative_status,
        doc_type: paper.doc_type,
        date:
          paper.date && paper.date !== ""
            ? moment(paper.date).format("YYYY-MM-DD")
            : null,
        proofs: paper.proofs,
        reliefs: paper.reliefs,
      };
      await updateApplicationPaperById(
        paper.classes.id,
        paper.application_object.fake_id,
        caseID,
        dispatch,
        data_
      );
      let resp = await getDocument(caseID, paper.id, dispatch);
      callback(resp);
      setIsLoading(false);
      dispatch({
        type: HIDE_RIGHT_SIDE_BAR,
      });
    }
  };

  const updateObject = (resp) => {
    let obj = { ...paper, ...resp };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };

  const onBundleChange = (select) => {
    setSelectedBundlesList(select);
    if (select) {
      updateObject({
        bundles: select.map((bundle) => ({
          id: bundle.value,
          label: bundle.label,
        })),
      });
    } else {
      updateObject({
        bundles: [],
      });
    }
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group d-flex mb-4 pr-3">
          <SelectAnotherTry
            label="Assign to bundle"
            options={bundlesOptionsList}
            isClearable={false}
            isMulti
            placeholder={"Select bundle"}
            value={selectedBundlesList}
            defaultValue={
              selectedBundlesList && selectedBundlesList.length
                ? bundlesOptionsList.filter((v) =>
                    selectedBundlesList.find((b) => b.id === v.value)
                  )
                : []
            }
            reactSelectStyles={{
              control: (provided) => ({
                ...provided,
                height: "unset !important",
                minHeight: "42px !important",
                borderRadius: "21px !important",
              }),
            }}
            onChange={onBundleChange}
          />
        </div>
        <Separator />
        <div className="form-group">
          <Label
            name={"Basic Info"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: paper,
                    content: (
                      <PapersBasicInfoForm
                        key={paper.id}
                        evidenceObject={paper}
                        setEvidenceObject={updateObject}
                        evidenceId={paper.id}
                        caseObject={caseObject}
                        caseId={caseID}
                      />
                    ),
                    swapContent: {
                      content: (
                        <PapersDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />
                      ),
                      title: "Doc Properties",
                    },
                    title: "Basic Info",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {paper.label ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Doc Label: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {paper.label}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {paper.doc_type ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Doc Type: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {paper.doc_type}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {paper.date ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Issue Date: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {format(parseISO(paper.date), "dd MMM yyyy")}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {paper.probative_status ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Probative Status: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {paper.probative_status}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
        </div>
        <Separator />
        <div className="form-group">
          <Label
            name={"Reliefs and Proofs"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: paper,
                    content: (
                      <AssociateProofs
                        applicationObject={paper.application_object}
                        applicationPaperObject={paper}
                        returnObject={true}
                        setApplicationPaperObject={(data) => {
                          updateObject(data);
                        }}
                        savedReliefs={paper.reliefs}
                        savedProofs={paper.proofs}
                        routeParams={{
                          caseId: caseID,
                          applicationId: paper.application_object.fake_id,
                        }}
                      />
                    ),
                    title: "Reliefs and Proofs",
                    swapContent: {
                      content: (
                        <PapersDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />
                      ),
                      title: "Doc Properties",
                    },
                  });
                }}
                type={"edit"}
              />
            }
          />
          {paper.application_object && paper.application_object.reliefs
            ? paper.application_object.reliefs
                .filter((v) => {
                  if (!paper.reliefs || !paper.reliefs.length) {
                    return false;
                  } else {
                    return paper.reliefs.find((vv) => vv.id === v.id);
                  }
                })
                .map((obj) => {
                  return (
                    <div className="container-fluid">
                      <Bold14Font textColor={theme.colors.darkOpacity}>
                        {obj.name}
                      </Bold14Font>
                      <div className="container-fluid">
                        <ul>
                          {paper.application_object &&
                          paper.application_object.proofs
                            ? paper.application_object.proofs
                                .filter((g) => {
                                  if (!paper.proofs) {
                                    return false;
                                  } else {
                                    return paper.proofs.find(
                                      (c) => c.id === g.id
                                    );
                                  }
                                })
                                .map((obj) => {
                                  return (
                                    <li style={{ listStyle: "disc" }}>
                                      <Medium14Font
                                        textColor={theme.colors.darkOpacity}
                                      >
                                        {obj.name}
                                      </Medium14Font>
                                    </li>
                                  );
                                })
                            : null}
                        </ul>
                      </div>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
      <div>
        <div className="form-group">
          <div className="container-fluid">
            <ButtonNew
              wide
              secondary
              loading={isLoading}
              disabled={isLoading}
              clickHandler={onClickEditOnTrialHubHandler}
            >
              Edit in Application Hub
            </ButtonNew>
          </div>
        </div>
        <Separator />
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={handleSubmit} id={``} ref={form}>
              <ButtonNew
                wide
                primary
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                // clickHandler={handleSubmit}
              >
                Save
              </ButtonNew>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PapersDocProperties;
