import React, { Fragment, useEffect, useRef, useState } from "react";
import SubscriptionPagesWrapper from "../../../components/SubscriptionPagesWrapper";

import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Bold30Font,
  Medium14Font,
} from "../../../components/Fonts/Fonts";
import { Link, useHistory, useLocation } from "react-router-dom";
import AddCardForm from "../../../components/forms/AddCardForm";
import Button from "../../../components/buttons/Button/Button";
import DivStyled from "../../../components/forms/AddCardForm/styled/DivStyled";
import { theme } from "../../../styled-components/Theme/Theme";
import moment from "moment";
import { route } from "../../../routes";
import Checkbox from "../../../components/Checkbox";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import FormHeader from "../../../components/forms/AddCardForm/styled/FormHeader";
import Label from "../../../components/forms/AddCardForm/Label";
import InputStyled from "../../../components/Input/styled/InputStyled";
import AddCardFormStyled from "../../../components/forms/AddCardForm/styled/AddCardFormStyled";
import {
  addSubscription,
  createStripeIntent,
  getActiveSubscription, swapSubscription,
} from "../../Auth/auth";
import {
  MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
  SHOW_MODAL,
  UPDATE_USER,
} from "../../../redux/types";
import modalErrorHandler, {
  handleErrorMessage,
} from "../../../components/Modal/modalErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import { redirectUserToAppropriatePage } from "../../Auth/utils";
import { regUserCardFirstAndLastName } from "../../../utils";

const SubscriptionStep3Page = () => {
  const location = useLocation();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [doneElements, setDoneElements] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    name: false,
  });
  const [fistNameLastName, setFirstNameLastName] = useState("");
  let product;

  const isUserAuth = useSelector((state) => state.auth.isAuth);
  const user_ = useSelector((state) => state.auth.user);
  // useEffect(() => {
  //   const tryRedirect = async () => {
  //     await redirectUserToAppropriatePage(isUserAuth, history, dispatch, location.state);
  //   };
  //   tryRedirect();
  // }, []);
  const handleChange = (change) => {
    doneElements[change.elementType] = change.complete && !change.error;
    setDoneElements(doneElements);
  };
  const canSubmit = () => {
    return Object.values(doneElements).indexOf(false) === -1;
  };
  const changeNameHandler = (value) => {
    setFirstNameLastName(value);
    doneElements["name"] = value !== "";
    setDoneElements(doneElements);
  };
  const addSub = async (paymentMethod, subscriptionObject, confirm) => {
    let resp = await swapSubscription(subscriptionObject);
    if (resp.result === "success") {
      if (product && typeof window.gtag !== "undefined") {
        window.gtag("event", "purchase", {
          currency: product.currency.toUpperCase(),
          transaction_id: resp.payment_method,
          value: parseFloat(product.price),
          items: [
            {
              item_id: product.price_id,
              item_name: product.name,
              price: parseFloat(product.price),
              quantity: 1
            },
          ],
        });
      }
      dispatch({ type: UPDATE_USER, payload: resp.user });
      dispatch({
        type: SHOW_MODAL,
        payload: MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
      });
    } else {
      if (resp.result === "action_required") {
        stripe
          .confirmCardPayment(resp.secret, {
            payment_method: paymentMethod.id,
          })
          .then(function (result) {
            if (result.error) {
              handleErrorMessage(result.error.message, dispatch);
            } else {
              if (product && typeof window.gtag !== "undefined") {
                window.gtag("event", "purchase", {
                  currency: product.currency.toUpperCase(),
                  transaction_id: resp.payment_method,
                  value: parseFloat(product.price),
                  items: [
                    {
                      item_id: product.price_id,
                      item_name: product.name,
                      price: parseFloat(product.price),
                      quantity: 1
                    },
                  ],
                });
              }
              dispatch({ type: UPDATE_USER, payload: resp.user });
              dispatch({
                type: SHOW_MODAL,
                payload: MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
              });
            }
          });
      }
    }
    setIsLoading(false);
  };
  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    let subscriptionObject;
    let paymentMethod;
    if (!isProductBasic() || isTrialEnds()) {
      if (!regUserCardFirstAndLastName.test(fistNameLastName)) {
        handleErrorMessage("Please enter valid First & Last Name", dispatch);
        setIsLoading(false);
        return;
      }
      // if (!terms) {
      //   handleErrorMessage(
      //     "You must agree to the TrialProofer Terms of Use and our Privacy Policy",
      //     dispatch
      //   );
      //   return;
      // }
      const CardNumber = elements.getElement(CardNumberElement);
      setIsLoading(true);
      // const { error, paymentMethod } = await stripe.createPaymentMethod({
      const stripeCreatePaymentResponse = await stripe.createPaymentMethod({
        type: "card",
        card: CardNumber,
        billing_details: {
          name: fistNameLastName,
        },
      });
      let error = stripeCreatePaymentResponse.error ?? null;
      paymentMethod = stripeCreatePaymentResponse.paymentMethod;
      if (error) {
        setIsLoading(false);
        handleErrorMessage(error.message, dispatch);
        return;
      }
      subscriptionObject = {
        payment_method: paymentMethod.id,
        product_id: product.product_id,
        price_id: product.price_id,
      };
      try {
        let intent_response = await createStripeIntent({
          payment_method: paymentMethod.id,
        });
        if (
          intent_response.data &&
          intent_response.data.intent &&
          intent_response.data.intent.status === "requires_confirmation"
        ) {
          stripe
            .confirmCardSetup(intent_response.data.intent.client_secret, {
              payment_method: intent_response.data.intent.payment_method,
            })
            .then(function (result) {
              if (result.error) {
                setIsLoading(false);
                handleErrorMessage(result.error.message, dispatch);
                return;
              } else {
                if (result.setupIntent.status === "succeeded") {
                  addSub(paymentMethod, subscriptionObject, false);
                }
              }
            });
        } else {
          addSub(paymentMethod, subscriptionObject, true);
        }
      } catch (e) {
        setIsLoading(false);
        handleErrorMessage(e.message, dispatch);
        return;
      }
    } else {
      try {
        if (isProductBasic() && !isTrialEnds()) {
          subscriptionObject = {
            product_id: product.product_id,
            price_id: product.price_id,
          };
          await addSub(null, subscriptionObject);
        }
      } catch (e) {
        setIsLoading(false);
        modalErrorHandler(e, dispatch);
      }
    }
  };

  const createOptions = {
    style: {
      base: {
        letterSpacing: "0.025em",
        top: "5px",
        fontFamily: "PT Root UI, sans-serif",
        "::placeholder": {
          color: theme.colors.darkOpacity,
          fontFamily: "PT Root UI, sans-serif",
          fontSize: "14px",
          fontWight: 500,
        },
      },
      invalid: {
        color: theme.colors.burgundy,
      },
    },
  };
  if (location.state) {
    product = location.state.product;
  } else {
    product = null;
  }

  const isProductBasic = () => product && product.name === "Basic";
  const isTrialEnds = () =>
    user_ && user_.trial_ends_at && !user_.features.length;

  const [terms, setTerms] = useState(false);
  const handleChangeClick = () => {
    history.push({
      state: { product: product },
      pathname: "/subscription/step2",
    });
  };
  let date = moment().add(30, "day").format("DD/MM/YY");
  return (
    <SubscriptionPagesWrapper>
      <div className="row">
        <div className="col d-flex flex-column justify-content-between align-items-center">
          <Medium14Font>Step 3 of 3</Medium14Font>
          {!user_.trial_ends_at ? (
            <Fragment>
              <Bold30Font className="text-center mb-3">
                {isProductBasic()
                  ? "Enjoy your first 3 months"
                  : "Enjoy your first month"}
              </Bold30Font>
              <Medium14Font className="text-center mb-2">
                {isProductBasic()
                  ? "No credit card required"
                  : `Total during free trial. No charge until ${date}`}
              </Medium14Font>
            </Fragment>
          ) : null}
        </div>
      </div>
      {isProductBasic() && !isTrialEnds() ? null : (
        <div className="row">
          <AddCardFormStyled
            onSubmit={handleSubmit}
            className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 m-auto"
          >
            <FormHeader className="d-flex align-items-center">
              <Bold18Font>Add Card</Bold18Font>
            </FormHeader>
            <fieldset className={"form-vertical"}>
              <div className="form-group">
                <Label name={"First & Last Name"} />
                <div className="row">
                  <div className="col">
                    <InputStyled
                      type={"text"}
                      value={fistNameLastName}
                      onChange={(e) => {
                        changeNameHandler(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Label name={"Card Number"} />
                <CardNumberElement onChange={handleChange} {...createOptions} />
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <Label name={"Expiration Date"} />
                  <CardExpiryElement
                    onChange={handleChange}
                    {...createOptions}
                  />
                </div>
                <div className="col-6">
                  <Label name={"Security Code"} />
                  <CardCvcElement onChange={handleChange} {...createOptions} />
                </div>
              </div>
            </fieldset>
          </AddCardFormStyled>
        </div>
      )}
      <div className="row" style={{ marginTop: "30px" }}>
        <DivStyled
          style={styles.div}
          className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 m-auto d-flex justify-content-center"
        >
          <div className="row w-100">
            <div className="col-3 d-flex justify-content-center align-items-center">
              <img width={70} height={70} src={product.icon} />
            </div>
            <div className="col-7 d-flex flex-column justify-content-center">
              <Bold14Font>
                EUR {product.price}/
                {product.type === "monthly" ? "mo." : "year"}{" "}
                {isTrialEnds() ? "" : "after free trial"}
              </Bold14Font>
              <Bold24Font style={{ color: theme.colors.darkOpacity }}>
                {product.name}
              </Bold24Font>
            </div>
            <div
              className="col-2 d-flex justify-content-center align-items-center"
              onClick={handleChangeClick}
            >
              <Bold14Font style={styles.change}>Change</Bold14Font>
            </div>
          </div>
        </DivStyled>
      </div>
      <div className="row" style={{ marginTop: "60px" }}>
        <div
          style={{ maxWidth: "570px", cursor: "pointer" }}
          className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 m-auto d-flex justify-content-center flex-column"
        >
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    setTerms(!terms);*/}
          {/*  }}*/}
          {/*  className="row align-items-baseline"*/}
          {/*  style={{ marginBottom: "60px", marginTop: "30px" }}*/}
          {/*>*/}
          {/*  <Checkbox checked={terms} onChange={setTerms} />*/}
          {/*  <Medium14Font*/}
          {/*    style={{ color: theme.colors.darkOpacity, marginRight: "10px" }}*/}
          {/*  >*/}
          {/*    By checking the checkbox below, you agree to our*/}
          {/*  </Medium14Font>*/}
          {/*  <Link to={route.termsAndConditions}>*/}
          {/*    <Bold14Font style={styles.change}>Terms of Use</Bold14Font>*/}
          {/*  </Link>*/}
          {/*  {", "}*/}
          {/*  <Link to={route.privacyPolicy} style={{ marginLeft: "3px" }}>*/}
          {/*    <Bold14Font style={styles.change}>Privacy Policy</Bold14Font>*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <div className="m-auto pb-4">
            <Button
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              style={{ width: "155px" }}
              primary
            >
              Start Membership
            </Button>
          </div>
        </div>
      </div>
    </SubscriptionPagesWrapper>
  );
};

const styles = {
  div: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: 0,
  },
  change: {
    color: theme.colors.blue,
    cursor: "pointer",
  },
};

export default SubscriptionStep3Page;
